import { Card } from 'react-bootstrap';
import { Button, Carousel } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useEffect, useState } from 'react';
import { getAdminGalleryItem } from 'src/features/services/booking.slice';
import PreviewGalleryItem from 'src/components/ServiceDetail/PreviewGalleryItem';

const ServiceItem = ({ dispatch, provider, service, selectedServices, handleAddToCart, handleRemove }) => {
  const [galleryItems, setGalleryItems] = useState([]);

  useEffect(() => {
    dispatch(getAdminGalleryItem({
      uuid: provider.uuid,
      service_id: service.id
    })).unwrap().then((result) => {
      setGalleryItems(result)
    });
  }, []);

  return (
    <>
      <Card className="service-card">
        <div className="photo-gallery">
          {galleryItems.length > 0 ?
            <Carousel>
              {galleryItems.map((galleryItem, index) => (
                <Carousel.Item key={`admingalleryitem-${index}`}>
                  <div style={{ height: "300px" }}>
                    <PreviewGalleryItem item={galleryItem} />
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
            :
            <div className="placeholder-image" style={{ height: "300px" }} />
          }
        </div>
        <Card.Body>
          <div className="service-title-subtitle">
            <span className="fw-bold fs-14 text-truncate">
              {service.name}
            </span>
            <p className="fs-14 fw-medium-light lh-1 text-secondary">
              {service.tagline}
            </p>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="fs-12" style={{ overflowWrap: 'anywhere' }}>
              <span>
                <span className="fw-bold"> <NumberFormat value={service.price} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
              </span>
            </div>
            {selectedServices.includes(service) ? (
              <div
                className="d-flex align-items-baseline text-blue-added fw-medium"
                onClick={() => handleRemove(service)}
              >
                <img src="/icon/check.svg" alt="" className="me-2" />
                Added
              </div>
            ) : (
              <Button
                className="add-btn fw-medium px-3"
                size="sm"
                variant="outline-dark"
                onClick={() => handleAddToCart(service)}
              >
                <span>Add to Cart</span>
              </Button>
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default ServiceItem;
