import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  TextField, 
  DialogActions, 
  InputLabel, 
  MenuItem, 
  Select, 
  Skeleton, 
  Typography,  
  CircularProgress, 
  FormControl, 
  Box, 
  TextareaAutosize, 
  Grid, 
  Popper,
  ButtonGroup, 
  Tab,
  Tabs,
  Button,
  Tooltip,
  ClickAwayListener,
  Grow,
  Paper,
  MenuList
} from "@mui/material";

import SwipeableViews from 'react-swipeable-views';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useEffect, useState, useRef } from "react";
import { trackAnalytics } from "src/features/analytics/analytics.slice";
import { httpClient } from "src/libs";
import { useAppDispatch } from "src/store";
import { useAppSelector } from "src/store";
import { useSnackbar } from 'notistack';
import ConfettiExplosion  from "react-confetti-explosion";
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import ContactsSearchAutocomplete from "../Contacts/ContactsSearchAutocomplete";
import { selectContactById } from "src/features/account/contacts.slice";
import { selectChannelById } from "src/features/account/channels.slice";
import { SelectChangeEvent } from '@mui/material/Select';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

interface ConversationCreateDialogV2Props {
  open: boolean;
  handleClose: () => void;
  incomingContactId?: string | null;
  incomingDraftMessage?: string;
  incomingChannelId?: string | null;
}

const ConversationCreateDialogV2 = ({
  open,
  handleClose,
  incomingContactId = null,
  incomingDraftMessage = '',
  incomingChannelId = null,
}: ConversationCreateDialogV2Props) => {
  const { channels = [], channelsLoading, error } = useAppSelector(state => state.channels);
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState(incomingDraftMessage);
  const [errorMessage, setErrorMessage] = useState("");
  const [postingMessage, setPostingMessage] = useState(false);  
  const [sentMessage, setSentMessage] = useState(null);
  const incomingChannel = useAppSelector(state => selectChannelById(state, incomingChannelId));
  const [selectedChannel, setSelectedChannel] = useState(incomingChannel || null);
  const [dateTimeChanged, setDateTimeChanged] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const { subscriptionFeatures, activeProSubscriber } = useAppSelector((state) => state.auth);
  const [inputType, setInputType] = useState('email'); // 'email' or 'phone'
  const [selectedContactId, setSelectedContactId] = useState(incomingContactId);
  const selectedContact = useAppSelector(state => selectContactById(state, selectedContactId));
  const [tabValue, setTabValue] = useState(inputType === 'phone' ? 0 : 1);
  const [phoneMethodAvailable, setPhoneMethodAvailable] = useState(false);
  const [emailMethodAvailable, setEmailMethodAvailable] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [dateTimeValue, setDateTimeValue] = useState(dateFormatter());

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const anchorRef = useRef<HTMLDivElement>(null); // Updated this line

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    console.log('handleChange called, newValue:', newValue);
    setTabValue(newValue);
    setInputType(newValue === 0 ? 'phone' : 'email');
  };

  const handleChangeIndex = (index: number) => {
    setTabValue(index);
    setInputType(index === 0 ? 'phone' : 'email');
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  function dateFormatter() {
    let date = new Date();
    let localOffset = date.getTimezoneOffset() * 60000;
    let localTime = date.getTime();
    date = new Date(localTime - localOffset);
    return date.toISOString().substring(0, 16);
  }

  useEffect(() => {
    if (!selectedChannel && !channelsLoading && channels && channels.length > 0){
      // select the first channel with a twilio_phone_number otherwise select the first channel
      const channelWithPhone = channels.find(c => c.twilio_phone_number)
      
      setSelectedChannel( channelWithPhone || channels[0])
    }
  }, [channels, channelsLoading]);

  const handleSendMessage = (message: string) => {
    if (!selectedChannel) {
      setErrorMessage("Please select a channel");
      return;
    }
    setPostingMessage(true);
    dispatch(trackAnalytics({ action_name: "user_create_conversation_start", payload: { description: `user create conversation start` } }))
    const url = `${process.env.REACT_APP_API_V3_URL}/chat/private/messages/post_message`;
    const data = {
      content: message,
      from_role: 'assistant',
      channel_id: selectedChannel.id,
      to_identifier: inputType === 'email' ? selectedContact.email : selectedContact.phone_number,
      to_tag: inputType === 'email' ? 'email' : 'sms',
      deliver_after: new Date(dateTimeValue).toISOString(),
      to_role: 'user',
    };
    httpClient.post(url, data, { headers: { "Content-Type": "application/json" } })
      .then((response) => {
        const res = response.data.data
        dispatch(trackAnalytics({ action_name: "user_create_conversation_success", payload: { description: `user create conversation success` } }))
        setSentMessage(message)
        setErrorMessage("");
        setInputValue("");
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 1000);
        handleClose();
      })
      .catch((error) => {
        console.error("Error sending message:", error);
        enqueueSnackbar(`Error sending message: ${error.message}`, {
          variant: 'error',
          autoHideDuration: 3000
        });
        if (error.response) {
          setErrorMessage(`${error.response.data.error_code}: ${error.response.data.message}`);
        } else if (error.request) {
          setErrorMessage(`The request was made, but no response was received.`);
        } else {
          setErrorMessage(`Error: ${error.message}.`);
        }
        setPostingMessage(false);
        setSentMessage(null)
        setInputValue(message);
      })
      .finally(() => {
        setPostingMessage(false);
      });
  };

  const handleSelectContact = (contact) => {
    setSelectedContactId(contact.id);

    // Only clear the channel if the current channel doesn't support the contact's methods
    if (selectedChannel) {
      const channelSupportsPhone = !!selectedChannel.twilio_phone_number;
      const channelSupportsEmail = !!selectedChannel.channel_email_address;
      const contactHasPhone = !!contact.phone_number;
      const contactHasEmail = !!contact.email;

      if (!(channelSupportsPhone && contactHasPhone) && !(channelSupportsEmail && contactHasEmail)) {
        setSelectedChannel(null);
      }
    }

    // Set initial input type based on available methods
    if (contact.phone_number && selectedChannel?.twilio_phone_number) {
      setInputType('phone');
      setTabValue(0);
    } else if (contact.email && selectedChannel?.channel_email_address) {
      setInputType('email');
      setTabValue(1);
    }
  };

  useEffect(() => {
    const phoneMethodAvailable = !!(selectedChannel?.twilio_phone_number && selectedContact?.phone_number);
    const emailMethodAvailable = !!(selectedChannel?.channel_email_address && selectedContact?.email);
    setPhoneMethodAvailable(phoneMethodAvailable);
    setEmailMethodAvailable(emailMethodAvailable);

    // Only update input type if current type is not available
    if (!phoneMethodAvailable && !emailMethodAvailable) {
      setInputType('');
      setTabValue(0);
    } else if (!phoneMethodAvailable && emailMethodAvailable) {
      setInputType('email');
      setTabValue(1);
    } else if (phoneMethodAvailable && !emailMethodAvailable) {
      setInputType('phone');
      setTabValue(0);
    }
    // Don't change if both are available, keep current selection
  }, [selectedContact, selectedChannel]);

  const handleChannelChange = (event: SelectChangeEvent<string>) => {
    const channelId = event.target.value;
    const selectedChannel = channels.find(channel => channel.id === channelId) || null;
    setSelectedChannel(selectedChannel);
  };

  return (
    <>
      {open && (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
          <DialogTitle id="form-dialog-title">New Conversation</DialogTitle>
          <DialogContent>
            {!channelsLoading ?
              <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, gap: 2 }}>
                <ContactsSearchAutocomplete onSelectContact={handleSelectContact} />              
                <FormControl fullWidth>
                  <InputLabel id="channels-simple-select-label">Send From</InputLabel>
                  <Select
                    labelId="channels-simple-select-label"
                    id="channels-simple-select"
                    value={selectedChannel?.id || ''}
                    onChange={handleChannelChange}
                    label="Pick sender Oppy"
                    required
                  >
                    {channels
                      .filter(channel => channel.channel_email_address || channel.twilio_phone_number)
                      .map((channel) => (
                        <MenuItem
                          key={channel.embed_key}
                          value={channel.id}
                          disabled={(inputType === 'phone' && !channel.twilio_phone_number) || (inputType === 'email' && !channel.channel_email_address)}
                        >
                          <Grid container alignItems="center">
                            <Grid item xs={12}>
                              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                {channel.chatbot_name}
                              </Typography>
                            </Grid>
                            {channel?.twilio_phone_number && (
                              <Grid item xs={12}>
                                <Typography variant="body2" color="text.secondary">
                                  {channel?.twilio_phone_number}
                                </Typography>
                              </Grid>
                            )}
                            {channel?.channel_email_address && (
                              <Grid item xs={12}>
                                <Typography variant="body2" color="text.secondary">
                                  {channel?.channel_email_address}
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <Box sx={{
                  marginTop: '20px'
                }}>
                  <Tabs 
                    value={tabValue} 
                    onChange={handleChange} 
                    aria-label="contact method tabs"
                    variant="fullWidth"
                    sx={{ 
                      width: '100%',
                    }}
                  >
                    <Tab 
                      icon={<PhoneIcon />} 
                      aria-label="phone" 
                      disabled={!phoneMethodAvailable} 
                    />
                    <Tab 
                      icon={<EmailIcon />} 
                      aria-label="email" 
                      disabled={!emailMethodAvailable} 
                    />
                  </Tabs>
                  <SwipeableViews
                    axis={'x'}
                    index={tabValue}
                    onChangeIndex={handleChangeIndex}
                    disableSwipeToOpen={true}
                  >
                    <TabPanel value={tabValue} index={0}>
                      <Box sx={{
                        marginTop: '-20px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        marginLeft: '-24px',
                        marginRight: '-24px'
                      }}> 
                      <TextareaAutosize
                        style={{ 
                          width: '100%', 
                          height: '100px',
                          maxHeight: '200px',
                          padding: '8px', 
                          borderColor: '#ccc',
                          resize: 'vertical'
                        }}
                        minRows={3}
                        maxRows={8}
                        placeholder={`What would you like ${selectedChannel?.chatbot_name ? `${selectedChannel.chatbot_name} ` : ''}to say?`}
                        onChange={(e) => setInputValue(e.target.value)}
                        value={inputValue}
                      />
                        <Typography variant="caption">
                          I confirm the recipient has opted in to receive SMS messages from my business.
                        </Typography>
                      </Box>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}  >
                      <Box sx={{
                        marginTop: '-20px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        marginLeft: '-24px',
                        marginRight: '-24px'
                      }}>
                      <TextareaAutosize
                        style={{
                          width: '100%',
                          height: '100px',
                          maxHeight: '200px',
                          padding: '8px',
                          borderColor: '#ccc',
                          resize: 'vertical'
                        }}
                        minRows={3}
                        maxRows={8}
                        placeholder={`What would you like ${selectedChannel?.chatbot_name ? `${selectedChannel.chatbot_name} ` : ''}to say?`}
                        onChange={(e) => setInputValue(e.target.value)}
                        value={inputValue}
                      />
                      <Typography variant="caption">
                        I agree I have consent from the recipient to send them an email.
                      </Typography>
                      </Box>
                    </TabPanel>
                  </SwipeableViews>
                </Box>
              </Box>
              :
              <>
                <Skeleton variant="rectangular" sx={{ mt: 2 }} width={210} height={40} />
                <Skeleton variant="rectangular" sx={{ mt: 2 }} width={210} height={40} />
                <Skeleton variant="rectangular" sx={{ mt: 2 }} width={210} height={40} />
                <Skeleton variant="rectangular" sx={{ mt: 2 }} width={'100%'} height={70} />
              </>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <ButtonGroup
              variant="outlined"
              ref={anchorRef}
              aria-label="split button"
            >
              <Button
                disabled={!(inputValue.length > 0 && selectedChannel && (selectedContact?.phone_number || selectedContact?.email))}
                onClick={() => handleSendMessage(inputValue)}
                variant="outlined"
                >
                {postingMessage ? <Grid container alignItems="center" spacing={1}><Grid item>Sending</Grid><Grid item><CircularProgress size={15} /></Grid></Grid> : "Send"}
              </Button>
              <Button
                size="small"
                aria-controls={openMenu ? 'split-button-menu' : undefined}
                aria-expanded={openMenu ? 'true' : undefined}
                aria-label="select send time"
                aria-haspopup="menu"
                onClick={handleMenuClick}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Popper
              sx={{
                zIndex: 1,
              }}
              open={openMenu}
              anchorEl={anchorEl}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleMenuClose}>
                      <MenuList id="split-button-menu" autoFocusItem>
                        <MenuItem>
                          <TextField
                            id="datetime"
                            label="Send At"
                            type="datetime-local"
                            value={dateTimeValue}
                            onChange={(e) => {
                              setDateTimeChanged(true);
                              setDateTimeValue(e.target.value);
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                            helperText={dateTimeValue ? '' : 'Please set a valid time.'}
                            error={!dateTimeValue}
                            inputProps={{
                              min: dateFormatter()
                            }}
                            fullWidth
                          />
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </DialogActions>
        </Dialog>
      )}
      {showConfetti && <ConfettiExplosion />}
    </>
  );
}

export default ConversationCreateDialogV2;
