import { API_ROUTES } from 'src/constants/routes';
import { httpClient } from 'src/libs';
import { Contact } from 'src/features/account/contacts.slice'; // Corrected import statement for type

const fetch_contacts = (params: Partial<Contact> = {}) => {
  const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.contacts}`
  return httpClient.get(url, { params });
}

const show = (contactId) => {
  const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.contacts}/${contactId}`
  return httpClient.get(url);
}

const update = (data) => {
  const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.contacts}/${data.id}`
  return httpClient.put(url, {"contact": data});
};

const destroy = (contactId) => {
  const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.contacts}/${contactId}`
  return httpClient.delete(url);
};

const create = (data) => {
  const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.contacts}`
  return httpClient.post(url, { contact: data }); 
}


const ContactsService = {
  update,
  destroy,
  fetch_contacts,
  create,
  show
};

export default ContactsService;
