import React, { useState, useEffect } from 'react';
import {
  Button, TextField, Select, MenuItem, Dialog, DialogTitle, DialogContent,
  Checkbox, FormControlLabel, Typography, Grid, Paper, IconButton, CircularProgress, AppBar, Toolbar, Tooltip, Popover,
  Box
} from '@mui/material';
import { Add, Remove, Send, Settings, Person, Computer, Functions } from '@mui/icons-material';

const modelOptions = [
  { value: 'gpt-4', label: 'GPT-4' },
  { value: 'gpt-4o', label: 'GPT-4o' },
  { value: 'gpt-4o-mini', label: 'GPT-4o Mini' },
  { value: 'gpt-3.5-turbo', label: 'GPT-3.5 Turbo' },
  { value: 'gpt-3.5-turbo-16k', label: 'GPT-3.5 Turbo 16k' },
  { value: 'claude-3-opus-20240229', label: 'Claude 3 Opus' },
  { value: 'claude-3-sonnet-20240229', label: 'Claude 3 Sonnet' },
  { value: 'mixtral-8x7b-32768', label: 'Mixtral 8x7B' },
  { value: 'llama-2-70b-chat', label: 'LLaMA 2 70B' },
];

const roleIcons = {
  system: <Settings />,
  assistant: <Computer />,
  user: <Person />,
  function: <Functions />
};

const roleOrder = ['system', 'assistant', 'user', 'function'];

export default function OpenAIPromptTestTool() {
  const [showParams, setShowParams] = useState(false);
  const [inputMessages, setInputMessages] = useState('');
  const [functionsJson, setFunctionsJson] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [endpointUrl, setEndpointUrl] = useState('https://api.openai.com/v1/chat/completions');
  const [model, setModel] = useState('gpt-4o');
  const [maxTokens, setMaxTokens] = useState('300');
  const [functionCall, setFunctionCall] = useState('auto');
  const [temperature, setTemperature] = useState('0.7');
  const [frequencyPenalty, setFrequencyPenalty] = useState('0');
  const [presencePenalty, setPresencePenalty] = useState('0');
  const [responseFormat, setResponseFormat] = useState('{ "type": "text" }');
  const [streaming, setStreaming] = useState(false);
  const [messages, setMessages] = useState([]);
  const [apiResponse, setApiResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState('');

  useEffect(() => {
    try {
      const parsedMessages = JSON.parse(inputMessages);
      setMessages(parsedMessages);
    } catch (error) {
      console.error('Invalid JSON for messages');
    }
  }, [inputMessages]);

  const handleApiCall = async () => {
    setLoading(true);
    setError(null);
    let streamedContent = '';

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${apiKey}`
    };

    let parsedFunctions;
    try {
      parsedFunctions = functionsJson ? JSON.parse(functionsJson) : [];
    } catch (error) {
      setApiResponse(`Error parsing functions JSON: ${error.message}`);
      setLoading(false);
      return;
    }

    let parsedResponseFormat;
    try {
      parsedResponseFormat = responseFormat ? JSON.parse(responseFormat) : undefined;
    } catch (error) {
      setApiResponse(`Error parsing response format JSON: ${error.message}`);
      setLoading(false);
      return;
    }

    const body = {
      model,
      messages: messages.map(msg => 
        msg.role === 'function' 
          ? { ...msg, name: msg.name || 'default_function_name' } 
          : msg
      ),
      functions: parsedFunctions,
      max_tokens: parseInt(maxTokens),
      function_call: functionCall,
      temperature: parseFloat(temperature),
      frequency_penalty: parseFloat(frequencyPenalty),
      presence_penalty: parseFloat(presencePenalty),
      response_format: parsedResponseFormat,
      stream: streaming
    };

    try {
      const response = await fetch(endpointUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(JSON.stringify(errorData, null, 2));
      }

      if (streaming) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          const chunk = decoder.decode(value);
          const lines = chunk.split('\n');

          for (const line of lines) {
            if (line.startsWith('data: ')) {
              try {
                const data = JSON.parse(line.slice(6));
                if (data.choices && data.choices[0].delta && data.choices[0].delta.content) {
                  streamedContent += data.choices[0].delta.content;
                  setApiResponse(streamedContent);
                }
              } catch (error) {
                console.error('Error parsing streaming data:', error);
              }
            }
          }
        }
      } else {
        const data = await response.json();
        const content = data.choices[0].message.function_call 
          ? JSON.stringify(data.choices[0].message.function_call, null, 2)
          : data.choices[0].message.content;
        setApiResponse(content);
      }

      setMessages([...messages, { role: 'assistant', content: streamedContent || apiResponse }]);
    } catch (error) {
      setError(error.message);
      setApiResponse('');
    }
    setLoading(false);
  };

  const handleMessageUpdate = (index, content) => {
    const updatedMessages = [...messages];
    updatedMessages[index].content = content;
    setMessages(updatedMessages);
    setInputMessages(JSON.stringify(updatedMessages, null, 2));
  };

  const handleMessageDelete = (index) => {
    const updatedMessages = messages.filter((_, i) => i !== index);
    setMessages(updatedMessages);
    setInputMessages(JSON.stringify(updatedMessages, null, 2));
  };

  const handleMessageAdd = (index) => {
    const newMessage = { role: 'user', content: '' };
    const updatedMessages = [...messages.slice(0, index + 1), newMessage, ...messages.slice(index + 1)];
    setMessages(updatedMessages);
    setInputMessages(JSON.stringify(updatedMessages, null, 2));
  };

  const handleRoleChange = (index) => {
    const updatedMessages = [...messages];
    const currentRoleIndex = roleOrder.indexOf(updatedMessages[index].role);
    const nextRoleIndex = (currentRoleIndex + 1) % roleOrder.length;
    updatedMessages[index].role = roleOrder[nextRoleIndex];
    if (updatedMessages[index].role === 'function') {
      updatedMessages[index].name = 'default_function_name';
    } else {
      delete updatedMessages[index].name;
    }
    setMessages(updatedMessages);
    setInputMessages(JSON.stringify(updatedMessages, null, 2));
  };

  const handleMouseEnter = (event, message) => {
    const fullResponse = {
      id: message.id || '',
      object: message.object || '',
      created: message.created || '',
      model: message.model || '',
      choices: [
        {
          index: 0,
          message: {
            role: message.role,
            content: message.content,
            refusal: message.refusal || null
          },
          logprobs: null,
          finish_reason: message.finish_reason || 'stop'
        }
      ],
      usage: message.usage || {},
      system_fingerprint: message.system_fingerprint || ''
    };
    setPopoverContent(JSON.stringify(fullResponse, null, 2));
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <Typography ml={10} variant="h6">OpenAI Prompt Test Tool</Typography>
          <Button color="inherit" onClick={() => setShowParams(true)} startIcon={<Settings />}>
            Edit Parameters
          </Button>
        </Toolbar>
      </AppBar>
      <Paper className="container mx-auto p-4" style={{ marginTop: '64px' }}>
        <Dialog open={showParams} onClose={() => setShowParams(false)}>
          <DialogTitle>Edit Parameters</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Select fullWidth value={model} onChange={(e) => setModel(e.target.value)}>
                  {modelOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Max Tokens" type="number" value={maxTokens} onChange={(e) => setMaxTokens(e.target.value)} />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Function Call" value={functionCall} onChange={(e) => setFunctionCall(e.target.value)} />
              </Grid>
              <Grid item xs={12}>
                <TextField 
                  fullWidth 
                  label="Temperature" 
                  type="number" 
                  inputProps={{ step: "0.1" }} 
                  value={temperature} 
                  onChange={(e) => setTemperature(e.target.value)} 
                />
              </Grid>
              <Grid item xs={12}>
                <TextField 
                  fullWidth 
                  label="Frequency Penalty" 
                  type="number" 
                  inputProps={{ step: "0.1" }} 
                  value={frequencyPenalty} 
                  onChange={(e) => setFrequencyPenalty(e.target.value)} 
                />
              </Grid>
              <Grid item xs={12}>
                <TextField 
                  fullWidth 
                  label="Presence Penalty" 
                  type="number" 
                  inputProps={{ step: "0.1" }} 
                  value={presencePenalty} 
                  onChange={(e) => setPresencePenalty(e.target.value)} 
                />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Response Format (JSON)" value={responseFormat} onChange={(e) => setResponseFormat(e.target.value)} />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox checked={streaming} onChange={(e) => setStreaming(e.target.checked)} />}
                  label="Streaming"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Input Messages JSON"
                  value={inputMessages}
                  onChange={(e) => setInputMessages(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Functions JSON"
                  value={functionsJson}
                  onChange={(e) => setFunctionsJson(e.target.value)}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <TextField
          fullWidth
          type="password"
          label="API Key"
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Endpoint URL"
          value={endpointUrl}
          onChange={(e) => setEndpointUrl(e.target.value)}
          margin="normal"
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>Editable Messages</Typography>
            {messages.map((message, index) => (
              <Paper key={index} className="mb-2 relative" style={{ position: 'relative', maxWidth: '100%', margin: '0 auto 16px' }}>
                <Tooltip title={message.role} placement="top-start">
                  <IconButton
                    size="small"
                    onClick={() => handleRoleChange(index)}
                    style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }}
                  >
                    {roleIcons[message.role]}
                  </IconButton>
                </Tooltip>
                <TextField
                  fullWidth
                  multiline
                  value={message.content}
                  onChange={(e) => handleMessageUpdate(index, e.target.value)}
                  InputProps={{
                    style: { overflowY: 'auto', paddingLeft: '40px', paddingRight: '40px' }
                  }}
                  onMouseEnter={(e) => handleMouseEnter(e, message)}
                  onMouseLeave={handleMouseLeave}
                />
                <Box onMouseEnter={(e) => handleMouseEnter(e,message)}>
                  Raw
                </Box>
                <div style={{ position: 'absolute', top: 0, right: 0, display: 'flex', flexDirection: 'column' }}>
                  <IconButton size="small" onClick={() => handleMessageAdd(index)}>
                    <Add />
                  </IconButton>
                  <IconButton size="small" onClick={() => handleMessageDelete(index)}>
                    <Remove />
                  </IconButton>
                </div>
              </Paper>
            ))}
          </Grid>
        </Grid>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleMouseLeave}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <pre style={{ padding: '10px', maxWidth: '500px', maxHeight: '400px', overflow: 'auto' }}>
            {popoverContent}
          </pre>
        </Popover>
        <Button 
          variant="contained" 
          onClick={handleApiCall} 
          startIcon={loading ? <CircularProgress size={24} /> : <Send />} 
          disabled={loading}
        >
          {loading ? 'Sending...' : 'Send API Call'}
        </Button>
        {error && (
          <Paper elevation={3} style={{ marginTop: '20px', padding: '10px', backgroundColor: '#ffebee' }}>
            <Typography variant="h6" color="error">Error:</Typography>
            <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
              {error}
            </pre>
          </Paper>
        )}
        <Paper elevation={0} variant="outlined" className="mt-4 p-2">
          <Typography variant="body2">
            Remember to keep your API key secure and never share it publicly.
          </Typography>
        </Paper>
      </Paper>
    </>
  );
}
