import React, { useState, useEffect } from 'react';
import { Box, Typography, Slider } from '@mui/material';
import { styled } from '@mui/system';

const FAQBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(4),
  position: 'relative',
  overflow: 'hidden',
}));

const FAQSlider = ({ faqs }) => {
  const [currentFAQ, setCurrentFAQ] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentFAQ((prev) => (prev + 1) % faqs.length);
    }, 5000);

    return () => clearInterval(timer);
  }, [faqs.length]);

  const handleSliderChange = (_, newValue: number) => {
    setCurrentFAQ(newValue);
  };

  return (
    <FAQBox>
      <Typography variant="h5" fontWeight="semibold" mb={2}>FAQ Slideshow</Typography>
      <Box sx={{ position: 'relative', overflow: 'hidden' }}>
        <Box sx={{ display: 'flex', transition: 'transform 0.5s ease-in-out', transform: `translateX(-${currentFAQ * 100}%)` }}>
          {faqs.map((faq, index) => (
            <Box key={index} sx={{ width: '100%', flexShrink: 0 }}>
              <Typography variant="h6" sx={{ mb: 1 }}>{faq.question}</Typography>
              <Typography>{faq.answer}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Slider
        value={currentFAQ}
        onChange={handleSliderChange}
        step={1}
        marks
        min={0}
        max={faqs.length - 1}
        sx={{ mt: 2 }}
      />
    </FAQBox>
  );
};

export default FAQSlider;
