import React, { useState, useEffect } from 'react';
import { useAppSelector } from 'src/store';
import { Drawer, TextField, Typography, Box, SwipeableDrawer } from '@mui/material';
import { selectConversationById } from 'src/features/account/conversations.slice';

interface Props {
  conversationId: string;
}

const AdvancedConversationDetailsDrawer: React.FC<Props> = ({ conversationId }) => {
  const [open, setOpen] = useState(false);
  const conversation = useAppSelector(state => selectConversationById(state, conversationId));

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'm') {
        setOpen(!open);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [open]);

  if (!conversation) return null;

  return (
    <SwipeableDrawer
      anchor={"bottom"}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      sx={{ width: '100px', zIndex: 9999 }}
    >

      <Box p={2}>
        <Typography variant="h6">Advanced Conversation Details</Typography>
        <TextField
          label="Memory"
          value={String(conversation.memory)}
          fullWidth
          margin="normal"
          InputProps={{ readOnly: true }}
        />
        <TextField
          label="Subject"
          value={conversation.subject}
          fullWidth
          margin="normal"
          inputProps={{ maxLength: 160 }}
        />
        <TextField
          label="Status"
          value={conversation.status}
          fullWidth
          margin="normal"
          InputProps={{ readOnly: true }}
        />
        <TextField
          label="Participating Entities"
          value={conversation.participating_entities}
          fullWidth
          margin="normal"
          InputProps={{ readOnly: true }}
        />
        <TextField
          label="Retries"
          value={conversation.retries}
          fullWidth
          margin="normal"
          InputProps={{ readOnly: true }}
        />
        <TextField
          label="Delivery State"
          value={conversation.delivery_state}
          fullWidth
          margin="normal"
          InputProps={{ readOnly: true }}
        />
        <TextField
          label="Delivery Delay"
          value={conversation.delivery_delay}
          fullWidth
          margin="normal"
          InputProps={{ readOnly: true }}
        />
        <TextField
          label="Latest Delivery At"
          value={conversation.latest_delivery_at}
          fullWidth
          margin="normal"
          InputProps={{ readOnly: true }}
        />
        <TextField
          label="Latest Message At"
          value={conversation.latest_message_at}
          fullWidth
          margin="normal"
          InputProps={{ readOnly: true }}
        />
        <TextField
          label="Next Autocomplete Scheduled At"
          value={conversation.next_autocomplete_scheduled_at}
          fullWidth
          margin="normal"
          InputProps={{ readOnly: true }}
        />
        <TextField
          label="Account ID"
          value={conversation.account_id}
          fullWidth
          margin="normal"
          InputProps={{ readOnly: true }}
        />
        <TextField
          label="Messages Count"
          value={conversation.message_count}
          fullWidth
          margin="normal"
          InputProps={{ readOnly: true }}
        />
      </Box>
    </SwipeableDrawer>
  );
};

export default AdvancedConversationDetailsDrawer;
