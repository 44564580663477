import React, { useEffect, useState } from 'react';
import { DataGrid, GridCellParams, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { GridToolbar } from '@mui/x-data-grid'; // Update import
import { useAppDispatch, useAppSelector } from 'src/store'; // Adjust the import path according to your project structure
import { deleteContact, getContacts, updateContact } from 'src/features/account/contacts.slice'; // Adjust the import path according to your project structure
import ContactDialog from './ContactDialog'; // Assuming the path is correct
import ContactCreateDialog from './ContactCreateDialog';
import ConversationCreateDialogV2 from '../Channels/ConversationCreateDialogV2';
import Box from '@mui/system/Box/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import { contactsAdapter, selectAllContacts, selectContactById } from 'src/features/account/contacts.slice';
import { selectAllFilteredContacts } from 'src/features/account/filteredContacts.slice';
import ContactsFilterBar from './ContactsFilterBar';
import { setOpenDialog } from 'src/features/theme/theme.slice';
import PhoneIcon from '@mui/icons-material/Phone';
import MessageIcon from '@mui/icons-material/Message';
import ConversationCallOptions from '../Channels/ConversationCallOptions';

const ContactsDataGridV2 = () => {
  const dispatch = useAppDispatch();
  const { totalContacts, contactsLoading, earliestContactCreatedAtDateTime } = useAppSelector(state => state.contacts);
  // const contacts = useAppSelector(selectAllContacts);
  const openDialog = useAppSelector(state => state.theme.openDialog);
  const contacts = useAppSelector(selectAllFilteredContacts);
  const [createContactDialog, setCreateContactDialog] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState<string | null>(null);
  const [createConversationOpen, setCreateConversationOpen] = useState(false);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 100 });
  const [rows, setRows] = useState<GridRowsProp>([]);  // Define state for rows
  const selectedContact = useAppSelector(state => selectContactById(state, selectedContactId));
  const [createContactOpen, setCreateContactOpen] = useState(false);
  const [callDialogOpen, setCallDialogOpen] = useState(false);
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);
  const [selectedChannelId, setSelectedChannelId] = useState(null);

  useEffect(() => {
    // Initialize rows when contacts are fetched
    setRows(contacts.map(contact => ({ ...contact, id: contact.id })));
  }, [contacts]);

  const handleContactUpdate = (updatedContact) => {
    // Update the row in the rows state
    const updatedRows = rows.map((row) => {
      if (row.id === updatedContact.id) {
        console.log(`row: ${row}, updatedContact: ${updatedContact}`);
        return { ...row, ...updatedContact };
      }
      return row;
    });
    setRows(updatedRows);  // Update rows state
  };

  // useEffect(() => {
  //   dispatch(getContacts({ limit: paginationModel.pageSize }));
  // }, [dispatch, paginationModel.pageSize]);

  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
    // somewhere here we can determine the corresponding created_at_before datetime with a page number
    // once we have it, we can do some math to see if the contacts already have been fetched in contacts before
    // if they have, we can skip the fetch and simply grab the contacts from the state
    // if they haven't, we can fetch
    console.warn("about to get contacts in handlepaginationchange");
    dispatch(getContacts({ limit: paginationModel.pageSize, created_at_before: new Date(earliestContactCreatedAtDateTime) }));  
  };

  const handleRowClick = (params) => {
    setSelectedContactId(params.id.toString());
    // const contactDetails = contactsAdapter.getSelectors().selectById(useAppSelector(state => state.contacts), params.id.toString());
    setCreateContactDialog(true);
  };

  const processRowUpdate = async (newRow, oldRow) => {
    try {
      // Dispatch the updateContact action with the updated row data
      await dispatch(updateContact(newRow)).unwrap();
      return newRow;
    } catch (error) {
      console.error('Failed to update contact', error);
      // Return the old row data to revert the changes in the UI
      return oldRow;
    }
  };

  const handleOpenCreateConversation = (params: GridCellParams) => {
    setSelectedContactId(params.id.toString());
    setCreateConversationOpen(true);
  }

  const handleCloseCreateConversation = () => {
    setSelectedContactId(null);
    setCreateConversationOpen(false);
  }

  const handleOpenContactsUploaderDialog = () => {
    console.log('handleOpenContactsUploaderDialog', openDialog);
    dispatch(setOpenDialog('ContactsUploaderDialog'));
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    { 
      field: 'name', 
      headerName: 'Name', 
      width: 150, 
      editable: true,
      renderCell: (params) => (
        <>
          <img src={params.row.picture_url} alt={params.value} style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }} />
          {params.value}
        </>
      )
    },
    { field: 'first_name', headerName: 'First Name', width: 150, editable: true },
    { field: 'last_name', headerName: 'Last Name', width: 150, editable: true },
    { field: 'email', headerName: 'Email', width: 200, editable: true },
    {
      field: 'phone_number', headerName: 'Phone Number', width: 150, editable: true, renderCell: (params) => (
        <Button onClick={() => handleOpenCreateConversation(params)}>
          {params.value}
        </Button>
      ), 
    },
    { field: 'company_name', headerName: 'Company Name', width: 150, editable: true },
    { field: 'status', headerName: 'Status', width: 100, editable: true },
    { field: 'notes', headerName: 'Notes', width: 200, editable: true },
    { field: 'last_contacted', headerName: 'Last Contacted', width: 200, editable: true, renderCell: (params) => (
      <>{params.value ? new Date(params.value).toLocaleString() : ''}</>
    ) },
    {
      field: 'delete',
      headerName: 'Delete',
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <IconButton
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            if (window.confirm(`Are you sure you want to delete ${params.row.first_name} ${params.row.last_name} - ${params.row.phone_number}, ${params.row.email}?`)) {
              dispatch(deleteContact(params.id.toString()));
            }
          }}
          color="error"
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
    { field: 'created_at', headerName: 'Created At', width: 200, editable: true, renderCell: (params) => (
      <>{params.value ? new Date(params.value).toLocaleString() : ''}</>
    ) },
    { field: 'updated_at', headerName: 'Updated At', width: 200, editable: true, renderCell: (params) => (
      <>{params.value ? new Date(params.value).toLocaleString() : ''}</>
    ) },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      sortable: false,
      renderCell: (params) => (
        <Box>
          <IconButton
            onClick={() => {
              setSelectedContactId(params.id.toString());
              setCallDialogOpen(true);
            }}
            color="primary"
            size="small"
          >
            <PhoneIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setSelectedContactId(params.id.toString());
              setMessageDialogOpen(true);
            }}
            color="primary"
            size="small"
          >
            <MessageIcon />
          </IconButton>
        </Box>
      ),
    },
    { field: 'score', headerName: 'Relevance', width: 200 },
  ];

  const styles = {
    mainContainer: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      pt: 2,
      flexGrow: 0,
      borderRadius: 3,
      height: '100vh',
      overflowY: 'scroll',
      minWidth: '320px',
      position: 'relative',
    },
    topBar: {
      position: 'absolute',
      top: '0px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center', // This centers the children vertically
      p: 2,
      background: '#FAFAFA',
      width: '100%',
    },
    topBarTitleAndFilterContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    topBarAction: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    contactsContainer: {
      mt: 6,
      width: '100%',
      height: '100%',
      maxHeight: '100vh',
      overflowY: 'scroll',
      // backgroundColor: 'lightblue',
      display: 'flex',
      flexDirection: 'column', // Ensure groups are stacked vertically
      gap: '0px',
      p: 2,
      alignItems: 'stretch',
      flexWrap: 'wrap',
      transition: 'all 0.3s ease',
    },
    contactsGrid: {
      height: '100%',
      width: '100%',
    },
  };
  return (
    <>
      <Box sx={styles.mainContainer}>
        <Box sx={styles.topBar}>
          <Box sx={styles.topBarTitleAndFilterContent}>
            <Typography variant="h5">Contacts</Typography>
          </Box>
          <Box sx={styles.topBarAction}>
            <Button variant="contained" color="primary" onClick={() => setCreateContactOpen(true)}>
              + Contact
            </Button>
            <Button variant="contained" color="primary" onClick={() => handleOpenContactsUploaderDialog()}>
              Upload Contacts
            </Button>
            <ContactCreateDialog open={createContactOpen} onClose={() => setCreateContactOpen(false)} />
          </Box>
        </Box>
        <Box sx={styles.contactsContainer}>
          <ContactsFilterBar />
          <DataGrid
            rows={rows}
            columns={columns}
            getRowId={(row) => row.id}
            initialState={{
              pagination: { paginationModel: { pageSize: 100 } },
              columns: {
                columnVisibilityModel: {
                  id: false,
                  picture_url: false,
                },
              },
            }}
            style={styles.contactsGrid}
            loading={contactsLoading}
            processRowUpdate={processRowUpdate}
            onRowClick={handleRowClick}
            paginationMode="server" // Set pagination mode to server
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationModelChange} // Handle pagination model changes
            rowCount={totalContacts}
            slots={{
              toolbar: GridToolbar, // Use GridToolbar for default toolbar with export
            }}
          />
        </Box>
      </Box>
      {selectedContactId && (
        <ContactDialog
          open={createContactDialog}
          onClose={() => setCreateContactDialog(false)}
          contactId={selectedContact.id}
          handleContactUpdate={handleContactUpdate}
        />
      )}
      {selectedContact && createConversationOpen && (
        <ConversationCreateDialogV2 
          open={createConversationOpen} 
          handleClose={handleCloseCreateConversation} 
          incomingDraftMessage={""} 
          incomingContactId={selectedContact.id}
        />
      )}
      <ConversationCallOptions
        open={callDialogOpen}
        channelId={selectedChannelId}
        incomingContactId={selectedContactId}
        onClose={() => setCallDialogOpen(false)}
      />
      <ConversationCreateDialogV2
        open={messageDialogOpen}
        handleClose={() => setMessageDialogOpen(false)}
        incomingContactId={selectedContactId}
        incomingChannelId={selectedChannelId}
      />
    </>
  );
};

export default ContactsDataGridV2;
