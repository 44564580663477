import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Call, PhoneInTalk, Phone } from '@mui/icons-material';
import ConversationCallOptions from './ConversationCallOptions';
import { useAppSelector, useAppDispatch } from 'src/store';
import { selectChannelById } from 'src/features/account/channels.slice';
import { initiateCall } from 'src/features/account/conversations.slice';

const ConversationCallButton = ({ conversation }) => {
  const [callOptionsOpen, setCallOptionsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.user);
  const channel = useAppSelector(state => selectChannelById(state, conversation.channel_id));
  const [connectToNumber, setConnectToNumber] = useState('');
  const [callerName, setCallerName] = useState(user?.user?.name || '');
  const [contactName, setContactName] = useState(conversation.contact_name);
  const [toAdminPhoneNumber, setToAdminPhoneNumber] = useState(user?.user?.contact_phone || '');
  const [callStatusState, setCallStatusState] = useState(conversation.call_status);
  const contact = conversation.participating_entities.find(entity => entity.role === 'user')?.contact
  
  useEffect(() => {
    setContactName(contact?.name || `${contact?.first_name} ${contact?.last_name}`);
    setConnectToNumber(contact?.phone_number);
    setCallOptionsOpen(false);
  }, [conversation]);

  const styles = {
    callStatus: {
      position: 'absolute',
      bottom: '1rem',
      right: '1rem',
      display: 'flex',
      alignItems: 'center',
      fontSize: '0.875rem',
      fontWeight: 'bold',
      zIndex: 2,
      color: conversation.call_status === 'active' ? '#10b981' : '#fbbf24',
      padding: '0.25rem 0.5rem',
      borderRadius: '0.25rem',
      backgroundColor: 'white',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#f3f4f6',
      },
    },
  };

  const onClick = (e) => {
    alert(`conversation_id: ${conversation.id} fromphone: ${channel.twilio_phone_number} tophone: ${toAdminPhoneNumber} connectToNumber: ${connectToNumber} callerName: ${callerName} contactName: ${contactName}`);
    e.stopPropagation();
    e.preventDefault();
    dispatch(initiateCall({
      conversationId: conversation.id,
      fromOppyPhoneNumber: channel.twilio_phone_number,
      toAdminPhoneNumber: toAdminPhoneNumber,
      connectToNumber: connectToNumber,
      channelId: conversation.channel_id,
      callerName,
      contactName
    }));
    setCallOptionsOpen(true);
  };

  return (
    <>
      <Box onClick={onClick}>
        {conversation.call_status === 'active' ? (
          <Call fontSize="small" sx={{ mr: 0.5 }} />
        ) : conversation.call_status === 'ringing' ? (
          <PhoneInTalk fontSize="small" sx={{ mr: 0.5 }} />
        ) : (
          <Phone fontSize="small" sx={{ mr: 0.5 }} />
        )}
        {conversation.call_status === 'active' ? 'In progress' :
          conversation.call_status === 'ringing' ? 'Ringing' : 'No call'}
      </Box>
      <ConversationCallOptions
        channelId={conversation.channel_id}
        open={callOptionsOpen}
        onClose={() => setCallOptionsOpen(false)}
      />
    </>
  );
};

export default ConversationCallButton;
