import { useEffect, useState } from 'react';
import { getAllAdminBookingServices } from 'src/features/services/booking.slice';
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import ServiceItem from './ServiceItem';
import { isPricingMethodology } from 'src/components/Booking/BookingUtils';

const BookingServices = ({ dispatch, provider, selectedServices, handleAddToCart, handleRemove, square_feet, listing_price, address, setShowSquareFeet, setShowListingPrice }) => {
  const [bookingServices, setBookingServices] = useState([]);

  useEffect(() => {
    if (address) {
      dispatch(getAllAdminBookingServices({
        uuid: provider.uuid,
        address: address,
        square_feet: square_feet || "",
        listing_price: listing_price || ""
      })).unwrap().then((result) => {
        setBookingServices(result)
      })
    } else {
      dispatch(getAllAdminBookingServices({ uuid: provider.uuid })).unwrap().then((result) => {
        setBookingServices(result)
      })
    }
  }, [address, square_feet, listing_price]);

  useEffect(() => {
    if (bookingServices.length) {
      if (isPricingMethodology(bookingServices, 'square_footage') || isPricingMethodology(bookingServices, 'tiered_sqft_fee')) {
        setShowSquareFeet(true)
      }
      if (isPricingMethodology(bookingServices, 'tiered_price_fee')) { setShowListingPrice(true) }
    }
  }, [bookingServices]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 2
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <>
      {bookingServices.length > 0 ?
        <Carousel responsive={responsive} className='my-4'>
          {bookingServices.map((service, index) => (
            <div className="mx-3" key={index}>
              <ServiceItem
                dispatch = {dispatch}
                provider = {provider}
                service={service}
                selectedServices={selectedServices}
                handleAddToCart={handleAddToCart}
                handleRemove={handleRemove}
              />
            </div>
          ))}
        </Carousel> :
        <div className='my-4'>Loading Services...</div>
      }
    </>
  );
};

export default BookingServices;
