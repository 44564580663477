import { Field, Formik } from 'formik';
import { useEffect, useState } from 'react';
import {Form, Row } from 'react-bootstrap';
import { Link as RouterLink } from 'react-router-dom';

import { useNavigate, useSearchParams } from 'react-router-dom';
// import mui link
import { CircularProgress, Link, Button, Divider } from '@mui/material';
import { register } from 'src/features/auth/auth.slice';
import { clearMessage } from 'src/features/auth/message.slice';
import { useAppDispatch, useAppSelector } from 'src/store';
import { registrationSchema } from 'src/utils/formSchemas';
import ValidatedPhoneInput from 'src/components/ValidatedPhoneInput'
import 'react-phone-input-2/lib/style.css'
import { trackAnalytics } from 'src/features/analytics/analytics.slice';
import DocumentMeta from 'react-document-meta';
import { getOnboardingPath, meta } from 'src/libs/utils';
import { getBranding } from 'src/utils/branding';
import { PhoneVerification } from 'src/components/PhoneVerification';
import { Box, TextField, Typography } from '@mui/material';
const params = new URLSearchParams(window.location.search)
const categoryCookie = document.cookie.replace(/(?:(?:^|.*;\s*)rmk_category\s*\=\s*([^;]*).*$)|^.*$/, "$1");
const category = categoryCookie || params.get("category");
  
const initialValues = {
  firstName: '',
  lastName: '',
  password: '',
  email: '',
  termOfUse: false,
  phone: '',
  phone_verification: '',
  existing_user: false,
  category: category || "",
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
};

const Registration = ({ displayedAuthScreen, setDisplayedAuthScreen }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [phoneError, setPhoneError] = useState(null);
  const { authError } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const utm_source = searchParams.get("utm_source")
  const [error, setError] = useState(false)
  // This state will determine which fields to show
  const [showPassword, setShowPassword] = useState(false);
  const [showNameFields, setShowNameFields] = useState(false);

  const onSubmit = (values) => {
    
    // if (!isPhoneVerified){
    //   setPhoneError("Please validate your Phone Number before submiting!")
    //   return;
    // }

    const refCookie = document.cookie.replace(/(?:(?:^|.*;\s*)rmk_ref\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    const ref = refCookie || params.get("ref")
    const accountInviteCookie = document.cookie.replace(/(?:(?:^|.*;\s*)rmk_account_invite\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    const account_invite = accountInviteCookie || params.get("account_invite")

    setLoading(true);
    dispatch(register({
      ...values,
      // category,
      ref,
      account_invite,
    }))
      .unwrap()
      .then(() => {
        dispatch(trackAnalytics({action_name: "user_registered", payload: { description: `user with email ${values.email} created account`}}))
        navigate(getOnboardingPath())
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <>
        <Typography variant='h3'
        sx={{
          opacity: 0.9,
        }}
        >Register</Typography>
      <Typography variant='body2'
        sx={{
          opacity: 0.7,
          pb: 1
        }}
      >Create new account{' '}
        <Link
          onClick={() => (
            navigate('/login'),
            setDisplayedAuthScreen('login')
          )}
          color="primary"
          component={RouterLink}
          to="/register"
          sx={{ textDecoration: 'none', cursor: 'pointer' }}
        >or sign in
        </Link>
      </Typography>
        <Box width={'100%'} mt={2}>
          <Formik
            initialValues={initialValues}
            validationSchema={registrationSchema}
            onSubmit={onSubmit}
          >
            {({ setFieldValue, handleSubmit, handleChange, setFieldError, setFieldTouched, values, touched, errors }) => {
            useEffect(() => {
              setError(authError);
              const isEmailValid = !!values.email.match(/^\S+@\S+\.\S+$/);
              setShowPassword(isEmailValid);
              if (isEmailValid) {
                setShowNameFields(values.password.length >= 8);
              } else {
                // If the email is not valid, then we shouldn't show the name fields either
                setShowNameFields(false);
              }
            }, [values.email, values.password, authError]);
            return ( 
              <Form noValidate onSubmit={handleSubmit}>
                {error && !errors.email && authError?.email && setFieldError("email", authError?.email)}

                <Form.Group className="mb-3" controlId="validationFormik03">
                  <TextField
                    sx={{ width: '100%' }}
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={(e) => {
                      handleChange(e)
                      setError(false)
                      setFieldError("email", null)
                    }}
                    label="Email"
                    error={touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    required
                    autoFocus
                  />
                </Form.Group>

                {/* Continue with the rest of your form inputs, converting them to MUI's TextField similar to the above pattern */}
              {showPassword && 
                <Form.Group className="mb-3" controlId="validationFormik04">
                  <TextField
                    sx={{ width: '100%' }}
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    label="Password"
                    error={touched.password && !!errors.password}
                    helperText={touched.password && errors.password}
                    required
                  />
                </Form.Group>
              }
              {showNameFields && 
                <>
                  <Form.Group className="mb-3" controlId="validationFormik01">
                    <TextField
                      sx={{ width: '100%' }}
                      type="text"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      label="First Name"
                      error={touched.firstName && !!errors.firstName}
                      helperText={touched.firstName && errors.firstName}
                      required
                      
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="validationFormik02">
                    <TextField
                      sx={{ width: '100%' }}
                      type="text"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      label="Last Name"
                      error={touched.lastName && !!errors.lastName}
                      helperText={touched.lastName && errors.lastName}
                      required
                    />
                  </Form.Group>
                </>
              }
                <Row className="justify-content-center p-2">
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={loading}
                    sx={{ mt: 3, mb: 3 }}
                  >
                    {loading && (
                      <CircularProgress size={24} />
                    )}
                    Create Account
                  </Button>
                  <Divider  > or </Divider>
                {/* phone login or email login button depending on state */}

                  <Button
                    variant="outlined"
                    type="submit"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={() => (
                      navigate('/login'),
                      setDisplayedAuthScreen('login')
                    )}
                    
                  >
                    Log In
                  </Button>
               
                </Row>
              </Form>
            )
            }}
          </Formik>
        </Box>

    </>
  );
};

export default Registration;
