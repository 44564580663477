import Pusher from 'pusher-js';
import { API_ROUTES } from 'src/constants/routes';
import { httpClient } from 'src/libs';
import { ResSubscriptionFeaturesType, UserType } from 'src/types/user';
import authToken from './auth-header';
import { getUser } from 'src/features/user/user.slice';
import { createContext } from 'react';

const token = authToken();
const authenticationEndpoint = `${process.env.REACT_APP_API_V3_URL}/private/pusher/authenticate`;
const authorizationEndpoint = `${process.env.REACT_APP_API_V3_URL}/private/pusher/authorize`;
const options = {
  cluster: "mt1",
  userAuthentication: {
    endpoint: authenticationEndpoint,
    transport: 'ajax' as 'ajax', // Ensure the type is correctly inferred
    headersProvider(){
      return {
        Authorization: `Bearer ${token}`,
      };
    },
  },
  channelAuthorization: {
    endpoint: authorizationEndpoint,
    transport: 'ajax' as 'ajax', // Ensure the type is correctly inferred
    headersProvider(){
      return {
        Authorization: `Bearer ${token}`,
      };
    },
  },
};

export const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, options);
Pusher.logToConsole = true; // Enable pusher logging - don't include this in production


export const PusherContext = createContext<Pusher>(pusher);

const register = async (user): Promise<UserType> => {
  return httpClient.post(`${API_ROUTES.registration}`, {
    user: {
      ...user,
    },
  })
  .then((response) => {
    if (response.data.access_token) {
      // save it in the localstorage maybe use a local storage plugin
      localStorage.setItem('user', JSON.stringify(response.data));
      pusher.signin();
    }
    return response.data;
  });
};

const login = async (email: string, password: string): Promise<UserType> => {
  return httpClient
    .post(`${API_ROUTES.login}`, {
      user: {
        email,
        password,
      },
    })
    .then((response) => {
      if (response.data.access_token) {
        // save it in the localstorage maybe use a local storage plugin
        localStorage.setItem('user', JSON.stringify(response.data));
        pusher.signin();
      }
      return response.data;
    });
};

const smsLogin = async (phone_token: string): Promise<UserType> => {
  return httpClient
    .post(`${API_ROUTES.login_with_sms}`, { phone_token })
    .then((response) => {
      if (response.data.access_token) {
        // save it in the localstorage maybe use a local storage plugin
        localStorage.setItem('user', JSON.stringify(response.data));
        pusher.signin();
      }
      return response.data;
    });
};

const sendSms = async (phone: string) => {
  return httpClient.post(API_ROUTES.send_verification_code, { phone });
};

const validateSms = async (phone: string, code: string) => {
  return httpClient.post(API_ROUTES.verify_sms, { phone, code });
};

const logout = async () => {
  // remove token from local storage
  localStorage.removeItem('user');
};

const forgotPassword = async (data: { email: string }) => {
  return httpClient.post(`${API_ROUTES.forgot_password}`, {
    user: data
  });
};

const resetPassword = async (data: { reset_password_token: string, password: string, password_confirmation: string }) => {
  return httpClient.put(`${API_ROUTES.reset_password}`, {
    user: data
  });
};

const getUserSubscription = async () :Promise<ResSubscriptionFeaturesType> => {
  return httpClient.get(`${API_ROUTES.subscription_paywall_user}`);
};

const createSubscription = async (data: { checkout_session_id: String }) => {
  return httpClient.post(`${API_ROUTES.create_subscription}`, data);
};


const AuthService = {
  register,
  login,
  logout,
  forgotPassword,
  resetPassword,
  getUserSubscription,
  createSubscription,
  sendSms,
  validateSms,
  smsLogin,
};

export default AuthService;
