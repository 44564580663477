// begin /src/components/Channels/Conversation.tsx
import React, { useState, useEffect, useRef } from "react";
import { Box,  CircularProgress,  Divider, FormControl, Icon, IconButton, TextField, ToggleButton, ToggleButtonGroup, Typography, LinearProgress } from '@mui/material';
import { useAppDispatch, useAppSelector } from "src/store";

import { trackAnalytics } from "src/features/analytics/analytics.slice";
import { fetchConversationWithMessages, sendMessage, toggleOpenConversation, updateConversationDeliveryState, updateConversationStatus } from "src/features/account/conversations.slice";
import SendIcon from '@mui/icons-material/Send';
import { dateTimeFormatter } from "src/libs/dateTimeFormatter";
import SendingEntityDrawer from "./SendingEntityDrawer";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Message from "./Message";
import { updateMessage } from "src/features/account/messages.slice";
import DeliveryStateButton from "./DeliveryStateButton";
import GenerateReplyButton from "./GenerateReplyButton";
import ConversationStatusIndicator from "./ConversationStatusIndicator";
import ConversationOptionsMenu from "./ConversationOptionsMenu";
import ConversationContacts from "./ConversationContacts";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ConversationMemoryDrawer from "./ConversationMemoryDrawer";
import { getBranding } from "src/utils/branding";
import { postMessageHotKey } from "src/libs/hotKeys"; // Import the hotkey function
import { useSelector } from "react-redux";
import { selectChannelById } from "src/features/account/channels.slice";

const styles = {
  chatBox: {
    display: 'flex',
    flexDirection: 'column', 
    justifyContent: 'space-between',
    position: 'relative',
    gap: '14px', 
    mb: '0',
    height: '100%',
    width: '100%', // Ensure chat box takes full width
    minHeight: '60vh',
    minWidth: '100%', // Ensure minimum width is 100% to prevent overflow
    maxWidth: '100%' // Ensure maximum width is 100% to prevent overflow
    // minWidth: { xs: '100vw', sm: '100vw', md: '450px', lg: '550px', xl: '650px' }, moved up
  },
  chatBoxHeader: {
    position: 'relative',
    background: '#fafafaf2', // Same color as the input container
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '110px',
    width: '100%',
    zIndex: 100,
    pt: { xs: 2, sm: 2, md: 2 },
    pb: 1,
    pr: { xs: 2, sm: 2, md: 2 },
    pl: { xs: 2, sm: 2, md: 2 },
  },
  statusIndicator: {
    position: 'absolute',
    top: 8,
    left: 8,
  },
  optionsMenu: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
  contactsContainer: {
    width: '100%',
    maxWidth: '100%',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    '&::-webkit-scrollbar': { display: 'none' },
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    mt: 4,
  },
  contactsHeading: {
    fontSize: '14px',
    color: '#000',
    textAlign: 'left',
    flexShrink: 0,
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  messagesContainer: {
    display: 'flex', 
    flexDirection: 'column', 
    // maxHeight: '80vh', 
    pb: {xs: '100px', sm:'95px', md:'75px', lg:'20px'}, 
    pt: '70px', 
    pl: 1.5,
    pr: 1.5,
    justifyContent: 'flex-start', 
    gap: '14px', 
    flexGrow: 1, 
    overflow: 'scroll'
  },
  dayDivider: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    margin: '0 auto',
    padding: '4px 8px',
    backgroundColor: 'rgba(0,0,0,.1)',
    borderRadius: '16px',
    transition: '300ms ease-in-out'
  },
  inputBox: {
    background: '#fafafaf2', 
    bottom:0,
    left:0, 
    display: 'flex', 
    position: {xs:'fixed', sm:'fixed', md:'relative'}, 
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    pt: 0,
    pb: {xs: .5, sm: .5, md: 1},
    pr: {xs: .5, sm: .5, md: 2},
    pl: {xs: .5, sm: .5, md: 2},
  },
  formControl: {
    width: '100%'
  },
  inputField: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '10px',
    mb: -.5,
  },
  textField: {
    width: '100%'
  },
  sendButtonProgress: {
    color: 'rgba(63, 81, 181, 1)',
    position: 'absolute',
    top: '.1em',
    zIndex: 10,
    width: '100%',
    opacity: .5,
    borderRadius: 18,
    transition: '300ms'
  },
  inputPropsStyle: {
    backgroundColor: "white",
    color: "",
    fontSize: 16,
  },
  captionTypography: {
    position: "absolute",
    right: "1em",
  },
  iconButtonStyle: {
    mr: 1,
    height: '35px',
    width: '35px',
    display: { xs: 'flex', sm: 'flex', md: 'none' }
  },
  boxFlexRow: {
    display: 'flex', 
    flexDirection: 'row', 
    gap: '4px', 
    alignItems: 'center'
  },
  boxFlexColumn: {
    display: 'flex', 
    flexDirection: 'column', 
    gap: '2px', 
    alignItems: { xs: 'center', sm: 'center', xl: 'flex-start' }
  },
  boxFlexRowGap: {
    display: 'flex', 
    flexDirection: 'row', 
    gap: '2px', 
    mr: 1, 
    mt: 1, 
    alignItems: 'center'
  }
};

const Conversation = ({ conversation }) => {
  const dispatch = useAppDispatch();
  const openConversationId = useAppSelector(state => state.conversations.openConversationId); // Add this line
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null); // Create a ref for the input field
  const [lastMessageCount, setLastMessageCount] = useState(0);
  const channel = useAppSelector(state => selectChannelById(state, conversation.channel_id));
  const lastMessageCountRef = useRef(lastMessageCount);
  const messagesContainerRef = useRef<HTMLDivElement | null>(null);
  const [postingMessage, setPostingMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [sentMessage, setSentMessage] = useState(null);
  const [deletingChat, setDeletingChat] = useState(false);
  const [sendingEntity, setSendingEntity] = useState(() => {
    if (conversation.participating_entities && conversation.participating_entities.length > 0) {
      return conversation.participating_entities.find(entity => entity.role === 'assistant') || conversation.participating_entities[0];
    }
    return null;
  });
  const [messagesPollingActive, setMessagesPollingActive] = useState(false)
  const [conversationLoading, setConversationLoading] = useState(true)
  const [initialMessageSent, setInitialMessageSent] = useState(false)
  const [sendingEntityDrawerOpen, setSendingEntityDrawerOpen] = useState(false)
  const { conversation_id } = conversation.id
  const [sendingProgress, setSendingProgress] = useState(0)
  const [ channelId, _setChannelId ] = useState(conversation.channel_id)
  const [unSentMessage, setUnsentMessage] = useState(null)
  const [isAlteringUnsentMessage, setIsAlteringUnsentMessage] = useState(false)

  useEffect(() => {
    // fetch the conversation with messages
    console.log("fetching conversation with messages");
    dispatch(fetchConversationWithMessages({ conversationId: conversation.id, messagesLimit: 10 }));
    scrollToBottom();
  }, []);

  useEffect(() => {
    if (messagesPollingActive) {
      const interval = setInterval(() => {
        dispatch(fetchConversationWithMessages({ conversationId: conversation.id, messagesLimit: 10 }));
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [messagesPollingActive, conversation.id]);

  useEffect(() => {
    setMessagesPollingActive(true);
    return () => setMessagesPollingActive(false);
  }, [conversation.id]);

  useEffect(() => {
    if (conversation.message_count > lastMessageCount) {
      scrollToBottom();
    }
    if (conversation.message_count !== lastMessageCount) {
      setLastMessageCount(conversation.message_count);
    }
  }, [conversation.message_count]);

  useEffect(() => {
    const hotKey = postMessageHotKey(inputRef, handleSendMessage);
    hotKey.mount();
    return () => hotKey.unmount();
  }, [inputValue, postingMessage]);

  useEffect(() => {
    // Unmount component if openConversationId changes and doesn't match the current conversation id
    if (openConversationId !== conversation.id) {
      setMessagesPollingActive(false);
    }
  }, [openConversationId, conversation.id]);

  const handleMessageClick = (message) => {
    if (message.content === inputValue) {
      return
    }
    if (!inputValue) {
      setUnsentMessage(message)
      setInputValue(message.content)
    } else {
      if (confirm('Are you sure you want to overwrite your current draft message with the one you clicked?')) {
        setUnsentMessage(message)
        setInputValue(message.content)
      } else {
        return
      }
    }
  }

  const setMostRecentPendingDeliveryMessageFromConversation = () => {
    const messages = conversation.messages;
    if (!messages) {
      return
    }
    const mostRecentPendingDeliveryMessage = messages.find(message => message.status === 'pending_delivery');
    if (!mostRecentPendingDeliveryMessage) {
      return
    }
    setUnsentMessage(mostRecentPendingDeliveryMessage);
  }

  const fetchConversationsWithMessagesAction = async () => {
    const conversationId = conversation.id;
    const channelId = conversation.channel_id;
    try {
      await dispatch(fetchConversationWithMessages({ conversationId, messagesLimit: 10 }));
    } catch (err) {
      console.log('Failed to fetch messages: ', err);
    }
  };

  const handleUpdateMessage = async (message) => {
    try {
      await dispatch(updateMessage({ messageId: message.id, content: message, channelId: channelId }))
    } catch (err) {
      setUnsentMessage(null)
      handleMessageClick(message)
    }
  }

  const handleSendMessage = (message: string) => {
    if (postingMessage || !message) {
      return;
    }
    setPostingMessage(true);
    setSendingProgress(0);

    const data = {
      conversation,
      message,
      sendingEntity
    };

    setSendingProgress(80);
    dispatch(sendMessage(data))
      .then(() => {
        setSendingProgress(90);
        setErrorMessage("");
        setInputValue("");
        setLastMessageCount(prevCount => prevCount + 1);
      })
      .catch((error) => {
        setErrorMessage("Error sending message.");
        dispatch(trackAnalytics({ action_name: "user_send_message_error", payload: { error: error } }));
        setSendingProgress(0);
        console.error("Error sending message:", error);
        setPostingMessage(false);
        setSentMessage(null);
        setInputValue(message);
      })
      .finally(() => {
        setSendingProgress(100);
        fetchConversationsWithMessagesAction();
        setPostingMessage(false);
      });
  };

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  };

  const handleTextFieldFocus = () => {
    scrollToBottom();
  }
  const toggleDeliveryState = () => {
    if (conversation.delivery_state === 'pause') {
      dispatch(updateConversationDeliveryState({ conversationId: conversation.id, channelId, deliveryState: 'play' }));
    } else {
      dispatch(updateConversationDeliveryState({ conversationId: conversation.id, channelId, deliveryState: 'pause' }));
    }
  }

  const conversationMessagesGroupedByDay = (messages) => {
    const groupedMessages = messages.reduce((accumulator, message) => {
      const date = new Date(message.deliver_after || message.created_at);
      const dateKey = date.toLocaleDateString();
      if (!accumulator[dateKey]) {
        accumulator[dateKey] = [];
      }
      accumulator[dateKey].push(message);
      return accumulator;
    }, {});
    return groupedMessages;
  }

  const groupedMessages = conversationMessagesGroupedByDay(conversation.messages || []);

  const formatDateToISO8601 = (day) => {
    return groupedMessages[day][0].deliver_after || groupedMessages[day][0].created_at
  }

  const handleSendingEntityChange = (event, newRole) => {
    if (newRole !== null && conversation.participating_entities) {
      const newEntity = conversation.participating_entities.find(entity => entity.role === newRole);
      setSendingEntity(newEntity || null);
    }
  };

  return (
    <>
      <Box sx={styles.chatBox}>
        <Box sx={styles.chatBoxHeader}>
          <Box sx={styles.statusIndicator}>
            <ConversationStatusIndicator conversationStatus={conversation.status} />
          </Box>
          <Box sx={styles.optionsMenu}>
            <ConversationOptionsMenu conversation={conversation} />
          </Box>
          <Typography variant="caption" sx={{ mt: 1 }}>
            {conversation.call_status}
          </Typography>
          <Box sx={styles.contactsContainer}>
            <Typography variant="h5" sx={styles.contactsHeading}>
              <ConversationContacts conversation={conversation} />
            </Typography>
          </Box>
          <LinearProgress
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: '2px',
              zIndex: 1000,
              opacity: conversation.loading ? 1 : 0,
              visibility: conversation.loading ? 'visible' : 'hidden',
              transition: 'opacity 0.3s, visibility 0.3s',
            }}
            
          />
        </Box>
        <Box 
          ref={messagesContainerRef} 
          sx={styles.messagesContainer}
          >
          {conversation && conversation.messages &&
            Object.keys(groupedMessages).map((day) => (
              <Box key={day} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Divider>
                  <Box sx={styles.dayDivider}>
                    {dateTimeFormatter(formatDateToISO8601(day), 'day_month_ordinal_humanized')}
                  </Box>
                </Divider>
                {groupedMessages[day].map((message) => (
                  <Message 
                    key={message.id} 
                    message={message} 
                    sendingEntity={sendingEntity} 
                    channelId={channelId} 
                    fetchConversationsWithMessagesAction={fetchConversationsWithMessagesAction} 
                    handleMessageClick={handleMessageClick} 
                    conversationDeliveryState={conversation.delivery_state} 
                  />
                ))}
              </Box>
            ))
          }

        {!conversation.loading && (!conversation || !conversation.messages || conversation.messages.length === 0) && (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '200px', width: '200px' }}>
            <img src="/images/oppies/Oppy_careers_Inspector-Appraiser.svg" alt="No Messages" />
            <Typography variant="h6" sx={{ mt: 2 }}>No messages, start a conversation now!</Typography>
          </Box>
        )}
        </Box>
        <Box sx={styles.inputBox}>
          <ToggleButtonGroup
            orientation="vertical"
            value={sendingEntity?.role || 'assistant'}
            exclusive
            onChange={handleSendingEntityChange}
            aria-label="Sending entity"
          >
            <ToggleButton value="user" aria-label="User">
              <Icon>person</Icon>
            </ToggleButton>
            <ToggleButton value="assistant" aria-label="Assistant">
              <img src={getBranding('oppy_expressions_no_mouth')} alt="Assistant Icon" style={{ width: '20px', height: '20px' }} />
            </ToggleButton>
            <ToggleButton value="function" aria-label="Function">
              <Icon>tips_and_updates</Icon>
            </ToggleButton>
          </ToggleButtonGroup>
          <FormControl sx={styles.formControl}>
            <Box sx={styles.inputField}>
              <GenerateReplyButton conversation={conversation} />
              <DeliveryStateButton deliveryState={conversation.delivery_state} onClick={toggleDeliveryState} />
            </Box>
            <TextField
              type="text"
              multiline
              placeholder={`${sendingEntity?.role === 'user' ? 'Write as the contact' : sendingEntity?.role === 'assistant' ? `Write as your Oppy, ${channel?.chatbot_name}` : `May the force be with you! Write an internal note to inform or prompt your Oppy, ${channel?.chatbot_name}. Invisible to your contact, but ${channel?.chatbot_name} will read it and keep relevant information in mind for future messages.`}...`}
              value={inputValue}
              autoComplete="off"
              onChange={(e) => setInputValue(e.target.value)}
              autoFocus={true}
              onFocus={() => {handleTextFieldFocus()}}
              disabled={postingMessage}
              inputRef={inputRef} // Ensure inputRef is assigned here
              inputProps={{
                maxLength: 1000
              }}
              InputProps={{
                style: styles.inputPropsStyle,
                endAdornment: (
                  <>
                    <IconButton
                      disabled={!inputValue}
                      onClick={() => {
                        handleSendMessage(inputValue);
                      }}
                    >
                      {postingMessage ? 
                          <CircularProgress 
                            size={24} 
                            thickness={5}
                            sx={styles.sendButtonProgress}
                            variant="determinate" 
                            value={sendingProgress}
                          />
                        :
                          <SendIcon sx={{ color: 'rgba(63, 81, 181, 1)' }} />
                      }
                    </IconButton>
                    <Box sx={{mb:"-35px"}}>
                      <Typography
                        variant="caption"
                        color={inputValue.length >= 1000 ? "error" : "text.secondary"}
                        sx={styles.captionTypography}
                      >
                      {`${(inputValue as string).length} /
                        1000`}
                      </Typography>
                    </Box>
                  </>
                ),
              }}
              sx={styles.textField}
            />
          </FormControl>
        </Box>
        <ConversationMemoryDrawer conversationId={conversation.id} />

      </Box>
    </>
  );
};

export default Conversation;
