import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import GooglePlacesAutocomplete, { geocodeByAddress } from 'react-google-places-autocomplete';
import { adminBookingSchema } from 'src/utils/formSchemas';
import NumberFormat from 'react-number-format';
import CustomMap from 'src/components/BookingConfirmation/CustomMap';
import BookingServices from './BookingServices';
import { calculateDistanceAdmin, createAdminBooking, getAdminAvailabilities } from 'src/features/services/booking.slice';
import ErrorPopup from 'src/components/ErrorPopup';
import { BookingTime } from 'src/components/Booking/BookingTime';
import PhoneInput from 'react-phone-input-2';

const BookingForm = ({ provider, filledAddress, dispatch }) => {
  const [bookingDate, setBookingDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [photographerAvailabilities, setPhotographerAvailabilities] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [showListingPrice, setShowListingPrice] = useState(false);
  const [showSquareFeet, setShowSquareFeet] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [showError, setShowError] = useState(false);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);
  const [expensive, setExpensive] = useState(0);

  useEffect(() => {
    if (filledAddress) {
      geocodeByAddress(filledAddress?.label)
        .then(results => {
          if (results[0]) {
            setValue({ value: results[0], label: results[0].formatted_address })
          }
        })
    }
  }, [filledAddress]);

  const initialValues = {
    address: filledAddress?.label || '',
    listing_price: '',
    square_feet: '',
    duration: 0,
    created_by_first_name: '',
    created_by_last_name: '',
    created_by_phone: '',
    created_by_email: '',
    show_listing_price: showListingPrice || false,
    show_square_feet: showSquareFeet || false,
  };

  const setErrorShow = (error) => {
    setError(error)
    setTimeout(() => {
      setError(null)
    }, 5000);
  };

  const removeSelectedSlotDetails = () => {
    setSelectedSlot(null)
    setBookingDate('')
    setPhotographerAvailabilities([])
  };

  const onSubmit = (data) => {
    if (selectedSlot) {
      const clientData = {
        created_by_first_name: data.created_by_first_name,
        created_by_last_name: data.created_by_last_name,
        created_by_phone: data.created_by_phone,
        created_by_email: data.created_by_email
      }
      const info = {
        uuid: provider.uuid,
        service_ids: selectedServices.map((service) => service.id),
        selected_time: selectedSlot.start_time,
        booking: clientData,
      }
      setIsLoading(true)
      dispatch(createAdminBooking({ ...info, ...data })).unwrap().then(() => {
        window.location.reload()
      }).catch((error) => {
        setIsLoading(false)
        setErrorShow(error)
      })
    } else {
      setErrorShow("Select a Date and Slot for booking!")
    }
  };

  const hasTheseFields = (requiredFields, errors) => {
    let arePresent = true
    requiredFields.forEach((field) => {
      if (Object.keys(errors).includes(field)) { arePresent = false }
    })
    return arePresent
  };

  const handleAddressChange = (e, setFieldValue) => {
    dispatch(calculateDistanceAdmin({ address: e.label, uuid: provider.uuid })).unwrap().then(() => {
      setValue(e)
      setFieldValue('address', e.label)
    }).catch((error) => {
      setErrorShow(error)
      setValue(null)
      setFieldValue('address', "")
      removeSelectedSlotDetails()
    })
  };

  const getAvailabilities = (date, duration, values) => {
    const data = { date: date, uuid: provider.uuid, address: value.label, square_feet: values.square_feet, listing_price: values.listing_price, duration: duration, service_ids: selectedServices.map((service) => service.id) }
    setIsLoading(true)
    dispatch(getAdminAvailabilities(data)).unwrap().then((result) => {
      setIsLoading(false)
      setPhotographerAvailabilities(result.available_time_slots)
    }).catch((error) => {
      setIsLoading(false)
      setErrorShow(error)
    })
  };

  const handleDurationChange = (duration, setFieldValue) => {
    setFieldValue('duration', duration)
    setShowError(true)
    removeSelectedSlotDetails()
  };

  const handleDateChange = (date, errors, values) => {
    if (hasTheseFields(["square_feet", "listing_price", "duration"], errors) && selectedServices.length > 0) {
      setBookingDate(date);
      getAvailabilities(date, values.duration, values)
    } else {
      setErrorShow("Select atleast one service")
    }
  };

  const checkErrorAndAddService = (service, errors, setFieldValue, values) => {
    setShowError(true)

    if (hasTheseFields(["square_feet", "listing_price"], errors) && service.price) {
      setSelectedServices([...selectedServices, service])
      calculateDuration(service, setFieldValue, values.square_feet)
      removeSelectedSlotDetails()
    }
  };

  const calculateDuration = (currentService, setFieldValue, square_feet) => {
    const updatedDuration = [...selectedServices, currentService]
      .reduce((totalDuration, service) =>
        totalDuration + Math.round(service.fixed_time + (square_feet / 1000) * service.time_per_k), 0
      )

    setFieldValue('duration', updatedDuration)
  };

  const handleRemove = (service, setFieldValue, values) => {
    setSelectedServices(selectedServices.filter((s) => s.id != service.id))

    const updatedDuration = selectedServices.filter((s) => s.id != service.id)
      .reduce((totalDuration, service) =>
        totalDuration + Math.round(service.fixed_time + (values.square_feet / 1000) * service.time_per_k), 0
      )

    setFieldValue('duration', updatedDuration)
    removeSelectedSlotDetails()
  };

  useEffect(() => {
    if (photographerAvailabilities.length != 0) { calculateExpensive() }
  }, [photographerAvailabilities]);

  const totalPrice = selectedServices.reduce((sum, service) => sum = sum + parseFloat(service?.price), 0)

  const calculateExpensive = () => {
    let maxFee = 0

    photographerAvailabilities.map((slot, index) => {
      maxFee = Math.max(maxFee, slot.travel_cost);
    })

    setExpensive(totalPrice + maxFee)
  };

  return (
    <>
      {error && <ErrorPopup message={error} />}
      <div className="d-flex flex-column align-items-center text-center">
        <p className="fs-4 px-3 fw-medium lh-sm">Create Booking for {provider.first_name}</p>
        <div className='w-100'>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize
            validationSchema={adminBookingSchema}
            validateOnMount
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              resetForm,
              values,
              touched,
              errors,
            }) => {
              return (
                <Form noValidate onSubmit={handleSubmit}>

                  <div className="mb-32">
                    <Form.Label htmlFor="formatted_address">
                      Address <span className="text-danger">*</span>
                    </Form.Label>

                    <GooglePlacesAutocomplete
                      apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                      selectProps={{
                        value,
                        onChange: (e) => {
                          handleAddressChange(e, setFieldValue)
                        },
                      }}
                    />
                    {showError && <p className='text-danger'>
                      <>{errors.address}</>
                    </p>}
                    <Col style={{ height: '300px', position: "relative" }}>
                      <CustomMap
                        address={values.address}
                        zoom={values.address ? 16 : 10}
                      />
                    </Col>
                  </div>

                  {showSquareFeet && <Form.Group>
                    <InputGroup className="mb-2">
                      <NumberFormat
                        thousandSeparator={true}
                        placeholder="Square feet (required)"
                        className={`mb-2 fs-14 form-control ${showError && !!errors.square_feet ? "is-invalid" : ""}`}
                        name="square_feet"
                        allowNegative={false}
                        value={values.square_feet}
                        onValueChange={(e) => { setFieldValue("square_feet", e.value) }}
                      />
                      <InputGroup.Text className="mb-2 fs-14">Sq. Ft.</InputGroup.Text>
                      <Form.Control.Feedback type="invalid">
                        <>{errors.square_feet}</>
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>}

                  {showListingPrice && <Form.Group>
                    <InputGroup className="mb-2">
                      <InputGroup.Text className="mb-2 fs-14">$</InputGroup.Text>
                      <NumberFormat
                        thousandSeparator={true}
                        placeholder="Listing Price (required)"
                        className={`mb-2 fs-14 form-control ${showError && !!errors.listing_price ? "is-invalid" : ""}`}
                        name="listing_price"
                        allowNegative={false}
                        value={values.listing_price}
                        onValueChange={(e) => { setFieldValue("listing_price", e.value) }}
                      />
                      <Form.Control.Feedback type="invalid">
                        <>{errors.listing_price}</>
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>}

                  <BookingServices
                    dispatch={dispatch}
                    provider={provider}
                    selectedServices={selectedServices}
                    handleAddToCart={(service) => { checkErrorAndAddService(service, errors, setFieldValue, values) }}
                    handleRemove={(service => handleRemove(service, setFieldValue, values))}
                    address={values.address}
                    square_feet={values.square_feet}
                    listing_price={values.listing_price}
                    setShowSquareFeet={setShowSquareFeet}
                    setShowListingPrice={setShowListingPrice}
                  />

                  <Form.Group>
                    <Form.Label htmlFor="duration">
                      Duration (min)<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      value={values.duration}
                      id="duration"
                      name="duration"
                      type="number"
                      min={0}
                      className={`mb-2 fs-14 form-control ${showError && !!errors.duration ? "is-invalid" : ""}`}
                      onChange={(e) => handleDurationChange(e.target.value, setFieldValue)}
                    />
                    <Form.Control.Feedback type="invalid">
                      <>{errors.duration}</>
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3 mx-5 mt-2">
                    <Form.Label htmlFor="duration">
                      Select Date<span className="text-danger">*</span>
                    </Form.Label>
                    <InputGroup className="mb-2">
                      <Form.Control
                        type="date"
                        value={bookingDate}
                        placeholder="Pick a date"
                        title="Pick a date"
                        className="mb-2 fs-14"
                        onChange={(e) => handleDateChange(e.target.value, errors, values)}
                      />
                      <Form.Control.Feedback type="invalid">
                        <>{errors.listing_price}</>
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  {isLoading && <div className='text-center my-4' >Loading...</div>}

                  {!isLoading && bookingDate && (photographerAvailabilities.length > 0) && (
                    <div className="mx-5">
                      <div className="fw-medium-light fs-14">Times displayed in: {provider.timezone}</div>
                      {photographerAvailabilities.map((slot, index) => (
                        <div key={index}>
                          <div className={`me-2 ${JSON.stringify((slot)) === JSON.stringify((selectedSlot)) ? "add-border" : ""}`}>
                            <BookingTime
                              time={slot.start_time_element}
                              meridian={slot.meridian}
                              onClick={() => setSelectedSlot(slot)}
                              discount={Math.round(((expensive - (totalPrice + slot.travel_cost)) / expensive) * 100)}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  {!isLoading && bookingDate && (photographerAvailabilities.length === 0) &&
                    <div className="mx-4 text-center">
                      <img src="./images/SadCalendar.svg" alt="" width="171" />
                      <div className="fw-medium fs-14 mt-4">
                        No availability for this date.
                      </div>
                      <p className=" fs-14 mt-1 mb-4">
                        Please select another day or check in later for availability!
                      </p>
                    </div>}

                  <Form.Group className="mb-3">
                    <Form.Label className="fw-medium-light fs-14 my-4">
                      Contact
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="First Name"
                      className={`mb-2 fs-14 form-control ${showError && touched.created_by_first_name && !!errors.created_by_first_name ? "is-invalid" : ""}`}
                      name="created_by_first_name"
                      value={values.created_by_first_name}
                      onChange={handleChange}
                      isValid={touched.created_by_first_name && !errors.created_by_first_name}
                      isInvalid={touched.created_by_first_name && !!errors.created_by_first_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      <>{errors.created_by_first_name}</>
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      className={`mb-2 fs-14 form-control ${showError && touched.created_by_last_name && !!errors.created_by_last_name ? "is-invalid" : ""}`}
                      name="created_by_last_name"
                      value={values.created_by_last_name}
                      onChange={handleChange}
                      isValid={touched.created_by_last_name && !errors.created_by_last_name}
                      isInvalid={touched.created_by_last_name && !!errors.created_by_last_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      <>{errors.created_by_last_name}</>
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <PhoneInput
                      value={values.created_by_phone}
                      enableSearch={true}
                      onlyCountries={['us']}
                      disableDropdown={true}
                      country="us"
                      placeholder="Enter phone number"
                      inputProps={{
                        id: "created_by_phone",
                        name: "created_by_phone",
                        className: "mb-2 fs-14 ht-46 w-100 form-control",
                      }}
                      isValid={touched.created_by_phone && !errors.created_by_phone}
                      onChange={(e) => {
                        if (!e.startsWith("1")) { e = "1" + e }
                        setFieldValue("created_by_phone", e)
                      }}
                    />
                    {showError && touched.created_by_phone && <div className='text-danger'>
                      <>{errors.created_by_phone}</>
                    </div>}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Control
                      type="email"
                      placeholder="Email Address"
                      className={`mb-2 fs-14 form-control ${showError && touched.created_by_email && !!errors.created_by_email ? "is-invalid" : ""}`}
                      name="created_by_email"
                      value={values.created_by_email}
                      onChange={handleChange}
                      isValid={touched.created_by_email && !errors.created_by_email}
                      isInvalid={touched.created_by_email && !!errors.created_by_email}
                    />
                    <Form.Control.Feedback type="invalid">
                      <>{errors.created_by_email}</>
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Button
                    className="w-100 mb-3"
                    variant="primary"
                    type="submit"
                    disabled={isLoading}
                  >
                    <span className="fw-medium">Create Booking</span>
                  </Button>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default BookingForm;
