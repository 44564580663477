import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, Button, TextField, Grid, CircularProgress } from '@mui/material';
import { Formik } from 'formik';
import { createContact } from 'src/features/account/contacts.slice';
import { contactSchema } from 'src/utils/formSchemas';
import { useAppDispatch } from 'src/store';

interface ContactCreateDialogProps {
  handleContactUpdate?: (updatedContact: any) => void;
  initialValues?: any;
  open: boolean;
  onClose: () => void;
}

const ContactCreateDialog: React.FC<ContactCreateDialogProps> = ({ handleContactUpdate, initialValues: initialPropsValues, open, onClose }) => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState({
    name: '',
    phone_number: '',
    email: '',
    first_name: '',
    last_name: '',
    address: '',
    notes: '',
    metadata: {},
    picture_url: '',
    status: '',
    preferred_contact_method: '',
    company_name: '',
    company_size: null,
    company_industry: '',
    company_website: '',
    company_address: '',
    home_address: '',
    tags: [],
    timezone: '',
  });

  useEffect(() => {
    if (initialPropsValues) {
      setInitialValues(initialPropsValues);
    }
  }, [initialPropsValues]);

  const handleClose = () => { onClose(); };

  const onSubmit = (values) => {
    setIsSubmitting(true)
    dispatch(createContact(values))
        .unwrap()
    .then(() => {
      if (handleContactUpdate) {
        handleContactUpdate(values);
      }
      setIsSubmitting(false)
      handleClose();
    })
    .catch(error => {
      setIsSubmitting(false)
      console.error('Failed to create contact:', error);
    })
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>New Contact</DialogTitle>
        <DialogContent>
          <Formik 
            initialValues={initialValues}
            // validationSchema={contactSchema}
            onSubmit={onSubmit}
            >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <form noValidate onSubmit={(e) => {
                console.log('Form submitted'); // Check if this logs when you submit the form
                handleSubmit(e);
              }}>                <Grid sx={{ mt: 1 }} container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="first_name"
                      label="First Name"
                      value={values.first_name}
                      onChange={handleChange}
                      error={touched.first_name && Boolean(errors.first_name)}
                      helperText={touched.first_name && errors.first_name}
                      fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="last_name"
                      label="Last Name"
                      value={values.last_name}
                      onChange={handleChange}
                      error={touched.last_name && Boolean(errors.last_name)}
                      helperText={touched.last_name && errors.last_name}
                      fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="phone_number"
                      label="Phone Number"
                      value={values.phone_number}
                      onChange={handleChange}
                      error={touched.phone_number && Boolean(errors.phone_number)}
                      helperText={touched.phone_number && errors.phone_number}
                      fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="email"
                      label="Email"
                      value={values.email}
                      onChange={handleChange}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      fullWidth required />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="address"
                      label="Address"
                      value={values.address}
                      onChange={handleChange}
                      error={touched.address && Boolean(errors.address)}
                      helperText={touched.address && errors.address}
                      fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="notes"
                      label="Notes"
                      value={values.notes}
                      onChange={handleChange}
                      error={touched.notes && Boolean(errors.notes)}
                      helperText={touched.notes && errors.notes}
                      fullWidth multiline rows={4} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="company_name"
                      label="Company Name"
                      value={values.company_name}
                      onChange={handleChange}
                      error={touched.company_name && Boolean(errors.company_name)}
                      helperText={touched.company_name && errors.company_name}
                      fullWidth />
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                    <TextField
                      name="company_size"
                      label="Company Size"
                      value={values.company_size}
                      onChange={handleChange}
                      error={touched.company_size && Boolean(errors.company_size)}
                      helperText={touched.company_size && errors.company_size}
                      fullWidth type="number" 
                      required={false} />
                  </Grid> */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="company_industry"
                      label="Industry"
                      value={values.company_industry}
                      onChange={handleChange}
                      error={touched.company_industry && Boolean(errors.company_industry)}
                      helperText={touched.company_industry && errors.company_industry}
                      fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="company_website"
                      label="Company Website"
                      value={values.company_website}
                      onChange={handleChange}
                      error={touched.company_website && Boolean(errors.company_website)}
                      helperText={touched.company_website && errors.company_website}
                      fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="company_address"
                      label="Company Address"
                      value={values.company_address}
                      onChange={handleChange}
                      error={touched.company_address && Boolean(errors.company_address)}
                      helperText={touched.company_address && errors.company_address}
                      fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="home_address"
                      label="Home Address"
                      value={values.home_address}
                      onChange={handleChange}
                      error={touched.home_address && Boolean(errors.home_address)}
                      helperText={touched.home_address && errors.home_address}
                      fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="tags"
                      label="Tags"
                      value={values.tags}
                      onChange={handleChange}
                      error={touched.tags && Boolean(errors.tags)}
                      helperText={touched.tags && errors.tags}
                      fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="timezone"
                      label="Timezone"
                      value={values.timezone}
                      onChange={handleChange}
                      error={touched.timezone && Boolean(errors.timezone)}
                      helperText={touched.timezone && errors.timezone}
                      fullWidth />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                  sx={{ mt: 3 }}
                >
                  {isSubmitting ? (
                    <>
                      <CircularProgress size={24} sx={{ mr: 1 }} />
                      Creating...
                    </>
                  ) : (
                    'Create Contact'
                  )}
                </Button>
              </form>
            )}
            </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ContactCreateDialog;
