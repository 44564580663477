import { useEffect, useState } from 'react'
import { Circle, GoogleMap, Marker } from '@react-google-maps/api';

const UsersMap = ({ users, isLoaded }) => {
  const [filteredUsers, setFilteredUsers] = useState(null)
  const [firstUserLatLong, setFirstUserLatLong] = useState(null)

  useEffect(() => {
    if (users) {
      const usersWithAddress = users.filter((user) => (user.address?.lng && user.address?.lat))
      setFilteredUsers(usersWithAddress)
      setFirstUserLatLong(usersWithAddress?.length > 0 &&
        { lat: parseFloat(usersWithAddress[0]?.address.lat), lng: parseFloat(usersWithAddress[0]?.address.lng) })
    }
  }, [users]);

  function calculateRadiusInMiles(user) {
    return user.surcharge_travel_distance * 1611
  }

  return isLoaded && filteredUsers != null ? (
    <GoogleMap
      mapContainerStyle={{ width: '100%', height: '100%' }}
      center={firstUserLatLong ? firstUserLatLong : { lat: parseFloat("0.0"), lng: parseFloat("0.0") }}
      zoom={firstUserLatLong ? 10 : 2}
    >
      {filteredUsers && filteredUsers.map((user, index) => (
        <Marker
          title={`${user.first_name} ${user.last_name}`}
          position={{ lat: parseFloat(user.address.lat), lng: parseFloat(user.address.lng) }}
          key={`marker-${user.id || index}`}
        >
        </Marker>
      ))}
      {filteredUsers && filteredUsers.map((user, index) => (
        <Circle
          key={`circle-${user.id || index}`}
          center={{
            lat: parseFloat(user.address.lat),
            lng: parseFloat(user.address.lng),
          }}
          radius={calculateRadiusInMiles(user)}
          options={{
            strokeColor: 'transparent',
            strokeOpacity: 1,
            strokeWeight: 5,
            fillColor: '#2f80ed',
            fillOpacity: 0.2,
          }}
        />
      ))}
    </GoogleMap>
  ) : <div>Waiting for Users to Load...</div>
}

export default (UsersMap)
