import { 
  createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { 
  fetchChannels, processPusherChannelUpdated, processPusherChannelCreated, processPusherChannelDeleted, 
  upsertChannel,
  removeChannel} from 'src/features/account/channels.slice';
import { 
  fetchConversationWithMessages,
  processPusherConversationUpdated, 
  upsertConversation, 
  removeConversation, 
  selectConversationsWithContacts, 
  selectAllConversations,
  selectConversationById,
  removeMessageFromConversation
} from 'src/features/account/conversations.slice';
import { removeContactState, updateContactState } from 'src/features/account/contacts.slice';
import { useAppSelector, useAppDispatch } from 'src/store';
import { 
  addOrUpdateMessage,
  mergeExistingOrNewConversationAndGetUpdatedConversationObject,  
} from 'src/utils/reducerHelpers';
import { useSnackbar, closeSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import CloseIcon from '@mui/icons-material/Close';

export const useHandlePusherEvents = () => {
  const dispatch = useAppDispatch();
  // const conversations = useAppSelector(selectAllConversations);
  // console.warn('conversationsLoadedInPusherEvents', conversations);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const snackbarNewMessageAction = (snackbarId, message) => {
    return (
      <>
        <IconButton onClick={() => { navigate(`/conversations?conversation_id=${message.conversation_id}&channel_id=${message.channel_id}&message_id=${message.id}`); }}>
          <MarkUnreadChatAltIcon sx={{ color: 'white' }} />
        </IconButton>
        <IconButton onClick={() => closeSnackbar(snackbarId)}>
          <CloseIcon />
        </IconButton>
      </>
    );
  }

  function conversationStatusChangeActions(initialStatus: string, newStatus: string) {
    if (!['error', 'needs_attention'].includes(initialStatus) && ['error', 'needs_attention'].includes(newStatus)) {
      new Audio('/audio/alert.mp3').play();
    }
  }

  function newMessageActions(conversation: any, message: any) {
    const senderEntity = conversation?.participating_entities?.find(entity => entity?.id === message?.sender_id);

    const truncatedMessageContent = message.content.length > 100 ? message.content.substring(0, 100) + '...' : message.content;
    const senderString = senderEntity?.name ? `${senderEntity.name}: ` : '';
    const notiMessage = `${senderString}${truncatedMessageContent}`;
    if (senderEntity?.role === 'function') {
      new Audio('/audio/keyboard.mp3').play();
    } else {
      new Audio("/audio/message_received.mp3").play();
    }
    enqueueSnackbar(notiMessage, {
      hideIconVariant: true,
      autoHideDuration: 4000,
      action: snackbarNewMessageAction
    });
  }

  const handleEvent = ({ eventName, data, collection = [], existingObject = null }) => {
    // alert(`eventName: ${eventName} data: ${JSON.stringify(data)} collection: ${JSON.stringify(collection)} existingObject: ${JSON.stringify(existingObject)}`);
    // enqueueSnackbar(`eventName: ${eventName}`);
    if (eventName === 'account_updated') {
      console.log(`account_updated at ${new Date().toISOString()}`);
    } else if (eventName.includes('channel_') || eventName.includes('faq_')) {
      if (eventName === 'channel_created') {
        dispatch(upsertChannel(data.channel));
      } else if (eventName === 'channel_updated') {
        console.log("channel_updated ABOUT TO UPSERT", data.channel);
        dispatch(upsertChannel(data.channel));
      } else if (eventName === 'channel_deleted') {
        dispatch(removeChannel(data.channel));
      } else if (eventName === 'faq_created') {
        // const channel = collection.find(channel => channel.id === data.faq.channel_id);
        // const channelWithFaq = { ...channel, faqs: [...channel.faqs, data.faq] };
        // dispatch(upsertChannel(channelWithFaq));
      } else if (eventName === 'faq_updated') {
        // const channel = collection.find(channel => channel.id === data.faq.channel_id);
        // dispatch(upsertChannel(channel));
      }
    } else if (data.conversation) {
      const conversations = collection;
      const existingConversation = conversations.find(conversation => conversation.id === data.conversation.id);
      if (eventName === 'conversation_updated') {
        const updatedConversation = mergeExistingOrNewConversationAndGetUpdatedConversationObject(existingConversation, data.conversation);
        if (existingConversation && existingConversation?.status !== updatedConversation?.status) {
          conversationStatusChangeActions(existingConversation?.status, updatedConversation?.status)
        }
        dispatch(upsertConversation(updatedConversation));
      } else if (eventName === 'conversation_created') {
        dispatch(upsertConversation(data.conversation));
      } else if (eventName === 'conversation_deleted') {
        dispatch(removeConversation(data.conversation));
      } else {
        console.log('Unhandled event: conversation_updated');
      }
    } else if (data.message) {
      const conversations = collection;
      const existingConversation = conversations.find(conversation => conversation.id === data.message.conversation_id);
      if (eventName === 'message_created') {
        newMessageActions(existingConversation, data.message);
        if (existingConversation) {
          const updatedConversation = addOrUpdateMessage(existingConversation, data.message);
          dispatch(upsertConversation(updatedConversation));
        } else {
          dispatch(fetchConversationWithMessages(data.message.conversation_id));
        }

      } else if (eventName === 'message_deleted') {
        if (existingConversation) {
          dispatch(removeMessageFromConversation({ messageId: data.message.id, conversationId: data.message.conversation_id }));
        } else {
          dispatch(fetchConversationWithMessages(data.message.conversation_id));
        }
      } else if (eventName === 'message_updated') {
        if (data.message.requires_fetch) {
          return dispatch(fetchConversationWithMessages(data.message.conversation_id));
        }
        if (existingConversation) {
          const updatedConversation = addOrUpdateMessage(existingConversation, data.message);
          dispatch(upsertConversation(updatedConversation));
        } else {
          dispatch(fetchConversationWithMessages(data.message.conversation_id));
        }
      }
    } else if (data.contact) {
      const contacts = collection;
      const existingContact = contacts.find(contact => contact.id === data.contact.id);
      if (eventName === 'contact_updated') {
        dispatch(updateContactState(data.contact));
      } else if (eventName === 'contact_created') {
        dispatch(updateContactState(data.contact));
      } else if (eventName === 'contact_deleted') {
        dispatch(removeContactState(data.contact.id));
      } else {
        console.log(`Unhandled contact event: ${eventName}`);
      }
    } else {
      console.log(`Unhandled event: ${eventName}`);
    }
  };

  return handleEvent;
};
