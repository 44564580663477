import { Col, Nav, Image, Container, Row } from 'react-bootstrap';
import GoBack from 'src/components/GoBack';
import Layout from '../Layout';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

const ProfileLayout = ({children, activeKey}) => {

  return (
    <Layout>
      <Container fluid className="with-navbar base-container">
        <Row>
          <Col md={4} lg={3} className="profile-sidebar">
            <GoBack
              path="/channels"
              text="Go Back"
              className="mb-3"
            />
            <p className="d-flex">
              <Image src="icon/settingsIcon.svg" className="me-2" />
              <Typography variant='h5' sx={{ color: '#000000' }}>Settings</Typography>
            </p>
            <Nav defaultActiveKey={activeKey} className="flex-column">
              <Nav.Item >
                <Nav.Link as={Link} to="/profile" eventKey="account" className="sidebar-link">
                  <Typography variant='h6' sx={{ color: '#000000', fontSize: '16px' }}>Account</Typography>
                  <Typography variant="caption" sx={{ fontSize: '12px' }}>Email, password and more</Typography>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item >
                <Nav.Link as={Link} to="/booking-availability" eventKey="booking" className="sidebar-link">
                  <Typography variant='h6' sx={{ color: '#000000', fontSize: '16px' }}>Your Availability</Typography>
                  <Typography variant="caption" sx={{ fontSize: '12px' }}>Office/Working Hours</Typography>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item >
                <Nav.Link as={Link} to="/google-calendar" eventKey="google_calendar" className="sidebar-link">
                  <Typography variant='h6' sx={{ color: '#000000', fontSize: '16px' }}>Calendar Connection</Typography>
                  <Typography variant="caption" sx={{ fontSize: '12px' }}>Manage your connected calendar</Typography>
                </Nav.Link>
              </Nav.Item>

              {/* <Nav.Item >
                <Nav.Link as={Link} to="/payment-processing" eventKey="payment_processing" className="sidebar-link">
                  <Typography variant='h6' sx={{ color: '#000000', fontSize: '16px' }}>Payment Processing</Typography>
                  <Typography variant="caption" sx={{ fontSize: '12px' }}>Service Booking</Typography>
                </Nav.Link>
              </Nav.Item> */}
              <Nav.Item >
                <Nav.Link as={Link} to="/travel-and-location" className="sidebar-link" eventKey="travel">
                  <Typography variant='h6' sx={{ color: '#000000', fontSize: '16px' }}>Advanced Scheduling Preferences</Typography>
                  <Typography variant="caption" sx={{ fontSize: '12px' }}>Buffers, drive times and filters</Typography>
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item >
                <Nav.Link as={Link} to="/confirmation-message" eventKey="confirmation" className="sidebar-link">
                  <Typography variant='h6' sx={{ color: '#000000', fontSize: '16px' }}>Confirmation Message</Typography>
                  <Typography variant="caption" sx={{ fontSize: '12px' }}>Service Booking</Typography>
                </Nav.Link>
              </Nav.Item> */}
            </Nav>
          </Col>

          <Col md={8} lg={9}>
            <Row className="d-flex justify-content-around mt-5 mb-5">
              <Col md={10} lg={11} xl={9}>
                {children}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default ProfileLayout;
