import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, InputLabel, MenuItem, Select, Skeleton, Typography, Divider, CircularProgress, Checkbox, FormControlLabel, FormControl, FormHelperText, Box, TextareaAutosize, Grid, IconButton, Button } from "@mui/material";
import { useAppSelector } from "src/store";

const ChannelEmbedDialog = ({ open, onClose, channelId }) => {
  const channel = useAppSelector((state) => state.channels.channels.find(channel => channel.id === channelId));

  const embedScript = (embedKey: string) => {
    return `
      <!-- Begin Oppy.pro Chat Popup Code -->
      <script type="text/javascript">

        var embedKey = '${embedKey}';

        function handleOppyLoad() {
          if (typeof window.renderChatPopup === "function" && !document.getElementById(embedKey)) {
            window.renderChatPopup(embedKey);
          }
        }

        function updateChatWindow(incomingMessage) {
          if (document.getElementById(embedKey)) {
            window.updateChat(embedKey, incomingMessage, true);
          } else {
            // Initialize new Oppy instance & send the new message
            window.renderChatPopup(embedKey, true, incomingMessage);
          }
        }

        (function () {
          var ldoppy = function () {
            if (typeof window.__oppyld != "undefined") return;
            window.__oppyld = 1;
            var oppy = document.createElement('script');
            oppy.type = 'text/javascript';
            oppy.async = true;
            oppy.id = "oppy-script";
            oppy.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://e.oppy.pro//chat_popup.js';
            var x = document.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(oppy, x);
            oppy.onload = handleOppyLoad;
          };

          if (document.readyState === "loading") {
            document.addEventListener("DOMContentLoaded", ldoppy);
          } else {
            ldoppy();
          }
        })();

      </script>
      <!-- End Oppy.pro Chat Popup Code -->
    `
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth

    >
      <DialogTitle>Embed Code</DialogTitle>
      {/* now do DialogTitle Embed Code followed by the remaining DialogContent */}
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        {/* mui textarea with the embed script you can copy on focus it highlights everything in the textarea */}
        <TextareaAutosize
          value={embedScript(channel.embed_key)}
          onFocus={(e) => e.target.select()}
          onClick={(e) => e.currentTarget.select()}
          style={{ height: '100%', borderRadius: '8px', padding: '8px' }}
          aria-label="minimum height"
          minRows={8}
          maxRows={15}
          cols={18}
          readOnly
        />
        <Typography variant="caption" sx={{}}>
          {`Copy the embed code above and paste it into your website's HTML right after the first <head> tag. For assistance, please contact us!`}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default ChannelEmbedDialog;
