import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Container, Link, TextField, Typography, CircularProgress } from '@mui/material';
import { trackAnalytics } from 'src/features/analytics/analytics.slice';
import { forgotPassword } from 'src/features/auth/auth.slice';
import { useAppDispatch, useAppSelector } from 'src/store';
import { forgotPasswordSchema } from 'src/utils/formSchemas';
import { getBranding } from 'src/utils/branding';
import { useSnackbar } from 'notistack';

const ForgotPassword = ({ displayedAuthScreen, setDisplayedAuthScreen }) => {
  const { enqueueSnackbar } = useSnackbar();
  const initialValues = {
    email: '',
  };
  const dispatch = useAppDispatch();
  const { authError, authSuccessMessage } = useAppSelector((state) => state.auth);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const branding_logo = getBranding('logo');

  useEffect(() => {
    setError(authError);
  }, [authError]);

  // notistack notification for authSuccessMessage
  useEffect(() => {
    if (authSuccessMessage && authSuccessMessage.length > 0) {
      enqueueSnackbar(authSuccessMessage, { variant: 'success' });
    }
  }, [authSuccessMessage]);

  const onSubmit = (data) => {
    setLoading(true);
    dispatch(forgotPassword(data))
      .unwrap()
      .then(() => {
        dispatch(trackAnalytics({ action_name: "forgot_password_clicked", payload: { description: `user with email ${data.email} requested for password reset` } }));
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Typography variant='h3'
        sx={{
          opacity: 0.9,
        }}
      >Forgot Password
      </Typography>
      <Typography variant='body2'
        sx={{
          opacity: 0.7,
          pb: 1
        }}
      >
        Enter your email address to reset your password
      </Typography>
      <Box sx={{ mt: 3, width: '100%' }}>
        <Formik
          initialValues={initialValues}
          validationSchema={forgotPasswordSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={values.email}
                onChange={handleChange}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />
              {authError && !errors.email && <Typography color="error">{authError}</Typography>}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Send Reset Password Email'}
              </Button>
            </Box>
          )}
        </Formik>
        <Typography component="p" sx={{ mt: 2, textAlign: 'center' }}>
          Have a login and password?
          <Link
            component={RouterLink}
            to="/login"
            sx={{ ml: 1 }}
            onClick={() => setDisplayedAuthScreen('login')}
          >
            Login
          </Link>
        </Typography>
      </Box>
    </>
  );
};

export default ForgotPassword;
