import { useEffect } from 'react';

const useDarkModeToggle = (enableDarkMode: boolean = false) => {
  useEffect(() => {
    if (enableDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }

    return () => {
      document.body.classList.remove('dark-mode');
    };
  }, [enableDarkMode]);
};

export default useDarkModeToggle;
