import { LinearProgress } from '@mui/material';
import { DataGrid, GridRowsProp, GridColDef, GridCellEditStopParams, GridCellEditStopReasons, MuiEvent, GridRenderCellParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { API_ROUTES } from 'src/constants/routes';
import { httpClient } from 'src/libs';
import { useAppSelector } from 'src/store';
import 'src/styles/components/_dashboard.scss'
import { ServicesType } from 'src/types/services';

const BookingManager = () => {
  const { user } = useAppSelector((state) => state.user);
  const [bookings, setBookings] = useState(null)
  const [loading, setLoading] = useState(true)
  
  const rows: GridRowsProp = bookings || [];
  function renderAvatar(params: GridRenderCellParams) {
    return <img className="avatar--round" src={`${params.value}`}></img>;
  }
  function renderAddress(params: GridRenderCellParams) {
    return params.value?.formatted_address
  }
  function renderBookingLink(params: GridRenderCellParams) {
    return <a href={`${params.value}`} target="_blank" rel="noreferrer">Book</a>
  }
  function renderServiceSlugList (params: GridRenderCellParams<ServicesType[]>) {
    return params.value.map(service => service.slug).join(', ')
  }

  function renderProviderDisplay (params: GridRenderCellParams<any>) {
    return params.value.display
  }

  function renderBookingPagewithAddress(params: GridRenderCellParams) {
    return <a title="View Booking" href={`c/${params.id}`} target="_blank" rel="noreferrer">{params.row.formatted_address}</a>
  }

  function filterBookingsByLocation(value){
    setLoading(true)
    const url = `${process.env.REACT_APP_API_URL}${API_ROUTES.providers_filter}?address=${value.label}`
    httpClient.get(url, {headers: {
        'Content-Type': 'application/json'
    }}).then(({data}) => {
      setBookings(data)
    })
  }

  const columns: GridColDef[] = [
  { field: 'view_booking', headerName: 'Address', width: 350,renderCell:renderBookingPagewithAddress},
  { field: 'chosen_time', headerName: 'Time', width: 220},
  { field: 'created_by_first_name', headerName: 'Booker First'},
  { field: 'created_by_last_name', headerName: 'Booker Last'},
  { field: 'created_by_email', headerName: 'Booker Email'},
  { field: 'created_by_phone', headerName: 'Booker Phone'},
  { field: 'provider', headerName: 'Provider',renderCell:renderProviderDisplay},
  { field: 'services', headerName: 'Services', renderCell:renderServiceSlugList},
  { field: 'status', headerName: 'Status'},
  { field: 'client_notes', headerName: 'Client Notes', width: 150, editable: true},
  { field: 'price', headerName: 'Quoted Price'},
  { field: 'fee', headerName: 'Travel Fee'},
  { field: 'estimated_duration', headerName: 'Duration'},
  { field: 'travel_time_to_booking', headerName: '-> Travel Time'},
  { field: 'travel_time_from_booking', headerName: '<- Travel Time'},
  { field: 'uuid', headerName: 'Booking ID'},
  { field: 'listing_price', headerName: 'Listing Price'},
  { field: 'area', headerName: 'Sqft'},
  { field: 'paid', headerName: 'Paid?'}
  ]
  const [value, setValue] = useState(null); 
  
  function fetchBookings(){
    const url = `${process.env.REACT_APP_API_URL}/bookings_list`
    httpClient.get(url, {headers: {
        'Content-Type': 'application/json'
    }}).then(({data}) => {
      setBookings(data)
      setLoading(false)
    })
  }

  function handleRefreshClicked(){
    setLoading(true)
    fetchBookings()
  }

  useEffect(() => {
    fetchBookings()
  }, [value]);

  const bookingUpdater = (payload) => {
    setLoading(true)
    const url = `${API_ROUTES.bulk_bookings_update}`
    return httpClient.post(url, {ids: [payload.uuid], booking: payload})
      .then(({ data }) => {
        if (!data.success) {
          throw new Error(data.message)
        }
      })
  }
  return (
    <div className="container-fluid">
      <div className='card'>
        <div className='card-header'>
          <div className="card-title">
            <h1>Manage Bookings</h1>
          </div>
          <div className='card-subtitle'>
           
          </div>
          <div className="grid-filters-bar">
            <button onClick={()=>handleRefreshClicked()} className="btn btn-default filter-item">
              Refresh
            </button>
          </div>
        </div>

        <div className="card-body" style={{ height: 600, width: '100%' }}>
          <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid 
                loading={ bookings === null }
                onRowClick={(params, event) => {
                  event.stopPropagation();
                }}                           
                rows={rows} 
                columns={columns} 
                getRowId={(row) => row.uuid}
                
                processRowUpdate={(newRow: GridCellEditStopParams) => {
                  bookingUpdater(newRow)
                  return newRow
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingManager;
