import { useAppDispatch, useAppSelector } from 'src/store';
import { delayTime } from 'src/components/ToastMessage';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Card, Col, Image, Row } from 'react-bootstrap';
import ServiceItem from 'src/components/Services/ServiceItem';
import useWindowSize from 'src/hooks/useWindowSize';
import { reorderServices, setServiceList } from 'src/features/services/photographer.slice';

const ServiceItemDragAndDrop = ({ serviceList, addAService, setShowDeleteServiceConfirmation, firstGalleryItems, onClickServiceItem, setSelectedService, setEnableErrors, setShowEnableService, updateServiceList }) => {
  var chunkSize = 3 // For screen size md and above
  const [width, _height] = useWindowSize();
  if (width < 768) chunkSize = 2 // For screen size sm & below
  let rows = []
  for (let i = 0; i < serviceList.length; i ? i += chunkSize : i += chunkSize - 1) {
    rows = [...rows, serviceList.slice(i, i ? i + chunkSize : i + chunkSize - 1)]
  }
  const dispatch = useAppDispatch();

  const reorder = (e) => {
    let source_row = parseInt(/\d+$/.exec(e.source.droppableId)[0])
    let destination_row = parseInt(/\d+$/.exec(e.destination.droppableId)[0])
    let source_index = ((source_row - 1) * chunkSize) + e.source.index - (source_row == 1 ? 0 : 1)
    let destination_index = ((destination_row - 1) * chunkSize) + e.destination.index - (destination_row == 1 ? 0 : 1)

    let tempServices = [...serviceList]
    const currentServices = [...serviceList]
    let element = tempServices[source_index];
    tempServices.splice(source_index, 1);
    if (source_row < destination_row)
      tempServices.splice(destination_index - 1, 0, element);
    else
      tempServices.splice(destination_index, 0, element);

    let order = []
    for (let i = 0; i < tempServices.length; i++) order.push({ id: tempServices[i].id, order: i + 1 });

    return [order, currentServices, tempServices]
  }

  const handleDragEnd = (e) => {
    if (!e.destination) { return }

    const [order, currentServices, tempServices] = reorder(e);

    dispatch(reorderServices(order))
      .unwrap().catch(() => dispatch(setServiceList(currentServices)))

    dispatch(setServiceList(tempServices))
  };

  const darkMode = useAppSelector(state=> state.theme.darkMode)

  return (
    <Row style={{ position: 'relative' }}>
      <DragDropContext onDragEnd={(results) => {
        handleDragEnd(results)
      }}>
        {rows.map((chunk, key) => (
          <Droppable droppableId={`droppable-${key + 1}`} direction="horizontal" key={key}>
            {
              (provider) => (
                <Row ref={provider.innerRef} {...provider.droppableProps}>
                  {!key && <Col className="mb-4" sm={6} md={4}>
                    <Card
                      className="service-card--empty text-center"
                      onClick={addAService}
                    >
                      <Card.Body>
                        <Image
                          className="plus-icon"
                          src={darkMode ? "/icon/plus.svg" : "/icon/plusBlack.svg"}
                        />
                        <h3 className="mb-0">New Service</h3>
                      </Card.Body>
                    </Card>
                  </Col>}
                  {chunk.map((service, index) => (
                    <Draggable
                      disableInteractiveElementBlocking key={`service-${service.id}`}
                      draggableId={`service-${service.id}`}
                      index={index}
                    >
                      {
                        (provider) => (
                          <Col sm={6} md={4} className="mb-4" ref={provider.innerRef} {...provider.draggableProps} {...provider.dragHandleProps}>
                            <ServiceItem
                              setShowDeleteServiceConfirmation={setShowDeleteServiceConfirmation}
                              firstGalleryItem={firstGalleryItems[service.id]}
                              serviceInfo={service}
                              onClick={() => onClickServiceItem(service.id)}
                              setSelectedService={setSelectedService}
                              setEnableErrors={setEnableErrors}
                              setShowEnableService={setShowEnableService}
                              updateServiceList={updateServiceList}
                            />
                          </Col>
                        )
                      }
                    </Draggable>
                  ))}
                  {provider.placeholder}
                </Row>
              )
            }
          </Droppable>
        ))}
      </DragDropContext>
    </Row>
  );
};

export default ServiceItemDragAndDrop;
