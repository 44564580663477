import { Card, FormCheck, FormGroup, Image } from 'react-bootstrap';
import { trackAnalytics } from 'src/features/analytics/analytics.slice';
import { getPhotographerServiceById, updatePhotographerService } from 'src/features/services/photographer.slice';
import { getValidationErrors, serviceValuesConversion } from 'src/libs/utils';
import { useAppDispatch } from 'src/store';
import { addServiceSchema } from 'src/utils/formSchemas';
import ServiceGalleryItem from '../ServiceDetail/ServiceGalleryItem';

const ServiceItem = ({ setShowDeleteServiceConfirmation, firstGalleryItem, updateServiceList, serviceInfo, onClick, setSelectedService, setEnableErrors, setShowEnableService }) => {
  const dispatch = useAppDispatch();
  const id = serviceInfo.id.toString()
  const onSubmit = (data) => {
    dispatch(updatePhotographerService({id, data})).unwrap().then((response) => {
      dispatch(trackAnalytics({ action_name: "service_status_changed", payload: { description: `status of service with id ${response.id} changed`, service_id: response.id, hidden: response.hidden }}))
    })
  };


  const handleEnable = () => {
    dispatch(getPhotographerServiceById({id})).unwrap()
      .then((response) => {
        const values = serviceValuesConversion(response)
        setSelectedService({...values, id: id})
        const data = {...values, hidden: false}
        const errors = getValidationErrors(addServiceSchema, data)
        if(!errors){
          onSubmit({hidden: false})
          updateServiceList(id, false)
        } else{
          setEnableErrors(errors)
          setShowEnableService(true)
        }
      })
  }

  const handleDeleteService = () => {
    setSelectedService(serviceInfo)
    setShowDeleteServiceConfirmation(true)
  }

  return (
    <Card className="service-card">
      <div style={{ overflow: 'hidden', height: '-webkit-fill-available', maxHeight: '-webkit-fill-available' }}>
      <ServiceGalleryItem
        galleryItem={firstGalleryItem}
        service={serviceInfo}
        handleServiceDetail={onClick}
      />
      </div>
      <Card.Body className=''>
        <div onClick={onClick}>
          <div className="d-flex justify-content-between">
            <Card.Title style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{serviceInfo.name}</Card.Title>
            <img className="service-icon" src={serviceInfo.icon} alt="" />
          </div>
          <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} className="fs-12 fw-medium-light lh-1 text-secondary">
            {serviceInfo.tagline}
          </p>
        </div>

        <FormGroup>
          <FormCheck.Label>{serviceInfo.hidden ? "Disabled" : "Enabled"}</FormCheck.Label>
          <div className='d-flex trash-icon-right'>
            <FormCheck
              className=""
              id="hidden"
              type="switch"
              name="hidden"
              checked={!serviceInfo.hidden}
              onChange={(e) => {
                if(e.target.checked){
                  handleEnable()
                }else{
                  onSubmit({hidden: true})
                  updateServiceList(id, true)
                }
              }}
            />
            <Image
              src="/icon/trash.svg"
              className="trash-icon"
              onClick={handleDeleteService}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </FormGroup>
      </Card.Body>
    </Card>
  );
};

export default ServiceItem;
