import React from 'react';
import { Box, Typography } from '@mui/material';
import ContactCard from '../ContactCard';
import EntityCard from '../EntityCard';
import { useAppDispatch, useAppSelector } from 'src/store';
import { getContact, selectContactById } from 'src/features/account/contacts.slice';
import theme from 'src/themes/oppy-default-theme';

const ConversationContacts = ({ conversation, sx = {} }) => {
  const dispatch = useAppDispatch();
  const initialLoad = useAppSelector(state => state.contacts.initialLoad);

  const style = {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    overflowX: 'auto',
    ...sx,
  };

  const cardStyles = {
    wrapperBox: {
      cursor: 'pointer',
      borderRadius: 1,
      width: '160px',
      border: '1px solid #e0e0e0',
      textOverflow: 'ellipsis',
      padding: 1, 
    },
    name: {
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      width: '100%',
      textOverflow: 'ellipsis',
      '&:hover': {
        color: theme.palette.primary.main
      },
      overflow: 'hidden',
    },
    detailsRow: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: 10,
      fontWeight: 'light',
      display: 'flex',
      alignItems: 'center'
    }
  };

  const getDisplayName = (contact) => {
    return contact?.first_name && contact?.last_name ? `${contact?.first_name} ${contact?.last_name}` : contact?.name || contact?.email || contact?.phone_number || contact.id;
  };

  const buildUniqueContactList = (entities) => {
    const uniqueContacts = new Set();
    const contactComponents = [];

    entities.forEach((entity) => {
      if (entity.role === "function") return;

      const contact = useAppSelector(state => selectContactById(state, entity.contact_id));
      if (!contact && entity.contact?.id && !initialLoad && !entity.contact?.loading) {
        dispatch(getContact(entity.contact.id));
      }

      if (contact && !uniqueContacts.has(contact.id)) {
        uniqueContacts.add(contact.id);
        const displayName = getDisplayName(contact);
        contactComponents.push(
          <ContactCard key={entity.id} contact={contact} name={displayName} styles={cardStyles} />
        );
      }
    });

    return contactComponents;
  };

  const buildUniqueEntityList = (entities) => {
    const entityComponents = [];

    entities.forEach((entity) => {
      if (entity.role === "function") return;
      if (!entity.contact_id && !entity.contact?.id) {
        entityComponents.push(
          <EntityCard key={entity.id} entity={entity} styles={cardStyles} />
        );
      }
    });

    return entityComponents;
  };

  const uniqueContactList = buildUniqueContactList(conversation.participating_entities);
  const uniqueEntityList = buildUniqueEntityList(conversation.participating_entities);

  return (
    <Box sx={style}>
      {uniqueContactList}
      {uniqueEntityList}
    </Box>
  );
};

export default ConversationContacts;
