import React, { useEffect, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store';
import {
  fetchFilteredContacts, selectAllFilteredContacts } from 'src/features/account/filteredContacts.slice';
import TextField from '@mui/material/TextField';
import { debounce } from '@mui/material/utils';
import { CircularProgress } from '@mui/material';

const ContactsFilterBar = () => {
  const dispatch = useAppDispatch();
  const contactsLoading = useAppSelector(state => state.filteredContacts.contactsLoading);
  const contactsUpdatedSinceFilterSet = useAppSelector(state => state.filteredContacts.contactsUpdatedSinceFilterSet);
  const contactsPresent = useAppSelector(state => state.filteredContacts.contactsPresent);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const fetch = useMemo(
    () =>
      debounce((input) => {
        dispatch(fetchFilteredContacts({ query: input }));
      }, 800),
    [dispatch]
  );

  useEffect(() => {
    if (inputValue === '') {
      return;
    }

    fetch(inputValue);
  }, [inputValue, fetch]);

  return (
    <TextField
      label="Search Contacts"
      placeholder="Search for contacts by name, phone number, email, anything..."
      variant="standard"
      fullWidth
      autoComplete="off"
      value={inputValue}
      onChange={(event) => handleInputChange(event.target.value)}
      helperText={
        `${contactsPresent ? '' : 'No contacts found.'}
        ${contactsUpdatedSinceFilterSet ? 'Contacts updated since filter set. Refresh to see new contacts.' : ''}`
      }
      InputProps={{
        endAdornment: (
          <>
            {contactsLoading ? <CircularProgress color="inherit" size={20} /> : null}
          </>
        ),
        inputProps: {
          sx: {
            // Disable LastPass popup
            '& div[data-lastpass-icon-root]': { display: 'none' },
            '& div[data-lastpass-root]': { display: 'none' },
          },
        },
      }}
    />
  );
};

export default ContactsFilterBar;
