import { createTheme } from '@mui/material/styles';
import dropdownIcon from '../assets/svg/arrow_down.svg';
import 'typeface-inter';

const interFont = "Inter, sans-serif";
const robotoFont = "Roboto, sans-serif";
const powerGroteskFont = "'Power Grotesk', sans-serif";
const powerGroteskLightFont = "'Power Grotesk Light', sans-serif";
const spaceGroteskFont = "'Space Grotesk', sans-serif";

// Updated Brandkit colors
const primaryColor = '#000000'; // Black
const secondaryColor = '#4949C8'; // Blue
const successColor = '#21D19F'; // Teal
const errorColor = '#ff3b3b'; // Red
const accentColor = '#ceff1a'; // Yellow
const infoColor = '#0288D1'; // Unchanged
const textColorPrimary = '#000000de';
const textColorSecondary = '#00000099';
const errorTextColor = '#ff3b3b';
const borderColor = '#0000001F';
const dividerColor = borderColor;
// dark background
const darkBackground = '#000000';

const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    success: {
      main: successColor,
    },
    error: {
      main: errorColor,
    },
    info: {
      main: infoColor,
    },
    accent: {
      main: accentColor,
    },
    text: {
      primary: textColorPrimary,
      secondary: textColorSecondary,
      error: errorTextColor,
    },
    border: borderColor,
    divider: dividerColor,
    backgroundDark: {
      default: darkBackground,
      paper: darkBackground,
    },
  },

  statusColors: {
    closed: successColor,
    error: '#C62828', // darker red
    pending_assistant_reply: '#FFC107', // amber
    pending_user_reply: '#0288D1', // blue, assuming
    processing_autocomplete: '#FFC107', // amber
    standby: '#9E9E9E', // grey
    needs_attention: '#F44336', // red, assuming
    loading: '#BBB5FF', // light purple, assuming
    loading_messages: '#BBB5FF', // add this line if needed
  },

  statusAnimations: {
    closed: {},
    error: {
      animation: 'pulsate 1.5s infinite',
      '@keyframes pulsate': {
        '0%': { opacity: 0.5 },
        '50%': { opacity: 1 },
        '100%': { opacity: 0.5 }
      }
    },
    needs_attention: {
      animation: 'pulsate 2s infinite',
      '@keyframes pulsate': {
        '0%': { opacity: 0.5 },
        '50%': { opacity: 1 },
        '100%': { opacity: 0.5 }
      }
    },
    pending_assistant_reply: {
      animation: 'blink 3s infinite',
      '@keyframes blink': {
        '0%': { opacity: 0.1 },
        '50%': { opacity: 1 },
        '100%': { opacity: 0.1 }
      }
    },
    pending_user_reply: {
      animation: 'fade 4s infinite',
      '@keyframes fade': {
        '0%': { opacity: 0.3 },
        '50%': { opacity: 1 },
        '100%': { opacity: 0.3 }
      }
    },
    processing_autocomplete: {
      animation: 'rotate 2s linear infinite',
      '@keyframes rotate': {
        '0%': { transform: 'rotate(0deg)' },
        '100%': { transform: 'rotate(360deg)' }
      }
    },
    standby: {
      animation: 'scale 1.5s infinite',
      '@keyframes scale': {
        '0%': { transform: 'scale(0.8)' },
        '50%': { transform: 'scale(1.2)' },
        '100%': { transform: 'scale(0.8)' }
      }
    },
    loading: {
      animation: 'pulse 1.5s infinite',
      '@keyframes pulse': {
        '0%': { opacity: 0.5 },
        '50%': { opacity: 1 },
        '100%': { opacity: 0.5 }
      }
    },
    loading_messages: {
      animation: 'pulse 1.5s infinite',
      '@keyframes pulse': {
        '0%': { opacity: 0.5 },
        '50%': { opacity: 1 },
        '100%': { opacity: 0.5 }
      }
    }
  },

  typography: {
    h1: {
      fontFamily: powerGroteskFont,
      fontWeight: 500,
    },
    h2: {
      fontFamily: powerGroteskFont,
      fontWeight: 500,
    },
    h3: {
      fontFamily: powerGroteskFont,
      fontWeight: 500,
    },
    h4: {
      fontFamily: powerGroteskFont,
      fontWeight: 500,
    },
    h5: {
      fontFamily: powerGroteskFont,
      fontWeight: 500,
    },
    h6: {
      fontFamily: powerGroteskFont,
      fontWeight: 500,
    },
    body1: {
      fontFamily: spaceGroteskFont,
    },
    body2: {
      fontFamily: spaceGroteskFont,
    },
    subtitle1: {
      fontFamily: spaceGroteskFont,
    },
    subtitle2: {
      fontFamily: spaceGroteskFont,
    },
    button: {
      fontFamily: spaceGroteskFont,
    },
    caption: {
      fontFamily: powerGroteskLightFont,
    },
    overline: {
      fontFamily: powerGroteskLightFont,
    },
  },

  components: {

    MuiCssBaseline: {
      styleOverrides: {
        '*::-webkit-scrollbar': {
          display: 'none',
          display: 'none',
        },
        '*': {
          scrollbarWidth: 'none', 
          '-ms-overflow-style': 'none',
          display: 'none',
        },
        '*::-webkit-scrollbar-thumb': {
          display: 'none',
        },
        '*::-webkit-scrollbar-track': {
          display: 'none',
        },
        '*--webkit-scrollbar': {
          display: 'none',
        },
        '@font-face': [
          {
            fontFamily: 'Power Grotesk',
            fontStyle: 'normal',
            fontWeight: 500,
            src: `local('Power Grotesk'), local('PowerGrotesk-Medium'), url(${require('src/assets/fonts/PowerGrotesk-Medium.otf')}) format('opentype')`,
          },
          {
            fontFamily: 'Power Grotesk Light',
            fontStyle: 'normal',
            fontWeight: 'normal',
            src: `local('Power Grotesk Light'), local('PowerGrotesk-Light'), url(${require('src/assets/fonts/PowerGrotesk-Light.otf')}) format('opentype')`,
          },
        ],
        body: {
          fontFamily: spaceGroteskFont,
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontFamily: robotoFont,
          fontSize: '14px',
          fontWeight: 600,
          lineHeight: 'normal',
          letterSpacing: 0,
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          marginRight: '-20px',
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: powerGroteskFont,
          fontWeight: 500,
          textTransform: 'none',
          borderRadius: '8px',
          margin: '3px',
          transition: '200ms ease-in-out',
          boxShadow: '2px 2px 0 #333333',
          gap: '10px', // Ensure gap between contents
          '&:hover': {
            transform: 'translateY(-3px)',
            boxShadow: '2px 2px 0 #333333, 4px 4px 0 rgba(51, 51, 51, 0.5)',
          },
          '&:active': {
            transform: 'translateY(0)',
          },
        },
        contained: {
          '&.MuiButton-containedPrimary': {
            backgroundColor: primaryColor,
            color: '#FFFFFF',
            boxShadow: '2px 2px 0 #333333',
            '&:hover': {
              // boxShadow: '0px 4px 8px 0 #333333, 0px 6px 20px 0 rgba(51, 51, 51, 0.5)',
              // boxShadow: 'rgba(51, 51, 51, 0.5) 0px 0px 0px 1px, rgba(51, 51, 51, 0.5) 0px 8px 0px 0px',
            },
            '&:active': {
              // boxShadow: '1px 1px 0 #333333',
            },
          },
          '&.MuiButton-containedSecondary': {
            backgroundColor: secondaryColor,
            color: '#FFFFFF',
            boxShadow: '2px 2px 0 #3a3aa0',
            '&:hover': {
              // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
            },
            '&:active': {
              // boxShadow: '1px 1px 0 #3a3aa0',
            },
          },
        },
        outlined: {
          backgroundColor: 'transparent',
          borderWidth: '2px',
          '&:hover': {
            // backgroundColor: 'rgba(0, 0, 0, 0.05)',
          },
          '&.MuiButton-outlinedPrimary': {
            color: primaryColor,
            borderColor: primaryColor,
            '&:active': {
              // boxShadow: '1px 1px 0 #333333',
            },
          },
        },
        text: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            boxShadow: '2px 2px 0 #333333, 4px 4px 0 rgba(51, 51, 51, 0.5)',
          },
          '&:active': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            boxShadow: '1px 1px 0 #333333',
          },
        },
        sizeSmall: {
          padding: '0.25rem 0.5rem',
          fontSize: '0.875rem',
        },
        sizeLarge: {
          padding: '0.75rem 1.5rem',
          fontSize: '1.25rem',
        },
        disabled: {
          opacity: 0.5,
          pointerEvents: 'none',
        },
        fullWidth: {
          width: '100%',
        },
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '24px',
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: 'rgba(0, 0, 0, 0.30)',
        }
      }
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          fontFamily: powerGroteskFont,
          borderRadius: '8px',
          transition: 'box-shadow 0.3s ease-in-out',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          },
        },
        colorPrimary: {
          color: primaryColor,
          '&:hover': {
            backgroundColor: `${primaryColor}14`,
          },
        },
        colorSecondary: {
          color: secondaryColor,
          '&:hover': {
            backgroundColor: `${secondaryColor}14`,
          },
        },
        // Add similar styles for success, error, and accent colors
        sizeSmall: {
          padding: '8px',
        },
        sizeMedium: {
          padding: '12px',
        },
        sizeLarge: {
          padding: '16px',
        },
        disabled: {
          opacity: 0.5,
        },
      },
    },
  },
});


export default theme;
