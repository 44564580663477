import React from 'react';
import { Editor, rootCtx, defaultValueCtx } from '@milkdown/core';
import { nord } from '@milkdown/theme-nord';
import { Milkdown, MilkdownProvider, useEditor } from '@milkdown/react';
import { commonmark } from '@milkdown/preset-commonmark';
import { history } from '@milkdown/plugin-history';
import { listener, listenerCtx } from '@milkdown/plugin-listener';
import { tooltipFactory } from '@milkdown/plugin-tooltip';
import { Box, Typography } from '@mui/material';

interface MilkdownEditorProps {
  defaultText: string;
  onChange: (text: string) => void;
  helperText?: string;
}

const MilkdownEditor: React.FC<MilkdownEditorProps> = ({ defaultText, onChange, helperText }) => {
  const tooltip = tooltipFactory('selection');
  useEditor((root) =>
    Editor.make()
      .config(nord)
      .config((ctx) => {
        ctx.set(rootCtx, root);
        ctx.set(defaultValueCtx, defaultText);
      })
      .use(commonmark)
      .use(history)
      .use(listener)
      .use(tooltip)
  )
    
  function handleKeyDown(e: React.KeyboardEvent) {
    if (e.key === 'Tab') {
      e.preventDefault();
      document.execCommand('insertText', false, '\t');
    }
  }

  return (
    <Box onKeyDown={handleKeyDown}>
      <Milkdown />
      {helperText && <Typography variant="caption">{helperText}</Typography>}
    </Box>
  );
};

export const MilkdownWYSIWYGEditor: React.FC<MilkdownEditorProps> = ({ defaultText, onChange }) => {
  return (
    <MilkdownProvider>
      <MilkdownEditor defaultText={defaultText} onChange={onChange} />
    </MilkdownProvider>
  );
};
