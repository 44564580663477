import React, { useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Container, TextField, Button, List, ListItem, IconButton, Box } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Save as SaveIcon } from '@mui/icons-material';
import Player from 'react-lottie-player'
import CallSummary from './CallSummary';
import LiveTranscriptionComponent from './LiveTranscriptionComponent';
import LavaLampBackground from '../ThemeElements/LavaLampBackground';

const LottieWysiwyg = () => {
  const [messages, setMessages] = useState([]);
  const [lottieJson, setLottieJson] = useState(null);
  const fileInputRef = useRef(null);
  const [transcriptionComplete, setTranscriptionComplete] = useState(false);
  const [showCallSummary, setShowCallSummary] = useState(false);
  const [restartTranscription, setRestartTranscription] = useState(false);

  const handleTranscriptionComplete = () => {
    setTranscriptionComplete(true);
    setShowCallSummary(true);
  };

  const handleCallSummaryComplete = () => {
    setShowCallSummary(false);
    setTranscriptionComplete(false);
    setRestartTranscription(prev => !prev); // Toggle to trigger restart
  };

  const extractMessages = (json) => {
    const result = [];
    const traverse = (obj) => {
      if (obj && typeof obj === 'object') {
        if (obj.ty === 5 && obj.t?.d?.k?.[0]?.s?.t) {
          const uniqueId = uuidv4();
          obj.uniqueId = uniqueId;
          result.push({ id: uniqueId, text: obj.t.d.k[0].s.t });
        }
        Object.values(obj).forEach(traverse);
      }
    };
    traverse(json);
    return result;
  };

  const updateLottieJson = (updatedMessages) => {
    const newJson = JSON.parse(JSON.stringify(lottieJson));
    const updateText = (obj) => {
      if (obj && typeof obj === 'object') {
        if (obj.ty === 5 && obj.uniqueId) {
          const message = updatedMessages.find(m => m.id === obj.uniqueId);
          if (message) {
            const lines = message.text.split('\n');
            obj.t.d.k[0].s.t = message.text;
            
            // Update text properties
            const textProp = obj.t.d.k[0].s;
            textProp.lh = textProp.lh || 48; // Line height
            textProp.sz = textProp.sz || 40; // Font size

            // Calculate new height
            const maxLineWidth = obj.w || 500; // Adjust based on your needs
            const wrappedLines = lines.flatMap(line => 
              wrapText(line, maxLineWidth, textProp.f, textProp.sz)
            );
            obj.h = wrappedLines.length * textProp.lh;

            // Update width if needed
            obj.w = Math.max(...wrappedLines.map(line => 
              measureTextWidth(line, textProp.f, textProp.sz)
            ));
          }
        }
        Object.values(obj).forEach(updateText);
      }
    };
    updateText(newJson);
    setLottieJson(newJson);
  };

  const wrapText = (text, maxWidth, fontFamily, fontSize) => {
    const words = text.split(' ');
    const lines = [];
    let currentLine = words[0];

    for (let i = 1; i < words.length; i++) {
      const word = words[i];
      const width = measureTextWidth(currentLine + ' ' + word, fontFamily, fontSize);
      if (width < maxWidth) {
        currentLine += ' ' + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }
    lines.push(currentLine);
    return lines;
  };

  const measureTextWidth = (text, fontFamily, fontSize) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = `${fontSize}px ${fontFamily}`;
    return context.measureText(text).width;
  };

  const handleUploadLottie = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      if (typeof e.target.result === 'string') {
        const json = JSON.parse(e.target.result);
        setLottieJson(json);
        setMessages(extractMessages(json));
      }
    };
    reader.readAsText(file);
  };

  const updateMessage = (id, newText) => {
    const updatedMessages = messages.map(msg => msg.id === id ? { ...msg, text: newText } : msg);
    setMessages(updatedMessages);
    updateLottieJson(updatedMessages);
  };

  const deleteMessage = (id) => {
    const updatedMessages = messages.filter(msg => msg.id !== id);
    setMessages(updatedMessages);
    updateLottieJson(updatedMessages);
  };

  return (
    <>
      <Box sx={{ height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignContent: 'center', flexDirection: 'row' }}>
        {/* <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: -1 }}>
          <LavaLampBackground />
        </Box> */}
      {(!showCallSummary) && (
        <LiveTranscriptionComponent 
          onComplete={handleTranscriptionComplete}
          restart={restartTranscription}
        />
      )}
      {showCallSummary && (
        <CallSummary 
          transcriptionComplete={transcriptionComplete} 
          onComplete={handleCallSummaryComplete}
        />
      )}
      {!showCallSummary && (
        <>
          {/* <Container sx={{ display: 'flex', height: '100vh', padding: 2 }}>
            <Container sx={{ flex: 1, paddingRight: 2 }}>
              <Button variant="contained" component="label" fullWidth>
                Upload Lottie JSON
                <input type="file" hidden onChange={handleUploadLottie} ref={fileInputRef} />
              </Button>
              <List>
                {messages.map((msg) => (
                  <ListItem key={msg.id}>
                    <TextField
                      multiline
                      fullWidth
                      value={msg.text}
                      onChange={(e) => updateMessage(msg.id, e.target.value)}
                      InputProps={{
                        style: { fontFamily: 'inherit', fontSize: 'inherit', lineHeight: 'inherit' }
                      }}
                    />
                    <IconButton onClick={() => deleteMessage(msg.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </Container>
            <Container sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f0f0f0' }}>
              {lottieJson && (
                <Player
                  play
                  loop
                  animationData={lottieJson}
                  style={{ width: '100%', height: '100%' }}
                />
              )}
            </Container>
          </Container> */}
        </>
      )}
      </Box>
    </>
  );
};

export default LottieWysiwyg;
