import { ReactNode, useEffect, useState } from 'react';
import { meta } from 'src/libs/utils';
import ConnectCalendarAlert from '../ConnectCalendarAlert';
import GlobalNav from '../NavV2';
import DocumentMeta from 'react-document-meta';
import { useAppDispatch, useAppSelector } from 'src/store';
import useAuth from 'src/hooks/useAuth';
import { trackAnalytics } from 'src/features/analytics/analytics.slice';
// import withReactContent from 'sweetalert2-react-content';
// import Swal from 'sweetalert2';
import UpgradePlanPopup from '../Nav/UpgradePlanPopup';
import { getUserSubscription } from 'src/features/auth/auth.slice';
import Box from '@mui/material/Box';
import AccountNotifications from '../Channels/AccountNotifications';
import { fetchChannels } from 'src/features/account/channels.slice';
import { unwrapResult } from '@reduxjs/toolkit';
// const subscribeTo = withReactContent(Swal)

interface Props {
  children: ReactNode;
  isBoarding?: boolean;
}

const sidebarNavWidth = 300;

const Layout = ({ children, isBoarding=false }: Props) => {
  const dispatch = useAppDispatch();
  const { logOut, isLoggedIn, authLoading } = useAuth();
  const { user } = useAppSelector((state) => state.user);
  const { subscriptionFeatures } = useAppSelector((state) => state.auth);
  const [openUpgradePlanPopup, setOpenUpgradePlanPopup] = useState(false);
  const { fullScreen } = useAppSelector(state => state.theme)

  const goToLogin = () => {
    window.location.href = '/login';
  };

  // useEffect(() => {
  //   const fetchChannelsData = async () => {
  //     try {
  //       dispatch(fetchChannels()).then((resultAction: any) => {
  //         unwrapResult(resultAction);
  //       });
  //     } catch (err) {
  //       console.error('Failed to fetch data: ', err);
  //     }
  //   };

  //   fetchChannelsData();
  // }, [dispatch]);

  const userLogOut = async () => {
    dispatch(trackAnalytics({ action_name: "user_logged_out", payload: { description: "user logged out" } }))
    logOut(goToLogin);
  };

  const upgradePlan = () => {
    if (subscriptionFeatures?.higher_tiers.length > 0) {
      setOpenUpgradePlanPopup(true)
    }
  };

  useEffect(() => {
    if (!subscriptionFeatures) {
      dispatch(getUserSubscription())
    }
  }, []);

  if (authLoading) {
    return;
  }
  return (
    <>
      {openUpgradePlanPopup && <UpgradePlanPopup
        isOpen={openUpgradePlanPopup}
        onClose={() => {
          setOpenUpgradePlanPopup(false);
        }}
        subscriptionFeatures={subscriptionFeatures}
      />}

      <DocumentMeta {...meta} />
      <main>
        <Box sx={{ display: "flex", flexDirection: { xs: 'column', sm: 'column', md:'row' } }}>

          {/* <ConnectCalendarAlert
            isBoarding={isBoarding}
          /> */}
          <Box sx={{ flexGrow: 1, width: { xs: '100%', sm: '100%', md: `calc(100% - ${sidebarNavWidth}px)` } }}>
            {children}
          </Box>
        </Box>
        <AccountNotifications />
      </main>
    </>
  );
};

export default Layout;
