import React, { useEffect, useState } from 'react';
import SplitPane from 'react-split-pane';
import Conversation from './Conversation';
import { useAppDispatch, useAppSelector } from "src/store";
import ConversationsListV2 from './ConversationsListV2';
import Box from '@mui/system/Box';
import Dialog from '@mui/material/Dialog';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { toggleOpenConversation } from 'src/features/account/conversations.slice';
import { selectAllConversations, selectConversationById } from 'src/features/account/conversations.slice';

const styles = {
  box: {
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    height: '100vh',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialog: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: { xs: '100vw', sm: '100vw', md: '750px', lg: '850px', xl: '950px' },
    '& .MuiDialog-paper': {
      width: { xs: '100vw', sm: '100vw', md: '750px', lg: '850px', xl: '950px' },
    },
  },
};

const ConversationsListSplitPane = () => {
  const dispatch = useAppDispatch();
  const conversations = useAppSelector(selectAllConversations);
  const openConversationId = useAppSelector(state => state.conversations.openConversationId);
  const conversation = useAppSelector(state => selectConversationById(state, openConversationId));
  const [paneSize, setPaneSize] = useState('100%');
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlConversationId = urlParams.get('conversation_id');
    if (urlConversationId && !openConversationId) {
      dispatch(toggleOpenConversation(urlConversationId));
    }
  }, [dispatch]);

  return (
    conversations && (
      <Box sx={styles.box}>
        <ConversationsListV2 conversationsListStyle="open" handleConversationsListToggleClick={() => { }} />
        {conversation && (
          <Dialog 
            fullScreen={isSmallScreen} 
            open={Boolean(conversation)} 
            disableScrollLock
            onClose={() => {
              dispatch(toggleOpenConversation(null));
            }}
            sx={styles.dialog}
          >
            <Conversation conversation={conversation} />
          </Dialog>
        )}
      </Box>
    )
  );
};

export default ConversationsListSplitPane;
