export const API_ROUTES = {
  registration: '/registrations',
  login: '/sessions',
  login_with_sms: 'sessions/login_with_sms',
  send_verification_code: '/verify_phone/start',
  verify_sms: '/verify_phone/check',
  forgot_password: '/password',
  reset_password: '/password',
  change_passwords: '/change_passwords',
  update_emails: '/update_emails',
  location: '/address',
  services: '/services',
  service_first_gallery_items: '/services/gallery_items/first_gallery_items',
  user: '/users',
  users_list: '/users_list',
  bulk_user_update: '/bulk_users_update',
  google_calendar: '/google_calendar',
  google_calendar_lists: '/google_calendar_lists',
  analytics: '/box',
  gallery_items: '/gallery_items',
  travel_preference: '/travel_preference',
  booking_availability: '/booking_availability',
  booking_settings: '/booking_setting',
  provider_services: '/providers/services',
  photographer_services: '/photographers/services',
  photographer_service_gallery_items: '/photographers/gallery_items',
  provider_availability: '/providers/availabilities',
  photographer_availability: '/photographers/availabilities',
  photographer: '/photographers/photographers',
  photographer_booking: '/photographers/bookings',
  provider_booking: '/providers/bookings',
  photographer_booking_settings: '/photographers/booking_settings',
  booking_cancelation: '/photographers/cancel_bookings',
  cancel_booking: '/photographers/cancel_bookings/id',
  waitlist_customers: '/waitlist_customers',
  availability_alert: '/photographers/availability_alerts',
  calculate_distances_provider: '/providers/calculate_distances',
  calculate_distances_photographer: '/photographers/calculate_distances',
  photographer_first_gallery_items: 'photographers/gallery_items/first_gallery_items',
  admin_booking_gallery_item: '/providers/gallery_items',
  connect_payment_processing: '/payments/bank_account/edit',
  finalize_invoice: '/payments/bank_account/finalize_invoice',
  finalize_invoice_mail: '/payments/finalize_invoices',
  verified_invoice: '/payments/finalize_invoices/id',
  payment_collection: '/payments/payment_processing',
  create_stripe_account: '/payments/checkout',
  invoices: '/payments/invoice',
  providers_filter: '/account/providers',
  bookings_list: '/bookings_list',
  bulk_bookings_update: '/bulk_bookings_update',
  subscription_paywall_user: 'subscription_paywalls',
  subscription_paywall_providers: 'booking_provider_paywall',
  create_subscription: '/payments/subscriptions',
  available_phone_numbers: '/available_phone_numbers',
  phone_purchase_success: '/phone_purchase_success',
  release_phone_number: '/release_phone_number',
  chat_message_thread: '/chat/public',
  chat_post_message: '/chat/public/post_message',
  private_chat_post_message: '/chat/private/post_message',
  private_channel: '/chat/private/channels',
  private_channel_page_remove: (channelId, trackingId) => `/chat/private/channels/${channelId}/pages/${trackingId}/remove_page`,
  private_channel_update_embed_key: (channelId) => `/chat/private/channels/${channelId}/update_embed_key`,
  private_channel_update_prompt: (channelId) => `/chat/private/channels/${channelId}/update_prompt`,
  private_channel_update_bcc_and_fallback_phone: (channelId) => `/chat/private/channels/${channelId}/bcc_and_fallback_phone`,
  private_channel_conversation_autocomplete: (conversationId) => `/chat/private/conversations/${conversationId}/autocomplete`,
  private_channel_conversation_autocomplete_now: (conversationId) => `/chat/private/conversations/${conversationId}/autocomplete_now`,
  private_channel_update_admin_settings: (channelId) => `/chat/private/channels/${channelId}/update_admin_settings`,
  private_channel_update_autocomplete_schedule: (channelId) => `/chat/private/channels/${channelId}/update_autocomplete_schedule`,
  private_channel_update_public_functions: (channelId) => `/chat/private/channels/${channelId}/update_public_functions`,
  private_channel_update_admin_functions: (channelId) => `/chat/private/channels/${channelId}/update_admin_functions`,
  private_conversations: '/chat/private/conversations',
  public_channel: '/chat/public/channels',
  public_conversations: '/chat/public/conversations',
  contacts: '/private/contacts',
  reorder_services: '/reorder_services',
  post_url_for_content_summary: '/post_url_for_content_summary',
  get_content_response: '/get_content_response',
  chat_reset: '/chat/reset',
  channel_url_available: '/chat/public/channel_url_availability',
  usages: '/usages',
  message: '/messages',
  channels: '/channels'
};
