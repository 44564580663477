import { API_ROUTES } from 'src/constants/routes';
import { httpClient } from 'src/libs';
import { plainHttpClient } from 'src/libs/axios';
import {
  BookingAvailabilityType,
  BookingSettingsType,
  ResBookingAvailabilityType,
  ResBookingSettingsType,
  ResPasswordType,
  ResPaymentCollectionType,
  ResponseUserType,
  ResTravelAndLocationType,
  ResUserCalendarType,
  TravelPreferencesType,
  UserCalendarReq,
  UserCalendarType,
} from 'src/types/user';

const getUser = async (): Promise<ResponseUserType> => {
  return httpClient.get(`${API_ROUTES.user}`);
};

const updateUser = async (data): Promise<ResponseUserType> => {
  return httpClient.put(`${API_ROUTES.user}`, data);
};

const updateUserPhoto = async (data): Promise<ResponseUserType> => {
  var formData = new FormData();
  formData.append('user[photo_attributes][file]', data.photo_attributes.file);

  return httpClient.put(`${API_ROUTES.user}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const deleteUserPhoto = async (): Promise<ResponseUserType> => {
  var formData = new FormData();
  formData.set('user[photo_attributes][_destroy]', 'true');

  return httpClient.put(`${API_ROUTES.user}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const getTravelPreferences = async (): Promise<ResTravelAndLocationType> => {
  return httpClient.get(`${API_ROUTES.travel_preference}`);
};

const putTravelPreferences = async (
  data: TravelPreferencesType
): Promise<ResTravelAndLocationType> => {
  return httpClient.put(`${API_ROUTES.travel_preference}`, data);
};

const getBookingAvailability =
  async (): Promise<ResBookingAvailabilityType> => {
    return httpClient.get(`${API_ROUTES.booking_availability}`);
  };

const putBookingAvailability = async (
  data: BookingAvailabilityType
): Promise<ResBookingAvailabilityType> => {
  return httpClient.put(`${API_ROUTES.booking_availability}`, {
    user_availabilty: data,
  });
};

const getBookingSettings = async (): Promise<ResBookingSettingsType> => {
  return httpClient.get(`${API_ROUTES.booking_settings}`);
};

const putBookingSettings = async (
  data: BookingAvailabilityType
): Promise<ResBookingSettingsType> => {
  return httpClient.put(`${API_ROUTES.booking_settings}`, data);
};


const getUserCalendar = async (): Promise<ResUserCalendarType> => {
  return httpClient.get(`${API_ROUTES.google_calendar_lists}`);
};

const putUserCalendar = async (
  data: UserCalendarReq
) => {
  return httpClient.put(`${API_ROUTES.google_calendar}`, data);
};

const changePassword = async ( data: ResPasswordType ) => {
  return httpClient.put(`${API_ROUTES.change_passwords}`, data);
};

const sendVerificationEmail = async () => {
  return httpClient.post(`${API_ROUTES.update_emails}`);
};

const updateEmail = async ( data: {email: string, email_token: string} ) => {
  return plainHttpClient.put(`${API_ROUTES.update_emails}`, data);
};

const connectPaymentProcessing = async () => {
  return httpClient.get(API_ROUTES.connect_payment_processing);
};

const getPaymentCollection = async (): Promise<ResPaymentCollectionType> => {
  return httpClient.get(API_ROUTES.payment_collection);
};

const updatePaymentCollection = async (data: { payment_collection_type: string }) => {
  return httpClient.put(API_ROUTES.payment_collection, data);
};

const UserService = {
  getUser,
  updateUser,
  updateUserPhoto,
  deleteUserPhoto,
  getTravelPreferences,
  putTravelPreferences,
  getBookingAvailability,
  putBookingAvailability,
  getBookingSettings,
  putBookingSettings,
  getUserCalendar,
  putUserCalendar,
  changePassword,
  sendVerificationEmail,
  updateEmail,
  connectPaymentProcessing,
  getPaymentCollection,
  updatePaymentCollection,
};

export default UserService;
