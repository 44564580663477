import React, { useEffect, useState, useRef } from 'react';
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTheme, Theme } from '@mui/material/styles';
import pulsatingBorderStyle from "src/libs/pulsatingBorderStyle";
import theme from "src/themes/oppy-default-theme";

const ConversationStatusIndicator = ({conversationStatus, variant = 'full', sx = {}}) => {
  
  const theme = useTheme();
  const [currentStatus, setCurrentStatus] = useState(conversationStatus);
  const prevStatusRef = useRef(conversationStatus);

  useEffect(() => {
    if (conversationStatus !== prevStatusRef.current) {
      setCurrentStatus(conversationStatus);
      prevStatusRef.current = conversationStatus;
    }
  }, [conversationStatus]);

  const getStatusLabel = (status) => {
    const statusLabels = {
      closed: "Closed",
      error: "Error",
      pending_assistant_reply: "Pending...",
      pending_user_reply: "Awaiting reply",
      processing_autocomplete: "Generating...",
      standby: "Standby",
      needs_attention: "Needs Attention!",
      loading: "Loading...",
      loading_messages: "Loading Messages..."
    };
    return statusLabels[status] || "Unknown Status";
  };

  const dynamicRotationStyle = () => {
    if (variant === 'circle' && ['processing_autocomplete', 'loading_messages', 'loading', 'pending_assistant_reply'].includes(currentStatus)) {
      return {
        '@keyframes rotate': {
          '0%': { transform: 'rotate(0deg)' },
          '100%': { transform: 'rotate(360deg)' }
        },
        animation: `rotate 2s linear infinite`,
      };
    }
    return {
      animation: 'none',
    };
  };

  const statusAnimation = () => {
    const statusAnimations = {
      processing_autocomplete: "rotate 2s linear infinite",
      pending_user_reply: "pulse 5s ease infinite",
      pending_assistant_reply: "pulse 1s ease infinite",
      loading_messages: "pulse 1s ease infinite",
      loading: "pulse 1s ease infinite",
      error: "flash 5s ease 2 alternate",
      needs_attention: "flash 5s ease alternate",
      closed: "none",
      standby: "none"
    };
    return statusAnimations[currentStatus] || "none";
  };

  const randomBorderRadius = () => {
    const randomRadius = () => `${Math.max(4, Math.random() * 42)}px ${Math.max(4, Math.random() * 42)}px ${Math.max(4, Math.random() * 42)}px ${Math.max(4, Math.random() * 42)}px`;
    return {
      '@keyframes borderRadiusChange': {
        '0%': { borderRadius: randomRadius() },
        '10%': { borderRadius: randomRadius() },
        '25%': { borderRadius: randomRadius() },
        '35%': { borderRadius: randomRadius() },
        '50%': { borderRadius: randomRadius() },
        '65%': { borderRadius: randomRadius() },
        '75%': { borderRadius: randomRadius() },
        '90%': { borderRadius: randomRadius() },
        '100%': { borderRadius: randomRadius() }
      },
      animation: `borderRadiusChange 30s infinite`,
    };
  };

  const styles = {
    full: {
      bgcolor: theme.statusColors[currentStatus] || 'default',
      pl: 2,
      pr: 2,
      pt: .5,
      pb: .5,
      borderRadius: '8px',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      animation: `${randomBorderRadius().animation}, ${statusAnimation()}`,
    },
    condensed: {
      bgcolor: theme.statusColors[currentStatus] || 'default',
      p: 0.5,
      borderRadius: '50%',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      animation: `${randomBorderRadius().animation}, ${statusAnimation()}`,
    },
    circle: {
      height: 35,
      width: 35,
      bgcolor: theme.statusColors[currentStatus] || 'default',
      ...randomBorderRadius(),
      animation: `${dynamicRotationStyle().animation || 'none'}, ${randomBorderRadius().animation || 'none'}, ${statusAnimation() || 'none'}`,
    },
    circleLabel: {
      bgcolor: theme.statusColors[currentStatus] || 'default',
      p: 0.5,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  };

  const renderChip = (status) => {
    const label = getStatusLabel(status);
    const selectedStyle = styles[variant];

    return (
      <Tooltip title={label}>
        <Box sx={selectedStyle}>
          {variant !== 'circle' && <Typography variant="caption">{label}</Typography>}
        </Box>
      </Tooltip>
    );
  };

  return (
    <>
      {renderChip(currentStatus)}
    </>
  );
};

export default ConversationStatusIndicator;
