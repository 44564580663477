import { CircularProgress } from '@mui/material';

const Loading = () => {
  return (
    <div className="vw-100 vh-100 d-flex justify-content-center align-items-center position-fixed z-index-master" style={{backgroundColor: '', zIndex:1090, top: 0}}>
      <CircularProgress />
    </div>
  );
};

export default Loading;
