import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Button, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssistantIcon from '../ThemeElements/AssistantIcon';

const conversation = [
  { speaker: 'Mia (Agent)', text: "Hey there! Mia from Dream Home Realty here. Who do I have the pleasure of chatting with?" },
  { speaker: 'Kai', text: "Hi Mia, it's Kai. Thanks for getting back to me." },
  { speaker: 'Mia (Agent)', text: "Great to connect, Kai! I hear you're interested in learning about the home buying process. Is that right?" },
  { speaker: 'Kai', text: "Exactly. First-time buyer here, looking to understand the whole process." },
  { speaker: 'Mia (Agent)', text: "Awesome! I'll walk you through it. Let's start with your dream home wishlist and budget. What kind of place are you looking for?" },
  { speaker: 'Kai', text: "I'm thinking a modern two-bedroom apartment, preferably with a balcony. My budget is around $300,000." },
  { speaker: 'Mia (Agent)', text: "That sounds like a great start, Kai! Have you considered which neighborhoods you're interested in?" },
  { speaker: 'Mia (Agent)', text: "Great! Have you been prequalified for a mortgage yet?" },
  { speaker: 'Kai', text: "Yes, I've already been prequalified." },
  { speaker: 'Mia (Agent)', text: "Excellent! Are you currently working with any other real estate agents?" },
  { speaker: 'Kai', text: "No, I'm not working with anyone else at the moment." },
  { speaker: 'Mia (Agent)', text: "Perfect! Let's schedule a meeting to discuss your options in more detail. How about next Tuesday at 2 PM?" },
  { speaker: 'Kai', text: "Tuesday at 2 PM works great for me." },
  { speaker: 'Mia (Agent)', text: "Wonderful! I'll send you a calendar invite. Looking forward to our meeting, Kai!" },
];

const LiveTranscriptionComponent = ({ onComplete, restart }) => {
  const [callState, setCallState] = useState('ringing');
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [displayedTexts, setDisplayedTexts] = useState([]);
  const [callDuration, setCallDuration] = useState(0);
  const [transcriptionComplete, setTranscriptionComplete] = useState(false);
  const [transcriptVisible, setTranscriptVisible] = useState(true);
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    if (restart) {
      // Reset all states when restarting
      setCallState('ringing');
      setCurrentTextIndex(0);
      setDisplayedTexts([]);
      setCallDuration(0);
      setTranscriptionComplete(false);
      setTranscriptVisible(true);
      setExpanded(true);
    }
  }, [restart]);

  useEffect(() => {
    const timer = setTimeout(() => setCallState('transcribing'), 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    let interval;
    if (callState === 'transcribing') {
      interval = setInterval(() => {
        setCallDuration(prev => prev + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [callState]);

  useEffect(() => {
    if (callState === 'transcribing' && currentTextIndex < conversation.length) {
      const textToType = conversation[currentTextIndex].text;
      let charIndex = 0;

      const typingInterval = setInterval(() => {
        if (charIndex < textToType.length) {
          setDisplayedTexts(prev => [
            ...prev.slice(0, currentTextIndex),
            { ...conversation[currentTextIndex], displayText: textToType.slice(0, charIndex + 1) },
            ...prev.slice(currentTextIndex + 1)
          ]);
          charIndex++;
        } else {
          clearInterval(typingInterval);
          if (currentTextIndex + 1 === conversation.length) {
            setTranscriptionComplete(true);
            setTranscriptVisible(false);
            onComplete(); // Call the onComplete callback when transcription is done
          } else {
            setTimeout(() => setCurrentTextIndex(prev => prev + 1), 1000);
          }
        }
      }, 10);

      return () => clearInterval(typingInterval);
    }
  }, [callState, currentTextIndex, onComplete]);

  useEffect(() => {
    if (transcriptionComplete) {
      setExpanded(false);
    }
  }, [transcriptionComplete]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} sx={{ width: '100%', maxWidth: 'lg' }}>
      <AccordionSummary onClick={() => setExpanded(!expanded)} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <AssistantIcon />
            {transcriptionComplete ? (
              <Button onClick={() => setTranscriptVisible(!transcriptVisible)}>
                Transcript Complete
              </Button>
            ) : (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      position: 'relative',
                      overflow: 'hidden',
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 500,
                        animation: 'fadeText 3s ease-in-out infinite',
                        '@keyframes fadeText': {
                          '0%': { opacity: .3 },
                          '50%': { opacity: .5 },
                          '100%': { opacity: .3 },
                        },
                      }}
                    >
                      Transcribing call...
                    </Typography>
                  </Box>
                </Box>          
            )}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {!transcriptionComplete && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AccessTimeIcon sx={{ mr: 0.5 }} />
                <Typography variant="body2">{formatTime(callDuration)}</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: 'flex', flexDirection: 'column', p: 3, bgcolor: 'background.paper', borderRadius: 2, boxShadow: 3, height: '32rem', overflow: 'hidden' }}>
          {transcriptVisible && (
            <Paper sx={{ width: '100%', overflowY: 'auto', flexGrow: 1, p: 2 }}>
              {displayedTexts.map((item, index) => (
                <Box key={index} sx={{ mb: 2 }}>
                  <Typography component="span" sx={{ fontWeight: 'bold' }}>{item.speaker}: </Typography>
                  <Typography component="span">{item.displayText}</Typography>
                </Box>
              ))}
            </Paper>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default LiveTranscriptionComponent;
