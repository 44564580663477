import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Typography, Skeleton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from "src/store";
import { selectAllChannels, selectChannelById } from 'src/features/account/channels.slice';
import { toggleChannelsList, toggleCreateChannelDialog } from 'src/features/theme/theme.slice';

const styles = {
  card: {
    position: 'relative',
    cursor: 'pointer',
    height: '150px',
    minWidth: '30px',
    pl: 2,
    pr: 2,
    pb: 2,
    pt: 1.5,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
    alignItems: 'flex-start',
    gap: '10px',
    background: 'rgb(255, 255, 255 / 3%)',
    borderRadius: '10px',
    '&:hover': { background: '#f5f5f5' },
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    p: 2,
    borderRadius: 3,
    background: 'rgb(139 145 167 / 3%);',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const ChannelsListDialog = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const channelIdUrlParam = searchParams.get("channel_id");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const channels = useAppSelector(selectAllChannels);
  const [selectedChannelId, setSelectedChannelId] = useState<string | null>(null);
  const open = useAppSelector(state => state.theme.isChannelsListOpen);
  const focusedChannel = useAppSelector(state => selectChannelById(state, selectedChannelId));
  const { channelsLoading } = useAppSelector(state => state.channels);
  const createChannelDialogOpen = useAppSelector(state => state.theme.isCreateChannelDialogOpen);

  useEffect(() => {
    if (channelIdUrlParam) {
      setSelectedChannelId(channelIdUrlParam);
      setSearchParams({ channel_id: channelIdUrlParam });
    } else if (channels.length > 0) {
      setSelectedChannelId(channels[0].id);
    }
  }, [channelIdUrlParam, channels]);

  const handleChannelClick = (channelId: string) => {
    setSearchParams({ channel_id: channelId });
    setSelectedChannelId(channelId);
    navigate(`/channels?channel_id=${channelId}`);
    dispatch(toggleChannelsList());
  };

  const handleCreateChannelDialogClick = () => {
    dispatch(toggleCreateChannelDialog());
    onClose();
  };

  const onClose = () => {
    dispatch(toggleChannelsList());
  };

  function snakeToTitle(snakeString: string) {
    if (!snakeString) {
      return '';
    }
    return snakeString.split('_').map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join(' ');
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
        Your Oppies
        <Button onClick={() => handleCreateChannelDialogClick()} color="secondary">+ New Oppy</Button>
      </DialogTitle>
      <DialogContent dividers>
        <Box>
          {channelsLoading ?
            <>
              <Typography variant='caption'>Loading Oppies...</Typography>
              <Skeleton variant="rectangular" width="100%" sx={{ background: 'rgb(139 145 167 / 3%)' }} height={65} />
              <Skeleton variant="rectangular" width="100%" sx={{ background: 'rgb(139 145 167 / 3%)' }} height={65} />
              <Skeleton variant="rectangular" width="100%" sx={{ background: 'rgb(139 145 167 / 3%)' }} height={65} />
              <Skeleton variant="rectangular" width="100%" sx={{ background: 'rgb(139 145 167 / 3%)' }} height={65} />
              <Skeleton variant="rectangular" width="100%" sx={{ background: 'rgb(139 145 167 / 3%)' }} height={65} />
            </>
            :
            (channels.length === 0 ?
              <Box sx={styles.emptyState}>
                <Typography sx={{ fontSize: '24px', fontWeight: 'bold', mb: 2 }}>
                  Make your first assistant in a few clicks!
                </Typography>
                <Button variant="outlined" color="secondary">+ New Oppy</Button>
              </Box>
              :
              channels.map((channel) => (
                <Box onClick={() => handleChannelClick(channel.id)} key={channel.embed_key} 
                  sx={{
                    ...styles.card,
                    background: focusedChannel && channel.id === focusedChannel.id ? '#f5f5f5' : '#ffffff'
                  }}>
                  <Box sx={{ maxWidth: '100px', width: '100%', height: '100%', backgroundImage: `url(${channel.avatar_url})`, backgroundSize: 'cover', backgroundPosition: 'center' }} />
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px', height: '100%', justifyContent: 'center' }}>
                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{channel.chatbot_name}</Typography>
                    <Typography sx={{ fontSize: '12px', mt: -1, color: '#555' }}>{snakeToTitle(channel.flow)}</Typography>
                    <Typography sx={{ width:'100%', maxWidth: '250px', fontSize: '12px', color: '#828282' }}>{channel.description}</Typography>
                  </Box>
                </Box>
              ))
            )
          }
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChannelsListDialog;
