import Layout from 'src/components/Layout';
import BookingManager from './BookingManager';
import UserManager from './UserManager';
import 'src/styles/components/_dashboard.scss'
import { useAppSelector } from 'src/store';
import useDarkModeToggle from 'src/components/DarkModeToggle';

const Dashboard = () => {
  useDarkModeToggle(true);
  const { user } = useAppSelector((state) => state.user);

  return (
    <Layout>
      {user.role > 9 &&
        <UserManager user={user} />
      }
      <BookingManager />
    </Layout>
  );
};

export default Dashboard;
