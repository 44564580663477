import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import { TextField, Button, Paper, Typography, Box, IconButton } from '@mui/material';
import { Download, Link, Person, Sms, Tune } from '@mui/icons-material';
import { styled } from '@mui/system';
import { selectChannelById } from 'src/features/account/channels.slice';
import { useAppSelector } from 'src/store';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: 400,
  margin: 'auto',
}));

const QRCodeWrapper = styled('div')({
  position: 'relative',
  marginTop: 16,
});

const AvatarOverlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pointerEvents: 'none',
});

const DownloadButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: 8,
  right: 8,
  minWidth: 'unset',
  padding: theme.spacing(1),
}));

const QRCodeGenerator = ({ defaultPhoneNumber, defaultEmail, defaultBaseUrl, defaultUrl, defaultInitialMessage }) => {
  const [url, setUrl] = useState(defaultUrl);
  const [urlInput, setUrlInput] = useState(defaultUrl);
  const [showDownload, setShowDownload] = useState(false);
  const [method, setMethod] = useState<string>();
  const [initialMessage, setInitialMessage] = useState<string>(defaultInitialMessage);
  const [showAdvanced, setShowAdvanced] = useState(false);

  useEffect(() => {
    if (!showAdvanced && method) {
      let newUrl = '';
      if (method === "email") {
        newUrl = `mailto:${defaultEmail}?subject=Hey ${defaultEmail}!&body=${initialMessage}`;
      } else if (method === "sms") {
        newUrl = `SMSTO:${defaultPhoneNumber}:${initialMessage}`;
      } else if (method === "website") {
        newUrl = defaultBaseUrl + `?initial_message=${initialMessage}`;
      }
      setUrlInput(newUrl);
    }
  }, [method, initialMessage, showAdvanced]);

  const handleMethodClick = (newMethod: string) => {
    setMethod(newMethod);
    setShowAdvanced(false);
  }

  const handleUrlInputChange = (e) => {
    if (!showAdvanced) {
      return;
    }
    setUrlInput(e.target.value);
  }

  const handleInitialMessageChange = (e) => {
    if (showAdvanced) {
      return;
    }
    setInitialMessage(e.target.value);
    updateUrlInput(e.target.value);
  }



  const updateUrlInput = (currentInitialMessage) => {
    if (showAdvanced) {
      return;
    }
    if (method === "email") {
      setUrlInput(`mailto:${defaultEmail}?subject=Hey ${defaultEmail}!&body=${currentInitialMessage}`);
    } else if (method === "sms") {
      setUrlInput(`SMSTO:${defaultPhoneNumber}:${currentInitialMessage}`);
    } else if (method === "website") {
      setUrlInput(defaultBaseUrl + `?initial_message=${currentInitialMessage}`);
    }
  }

  const handleDownload = () => {
    const canvas = document.getElementById('qr-code') as HTMLCanvasElement;
    const pngUrl = canvas?.toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'qr-code.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <StyledPaper elevation={3}>
      <QRCodeWrapper
        onMouseEnter={() => setShowDownload(true)}
        onMouseLeave={() => setShowDownload(false)}
      >
        <QRCode
          id="qr-code"
          value={urlInput}
          size={256}
          level="H"
          includeMargin={true}
        />
        <Box component="image" />
        {showDownload && (
          <DownloadButton
            variant="contained"
            color="primary"
            onClick={handleDownload}
            aria-label="Download QR Code"
          >
            <Download />
          </DownloadButton>
        )}
      </QRCodeWrapper>
      <Box
        sx={{
          display: 'flex',
          overflowX: 'auto',
          maxWidth: '100%',
          gap: '8px',
          padding: '8px',
          marginBottom: '5px',
          paddingLeft: '23px',
          scrollbarWidth: 'none', // for Firefox
          '&::-webkit-scrollbar': {
            display: 'none', // for Chrome and Safari
          },
        }}
      >

        {defaultPhoneNumber && <Button
          startIcon={<Sms />}
          onClick={() => handleMethodClick("sms")}
          disabled={!defaultPhoneNumber}
          variant='outlined'
          color={method === 'sms' ? 'primary' : 'secondary'}
        >
          SMS
        </Button>}
        {defaultEmail && <Button
          startIcon={<Person />}
          onClick={() => handleMethodClick("email")}
          disabled={!defaultEmail}
          variant='outlined'
          color={method === 'email' ? 'primary' : 'secondary'}
        >
          Email
        </Button>}
        {defaultBaseUrl &&
          <Button
            startIcon={<Link />}
            onClick={() => handleMethodClick("website")}
            hidden={!defaultBaseUrl}
            variant='outlined'
            color={method === 'website' ? 'primary' : 'secondary'}
          >
            Website
          </Button>
        }
        <IconButton
          onClick={() => setShowAdvanced(true)}
          color="secondary"
        >
          <Tune />
        </IconButton>
        
      </Box>
      <TextField
        fullWidth
        variant="outlined"
        value={initialMessage}
        onChange={handleInitialMessageChange}
        placeholder="Prefilled message"
        helperText="This default text will populate the message when someone scans the QR code (Keep in mind, they can change it before sending)"
        margin="normal"
        hidden={showAdvanced}
      />
      {/* Advanced - keep it rendered but hide it if the user doesn't want to see it which is most cases*/}
      <TextField
        fullWidth
        variant="outlined"
        value={urlInput}
        onChange={handleUrlInputChange}
        placeholder="Enter URL"
        margin="normal"
        hidden={!showAdvanced}
      />
    </StyledPaper>
  );
};

export default QRCodeGenerator;
