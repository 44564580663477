// BEGIN frontend/src/features/account/faq.slice.ts
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/store';

interface FAQ {
  id: string;
  question: string;
  answer: string;
  createdAt: string;
  updatedAt: string;
}

const faqsAdapter = createEntityAdapter<FAQ>({
  selectId: (faq) => faq.id,
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt),
});

const initialState = faqsAdapter.getInitialState();

const faqSlice = createSlice({
  name: 'faqs',
  initialState,
  reducers: {
    faqAdded: faqsAdapter.addOne,
    faqUpdated: faqsAdapter.updateOne,
    faqRemoved: faqsAdapter.removeOne,
  },
});

export const {
  selectById: selectFaqById,
  selectIds: selectFaqIds,
  selectEntities: selectFaqEntities,
  selectAll: selectAllFaqs,
  selectTotal: selectTotalFaqs
} = faqsAdapter.getSelectors<RootState>((state) => state.faqs);

export const { faqAdded, faqUpdated, faqRemoved } = faqSlice.actions;

export default faqSlice.reducer;
// END frontend/src/features/account/faq.slice.ts
export {}
