import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, Button, TextField, Grid, CircularProgress, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Formik, Form, Field } from 'formik';
import { updateContact, updateContactState, selectContactById } from 'src/features/account/contacts.slice';
import { useAppDispatch, useAppSelector } from 'src/store';

interface ContactDialogProps {
  contactId: string;
  open: boolean;
  onClose: () => void;
  handleContactUpdate?: (updatedContact: any) => void;
}

const ContactDialog: React.FC<ContactDialogProps> = ({ contactId, open, onClose, handleContactUpdate }) => {
  const dispatch = useAppDispatch();
  const contact = useAppSelector(state => selectContactById(state, contactId));
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const onSubmit = (values) => {
    setIsSubmitting(true);
    
    // Create a copy of values to avoid mutating the original object
    const sanitizedValues = { ...values };

    // Check if last_contacted is a valid date
    if (sanitizedValues.last_contacted) {
      if (!(sanitizedValues.last_contacted instanceof Date) || isNaN(sanitizedValues.last_contacted.getTime())) {
        // If it's not a valid Date object, set it to null or remove it
        delete sanitizedValues.last_contacted;
        // Alternatively, you could set it to the current date:
        // sanitizedValues.last_contacted = new Date();
      } else {
        // If it is a valid Date object, convert it to ISO string
        sanitizedValues.last_contacted = sanitizedValues.last_contacted.toISOString();
      }
    } else {
      // If last_contacted is null or undefined, remove it from the object
      delete sanitizedValues.last_contacted;
    }

    dispatch(updateContact(sanitizedValues))
      .unwrap()
      .then((updatedContact) => {
        dispatch(updateContactState(updatedContact));
        if (handleContactUpdate) {
          handleContactUpdate(updatedContact);
        }
        setIsSubmitting(false);
        handleClose();
      })
      .catch(error => {
        setIsSubmitting(false);
        console.error('Failed to update contact:', error);
      });
  };

  useEffect(() => {
    // This effect will run whenever the contact in the Redux store changes
    if (contact) {
      // You can update the form values here if needed
      // For example, if you're using Formik's setValues:
      // formikRef.current.setValues(contact);
    }
  }, [contact]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Edit Contact</DialogTitle>
      <DialogContent>
        {contact && (
          <Formik
            initialValues={{
              ...contact,
              last_contacted: contact.last_contacted ? new Date(contact.last_contacted) : null,
            }}
            onSubmit={onSubmit}
            enableReinitialize // This will reinitialize the form when the contact prop changes
          >
            {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="first_name"
                      label="First Name"
                      value={values.first_name}
                      onChange={handleChange}
                      error={touched.first_name && Boolean(errors.first_name)}
                      helperText={touched.first_name && errors.first_name}
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="last_name"
                      label="Last Name"
                      value={values.last_name}
                      onChange={handleChange}
                      error={touched.last_name && Boolean(errors.last_name)}
                      helperText={touched.last_name && errors.last_name}
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="email"
                      label="Email"
                      value={values.email}
                      onChange={handleChange}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="phone_number"
                      label="Phone Number"
                      value={values.phone_number}
                      onChange={handleChange}
                      error={touched.phone_number && Boolean(errors.phone_number)}
                      helperText={touched.phone_number && errors.phone_number}
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth disabled={isSubmitting}>
                      <InputLabel id="preferred-contact-method-label">Preferred Contact Method</InputLabel>
                      <Select
                        labelId="preferred-contact-method-label"
                        name="preferred_contact_method"
                        value={values.preferred_contact_method}
                        onChange={handleChange}
                        label="Preferred Contact Method"
                      >
                        <MenuItem value="phone">Phone</MenuItem>
                        <MenuItem value="email">Email</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        label="Last Contacted"
                        value={values.last_contacted}
                        onChange={(newValue) => {
                          setFieldValue('last_contacted', newValue);
                        }}
                        disabled={isSubmitting}
                        slotProps={{ textField: { fullWidth: true } }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="company_name"
                      label="Company Name"
                      value={values.company_name}
                      onChange={handleChange}
                      error={touched.company_name && Boolean(errors.company_name)}
                      helperText={touched.company_name && errors.company_name}
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="status"
                      label="Status"
                      value={values.status}
                      onChange={handleChange}
                      error={touched.status && Boolean(errors.status)}
                      helperText={touched.status && errors.status}
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="shared_notes"
                      label="Shared Notes"
                      placeholder="Enter shared notes. These notes will be shared with: all users and Oppies who have access to this contact."
                      value={values.shared_notes}
                      onChange={handleChange}
                      error={touched.shared_notes && Boolean(errors.shared_notes)}
                      fullWidth
                      multiline
                      rows={4}
                      disabled={isSubmitting}
                      helperText="Great for non-sensitive contact info to reference or use to help conversations. These notes will be accessible to: admins, Oppies who have access to this contact, and the contact themselves."
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="admin_notes"
                      label="Admin Notes"
                      placeholder="Only your eyes and those of your admin team will see these notes. These notes will not be shared with Oppies who have access to this contact unless you explicitly choose to do so."
                      value={values.admin_notes}
                      onChange={handleChange}
                      error={touched.admin_notes && Boolean(errors.admin_notes)}
                      helperText="Only your eyes and those of your admin team will see these notes. These notes will not be shared with Oppies who have access to this contact unless you explicitly choose to do so."
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="address"
                      label="Address"
                      value={values.address}
                      onChange={handleChange}
                      error={touched.address && Boolean(errors.address)}
                      helperText={touched.address && errors.address}
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="company_industry"
                      label="Industry"
                      value={values.company_industry}
                      onChange={handleChange}
                      error={touched.company_industry && Boolean(errors.company_industry)}
                      helperText={touched.company_industry && errors.company_industry}
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="company_website"
                      label="Company Website"
                      value={values.company_website}
                      onChange={handleChange}
                      error={touched.company_website && Boolean(errors.company_website)}
                      helperText={touched.company_website && errors.company_website}
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="company_address"
                      label="Company Address"
                      value={values.company_address}
                      onChange={handleChange}
                      error={touched.company_address && Boolean(errors.company_address)}
                      helperText={touched.company_address && errors.company_address}
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="home_address"
                      label="Home Address"
                      value={values.home_address}
                      onChange={handleChange}
                      error={touched.home_address && Boolean(errors.home_address)}
                      helperText={touched.home_address && errors.home_address}
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="tags"
                      label="Tags"
                      value={values.tags}
                      onChange={handleChange}
                      error={touched.tags && Boolean(errors.tags)}
                      helperText={touched.tags && errors.tags}
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="timezone"
                      label="Timezone"
                      value={values.timezone}
                      onChange={handleChange}
                      error={touched.timezone && Boolean(errors.timezone)}
                      helperText={touched.timezone && errors.timezone}
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={isSubmitting}
                      sx={{ mt: 3 }}
                    >
                      {isSubmitting ? (
                        <>
                          <CircularProgress size={24} sx={{ mr: 1 }} />
                          Updating...
                        </>
                      ) : (
                        'Update Contact'
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ContactDialog;
