import { API_ROUTES } from 'src/constants/routes';
import { httpClient } from 'src/libs';

const setGoogleCalendarToken = async (data: { authorization_code: string, redirect_uri?: string }) => {
  return httpClient.post(`${API_ROUTES.google_calendar}`, {
    ...data,
  });
};

const GoogleService = {
  setGoogleCalendarToken,
};

export default GoogleService;
