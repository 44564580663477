import { useContext, useEffect, useState } from 'react';
import { useAppSelector } from 'src/store';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { selectAllConversations } from 'src/features/account/conversations.slice';
import { selectAllContacts, updateContactState } from 'src/features/account/contacts.slice';
import useAuth from 'src/hooks/useAuth';
import { PusherContext } from 'src/services/auth.service'; 
import { useHandlePusherEvents } from 'src/hooks/useHandlePusherEvents';

const AccountNotifications = () => {
  const { isLoggedIn, authLoading } = useAuth();
  const { user } = useAppSelector(state => state.user);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const pusher = useContext(PusherContext);
  const conversations = useAppSelector(selectAllConversations);
  const { loading: loadingConversations, initialLoad: initialLoadConversations } = useAppSelector(state => state.conversations);
  const { contactsLoading, initialLoad: initialLoadContacts } = useAppSelector(state => state.contacts);
  const { channelsLoading, initialLoad: initialLoadChannels, channels } = useAppSelector(state => state.channels);
  const [latestConversationPusherEvent, setLatestConversationPusherEvent] = useState(null);
  const [latestContactPusherEvent, setLatestContactPusherEvent] = useState(null);
  const [latestChannelPusherEvent, setLatestChannelPusherEvent] = useState(null);
  const [pusherSubscriptionSucceeded, setPusherSubscriptionSucceeded] = useState(false);
  const contacts = useAppSelector(selectAllContacts);
  const handlePusherEvent = useHandlePusherEvents();

  useEffect(() => {
    if (latestConversationPusherEvent) {
      console.log('latestConversationPusherEvent', latestConversationPusherEvent);
      const { eventName, data } = latestConversationPusherEvent;
      handlePusherEvent({ eventName, data, collection: conversations });
      setLatestConversationPusherEvent(null);
    }
  }, [latestConversationPusherEvent]);

  useEffect(() => {
    if (latestContactPusherEvent) {
      console.log('latestContactPusherEvent', latestContactPusherEvent);
      const { eventName, data } = latestContactPusherEvent;
      handlePusherEvent({ eventName, data, collection: contacts });
      setLatestContactPusherEvent(null);
    }
  }, [latestContactPusherEvent]);

  useEffect(() => {
    if (latestChannelPusherEvent) {
      console.log('latestChannelPusherEvent', latestChannelPusherEvent);
      const { eventName, data } = latestChannelPusherEvent;
      handlePusherEvent({ eventName, data, collection: channels });
      setLatestChannelPusherEvent(null);
    }
  }, [latestChannelPusherEvent]);

  // useEffect(() => {
  //   pusher.signin()
  //   // here we need to actually make sure the subscription succeeded by the callback for Pusher  pusher:subscription_succeeded
  //   setPusherSubscriptionSucceeded(true);
  //   pusher.bind('pusher:subscription_succeeded', () => {
  //     console.log('pusher:subscription_succeeded! setting state');
  //     setPusherSubscriptionSucceeded(true);
  //   });
  // }, [])

  useEffect(() => {
    // if (!pusherSubscriptionSucceeded) {
    //   // trigger popup alert
    //   enqueueSnackbar('Pusher subscription failed!', { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });
    //   pusher.signin()
    //   pusher.bind('pusher:subscription_succeeded', () => {
    //     console.log('pusher:subscription_succeeded! setting state');
    //     setPusherSubscriptionSucceeded(true);
    //     enqueueSnackbar('Pusher subscription succeeded!', { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });
    //   });
    // }
    if (isLoggedIn && user && !pusherSubscriptionSucceeded) {
      pusher.signin()
      // here we need to actually make sure the subscription succeeded by the callback for Pusher  pusher:subscription_succeeded
      setPusherSubscriptionSucceeded(true);
      pusher.bind('pusher:subscription_succeeded', () => {
        console.log('pusher:subscription_succeeded! setting state');
        setPusherSubscriptionSucceeded(true);
        enqueueSnackbar('Live connection succeeded! 1', { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });
      });
    }
    if (isLoggedIn && user && pusherSubscriptionSucceeded) {
      // trigger popup alert
      const channel = pusher.subscribe('presence-account-' + user.account.uuid);

      const bindEvents = () => {
        ['channel_created', 'channel_updated', 'channel_deleted'].forEach(eventName => {
          channel.bind(eventName, (data) => {
            console.log(`account_notifications ${eventName}`, data);
            setLatestChannelPusherEvent({ eventName, data });
          });
        });

        ['conversation_created', 'conversation_updated', 'conversation_deleted'].forEach(eventName => {
          channel.bind(eventName, (data) => {
            console.log(`account_notifications conversation updated ${eventName}`, data);
            setLatestConversationPusherEvent({ eventName, data });
          });
        });
        
        ['message_created', 'message_updated', 'message_deleted'].forEach(eventName => {
          channel.bind(eventName, (data) => {
            console.log(`message notification ${eventName}`, data);
            setLatestConversationPusherEvent({ eventName, data });
          });
        });
        ['contact_created', 'contact_updated', 'contact_deleted'].forEach(eventName => {
          channel.bind(eventName, (data) => {
            console.log('contact_created', data);
            setLatestContactPusherEvent({ eventName, data });
          });
        });
        ['entity_created', 'entity_updated', 'entity_deleted'].forEach(eventName => {
          channel.bind(eventName, (data) => {
            console.log('entity_created', data);
            setLatestChannelPusherEvent({ eventName, data });
          });
        });
        ['faq_created', 'faq_updated', 'faq_deleted'].forEach(eventName => {
          channel.bind(eventName, (data) => {
            console.log('faq updated', data);
            setLatestChannelPusherEvent({ eventName, data });
          });
        });
      };

      bindEvents();

      return () => {
        channel.unbind_all(); // Clean up: unbind all events when the component unmounts or data changes
        channel.unsubscribe();
      };
    }
  }, [isLoggedIn, user, pusherSubscriptionSucceeded, authLoading]);

  return null;
}

export default AccountNotifications;
