import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatePublicFunctions, updateAdminFunctions, selectChannelById } from '../../features/account/channels.slice';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, Checkbox, FormControlLabel, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Typography, Link, Box, Dialog as MuiDialog, Snackbar, Tooltip, Icon
} from '@mui/material';
import { RootState } from '../../store';
import { enqueueSnackbar } from 'notistack';
import { AppDispatch } from '../../store';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ContactsIcon from '@mui/icons-material/Contacts';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventIcon from '@mui/icons-material/Event';
import ImageIcon from '@mui/icons-material/Image';
import MessageIcon from '@mui/icons-material/Message';
import AlarmIcon from '@mui/icons-material/Alarm';
import FlagIcon from '@mui/icons-material/Flag';
import PersonIcon from '@mui/icons-material/Person';
import LanguageIcon from '@mui/icons-material/Language';
import EditIcon from '@mui/icons-material/Edit';
import ChatIcon from '@mui/icons-material/Chat';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import CalculateIcon from '@mui/icons-material/Calculate';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import BlockIcon from '@mui/icons-material/Block';
import ForumIcon from '@mui/icons-material/Forum';
import ListIcon from '@mui/icons-material/List';
import MemoryIcon from '@mui/icons-material/Memory';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SendIcon from '@mui/icons-material/Send';

const functions = [
  {
    name: "get_available_timeslots_function",
    description: "Retrieves available time slots for booking appointments. It considers the provider's schedule, specified date range, and desired meeting duration. Useful for scheduling meetings or consultations.",
  },
  {
    name: "create_and_confirm_provider_booking_function",
    description: "Creates and confirms an appointment based on selected date, time, and duration. It handles the entire booking process, including generating a professional meeting agenda and managing double-booking scenarios.",
  },
  {
    name: "analyze_image_function",
    description: "Utilizes AI vision services to analyze images in great detail. It can describe image contents, detect objects, recognize text, and provide comprehensive descriptions suitable for visually impaired users.",
  },
  {
    name: "prompt_to_self_and_admins_function",
    description: "Creates hidden messages visible only to the AI and admins. Useful for internal notes, action plans, or conversation summaries that shouldn't be shared with the user.",
  },
  {
    name: "create_future_function_message_function",
    description: "Schedules a function message to be executed at a future time. Ideal for setting reminders, follow-ups, or delayed actions within conversations.",
  },
  {
    name: "change_conversation_status_function",
    description: "Modifies the status of a conversation. It can mark conversations as needing attention, on standby, or in error state, and notifies admins when necessary.",
  },
  {
    name: "update_contact_user_details_in_memory_function",
    description: "Updates user information stored in the conversation memory. It handles various user attributes and ensures data consistency across the system.",
  },
  {
    name: "read_and_summarize_webpage_function",
    description: "Fetches content from a given URL, processes it, and provides a summary. Useful for quickly extracting key information from web pages without manual browsing.",
  },
  {
    name: "update_contact_function",
    description: "Modifies contact information in the system. It can update various fields like name, email, phone number, and custom metadata, ensuring contact records are up-to-date.",
  },
  {
    name: "create_new_conversation_with_contact_function",
    description: "Initiates a new conversation with a contact using their preferred communication method (SMS/email). It selects the most suitable AI assistant and sets up the conversation context.",
  },
  {
    name: "create_checklist_function",
    description: "Generates a structured checklist with up to 10 items, including a title, summary, and completion actions. Useful for managing multi-step processes or task lists within conversations.",
  },
  {
    name: "update_checklist_object_and_or_checklist_items_function",
    description: "Modifies existing checklists or individual checklist items. It allows for updating item status, content, or overall checklist properties to reflect progress or changes in tasks.",
  },
  {
    name: "delete_checklist_function",
    description: "Removes a checklist from the conversation memory. Use this to clean up completed or obsolete checklists from the conversation context.",
  },
  {
    name: "extract_text_from_image_function",
    description: "Uses optical character recognition (OCR) to extract text content from images. Ideal for processing documents, business cards, or any text-containing images shared in conversations.",
  },
  {
    name: "calculate_equation_function",
    description: "Evaluates mathematical equations provided as text. It supports various operations and can handle equations with variables, making it useful for quick calculations within conversations.",
  },
  {
    name: "text_to_speech_function",
    description: "Converts text input into spoken audio. This function can generate voice responses, making conversations more accessible or providing audio content when needed.",
  },
  {
    name: "get_event_function",
    description: "Retrieves detailed information about a specific calendar event. It provides event details such as time, location, attendees, and description based on the event ID.",
  },
  {
    name: "update_event_function",
    description: "Modifies existing calendar events. It can update various event properties including time, location, attendees, and description, ensuring calendar information stays current.",
  },
  {
    name: "destroy_event_function",
    description: "Deletes a specific calendar event. Use this to remove cancelled or obsolete events from the calendar system.",
  },
  {
    name: "do_nothing_function",
    description: "A null operation that doesn't perform any action. Useful in scenarios where a conversation doesn't require a response or when waiting for scheduled functions to run.",
  },
  {
    name: "fetch_calendar_events_function",
    description: "Retrieves a list of calendar events based on specified criteria such as date range, search query, or specific identifiers. Useful for viewing schedules or planning.",
  },
  {
    name: "get_filtered_contacts_function",
    description: "Searches and retrieves contacts based on various filter criteria. It can search by name, email, phone number, tags, and other attributes, facilitating targeted contact management.",
  },
  {
    name: "find_or_create_contact_function",
    description: "Locates an existing contact or creates a new one if not found. It ensures contact records are created or updated with the latest information provided.",
  },
  {
    name: "get_conversation_with_messages_function",
    description: "Get a conversation with messages. This function retrieves a specific conversation along with its messages, allowing for message limit specification and defining next actions.",
  },
  {
    name: "fetch_conversations_list_function",
    description: "List conversations and their messages matching given criteria. Examples:\n\n1. Recent customer inquiries:\n   - status: 'pending_user_reply'\n   - limit: 5\n   - conversation_last_updated_before: (24 hours ago)\n\n2. Follow-up on sales leads:\n   - query_string: 'interested in product demo'\n   - status: 'pending_assistant_reply'\n   - conversation_latest_delivery_at_before: (1 week ago)\n\n3. Urgent support issues:\n   - status: 'needs_attention'\n   - subject_contains: 'urgent'\n\n4. Conversations with a specific contact:\n   - contact_id: (specific contact's ID)\n\n5. Find conversations mentioning a product:\n   - memory_contains: { key: 'mentioned_product', value: 'ProductX' }\n\n6. Analyze team performance:\n   - status: 'closed'\n   - conversation_latest_message_created_at_before: (end of last month)\n   - limit: 100",
  },
  {
    name: "add_key_value_to_conversation_memory_function",
    description: "Add a key value pair to the conversation memory. This function allows storing additional information in the conversation context which may come in handy for the AI to make decisions. You can also filter conversations based on this information.",
  },
  {
    name: "remove_key_from_conversation_memory_function",
    description: "Remove a key from the conversation memory. This function helps in managing and cleaning up the conversation context.",
  },
  {
    name: "post_message_to_conversation_function",
    description: "Post a message to a different conversation. This function enables cross-conversation communication and follow-ups.",
  },
];

const restrictedFunctions = [
  "fetch_calendar_events_function",
  "get_filtered_contacts_function",
  "find_or_create_contact_function"
];

const functionIcons: { [key: string]: React.ReactElement } = {
  get_available_timeslots_function: <AccessTimeIcon />,
  create_and_confirm_provider_booking_function: <EventIcon />,
  analyze_image_function: <ImageIcon />,
  prompt_to_self_and_admins_function: <MessageIcon />,
  create_future_function_message_function: <AlarmIcon />,
  change_conversation_status_function: <FlagIcon />,
  update_contact_user_details_in_memory_function: <PersonIcon />,
  read_and_summarize_webpage_function: <LanguageIcon />,
  update_contact_function: <EditIcon />,
  create_new_conversation_with_contact_function: <ChatIcon />,
  create_checklist_function: <ListAltIcon />,
  update_checklist_object_and_or_checklist_items_function: <ListAltIcon />,
  delete_checklist_function: <DeleteIcon />,
  extract_text_from_image_function: <TextFieldsIcon />,
  calculate_equation_function: <CalculateIcon />,
  text_to_speech_function: <RecordVoiceOverIcon />,
  get_event_function: <EventIcon />,
  update_event_function: <EditIcon />,
  destroy_event_function: <DeleteIcon />,
  do_nothing_function: <BlockIcon />,
  fetch_calendar_events_function: <CalendarTodayIcon />,
  get_filtered_contacts_function: <ContactsIcon />,
  find_or_create_contact_function: <PersonAddIcon />,
  get_conversation_with_messages_function: <ForumIcon />,
  fetch_conversations_list_function: <ListIcon />,
  add_key_value_to_conversation_memory_function: <MemoryIcon />,
  remove_key_from_conversation_memory_function: <DeleteOutlineIcon />,
  post_message_to_conversation_function: <SendIcon />,
};

interface FunctionPermissionDialogProps {
  open: boolean;
  onClose: () => void;
  channelId: string;
}

interface UpdateResult {
  success: boolean;
  error?: string;
}

const FunctionPermissionDialog: React.FC<FunctionPermissionDialogProps> = ({
  open, onClose, channelId
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const channel = useSelector((state: RootState) => selectChannelById(state, channelId));
  const [publicFunctions, setPublicFunctions] = useState<string[]>([]);
  const [adminFunctions, setAdminFunctions] = useState<string[]>([]);
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});
  const descriptionRefs = useRef<{ [key: string]: HTMLParagraphElement | null }>({});
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [changedAdminFunctions, setChangedAdminFunctions] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (channel) {
      setPublicFunctions(channel.public_functions || []);
      setAdminFunctions(channel.admin_functions || []);
    }
  }, [channel]);

  const handleToggle = (funcName: string, isPublic: boolean) => {
    if (isPublic && restrictedFunctions.includes(funcName)) {
      enqueueSnackbar('This function cannot be set as public.', { variant: 'warning' });
      return;
    }
    const updateFunctions = isPublic ? setPublicFunctions : setAdminFunctions;
    updateFunctions(prev =>
      prev.includes(funcName) ? prev.filter(f => f !== funcName) : [...prev, funcName]
    );
  };

  const handleToggleAll = (isPublic: boolean) => {
    const updateFunctions = isPublic ? setPublicFunctions : setAdminFunctions;
    const currentFunctions = isPublic ? publicFunctions : adminFunctions;
    updateFunctions(
      currentFunctions.length === functions.length ? 
      [] : 
      functions.map(f => f.name).filter(name => !isPublic || !restrictedFunctions.includes(name))
    );
  };

  const handleSubmit = () => {
    const originalAdminFunctions = channel?.admin_functions || [];
    const addedFunctions = adminFunctions.filter(f => !originalAdminFunctions.includes(f));
    const removedFunctions = originalAdminFunctions.filter(f => !adminFunctions.includes(f));
    setChangedAdminFunctions([...addedFunctions, ...removedFunctions]);
    
    if (addedFunctions.length > 0 || removedFunctions.length > 0) {
      setConfirmDialogOpen(true);
    } else {
      handleSave();
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const initialPublicFunctions = channel?.public_functions || [];
      const initialAdminFunctions = channel?.admin_functions || [];

      const publicFunctionsChanged = JSON.stringify(publicFunctions) !== JSON.stringify(initialPublicFunctions);
      const adminFunctionsChanged = JSON.stringify(adminFunctions) !== JSON.stringify(initialAdminFunctions);

      let publicResult: UpdateResult = { success: true };
      let adminResult: UpdateResult = { success: true };

      if (publicFunctionsChanged) {
        publicResult = await dispatch(updatePublicFunctions({ channelId, publicFunctions })).unwrap();
      }

      if (adminFunctionsChanged) {
        adminResult = await dispatch(updateAdminFunctions({ channelId, adminFunctions })).unwrap();
      }
      
      if (publicResult.success && adminResult.success) {
        enqueueSnackbar('Functions updated successfully!', { variant: 'success' });
        onClose();
      } else {
        throw new Error(publicResult.error || adminResult.error || 'Unknown error occurred');
      }
    } catch (error) {
      if (error instanceof Error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      } else {
        enqueueSnackbar('An error occurred while updating functions', { variant: 'error' });
      }
    } finally {
      setIsSaving(false);
    }
  };

  const toggleRowExpansion = (funcName: string) => {
    setExpandedRows(prev => ({ ...prev, [funcName]: !prev[funcName] }));
  };

  const humanizeTitle = (name: string) => {
    return name.replace(/_function$/, '')
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const isDescriptionTruncated = (funcName: string) => {
    const ref = descriptionRefs.current[funcName];
    return ref ? ref.scrollHeight > ref.clientHeight : false;
  };

  const ConfirmDialog = () => (
    <MuiDialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
      <DialogTitle>Confirm Admin Function Changes</DialogTitle>
      <DialogContent>
        <Typography>The following admin functions will be changed:</Typography>
        <ul>
          {changedAdminFunctions.map(func => (
            <li key={func}>
              {func} - {adminFunctions.includes(func) ? 'Added' : 'Removed'}
            </li>
          ))}
        </ul>
        <Typography>Are you sure you want to proceed?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
        <Button onClick={() => {
          setConfirmDialogOpen(false);
          handleSave();
        }} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </MuiDialog>
  );

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>Function Permissions</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Function</TableCell>
                  <TableCell align="center">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={publicFunctions.length === functions.length}
                          indeterminate={publicFunctions.length > 0 && publicFunctions.length < functions.length}
                          onChange={() => handleToggleAll(true)}
                        />
                      }
                      label="Public"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={adminFunctions.length === functions.length}
                          indeterminate={adminFunctions.length > 0 && adminFunctions.length < functions.length}
                          onChange={() => handleToggleAll(false)}
                        />
                      }
                      label="Admin"
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {functions.map((func) => (
                  <React.Fragment key={func.name}>
                    <TableRow>
                      <TableCell>
                        <Box display="flex" alignItems="flex-start">
                          <Icon sx={{ mr: 1, mt: 0.5, minWidth: '24px' }}>{functionIcons[func.name]}</Icon>
                          <Box>
                            <Typography variant="subtitle1">{humanizeTitle(func.name)}</Typography>
                            <Typography variant="caption" color="textSecondary">{func.name}</Typography>
                            <Typography
                              ref={(el) => descriptionRefs.current[func.name] = el}
                              variant="body2"
                              component="p"
                              sx={{
                                display: '-webkit-box',
                                WebkitLineClamp: expandedRows[func.name] ? 'unset' : 2,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                transition: 'all 0.3s ease',
                              }}
                            >
                              {func.description}
                            </Typography>
                            {!expandedRows[func.name] && isDescriptionTruncated(func.name) && (
                              <Link
                                component="button"
                                variant="body2"
                                onClick={() => toggleRowExpansion(func.name)}
                                sx={{ mt: 0.5 }}
                              >
                                Read more
                              </Link>
                            )}
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title={restrictedFunctions.includes(func.name) ? "This function cannot be set as public due to security reasons." : ""}>
                          <span>
                            <Checkbox
                              checked={publicFunctions.includes(func.name)}
                              onChange={() => handleToggle(func.name, true)}
                              disabled={restrictedFunctions.includes(func.name)}
                            />
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Checkbox
                          checked={adminFunctions.includes(func.name)}
                          onChange={() => handleToggle(func.name, false)}
                        />
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog />
      <Snackbar />
    </>
  );
};

export default FunctionPermissionDialog;
