import React, { useEffect, useState } from "react";
import { Box, TextField, Typography, Button, IconButton, Icon } from "@mui/material";
import Markdown from "markdown-to-jsx";
import { dateTimeFormatter } from "src/libs/dateTimeFormatter";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import { useAppDispatch, useAppSelector } from 'src/store';
import { deleteMessage, deliverMessage, updateMessage, deliverMessageNow } from "src/features/account/messages.slice";
import { decrementMessageCount } from "src/features/account/conversations.slice";
import theme from "src/themes/oppy-default-theme";
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from "notistack";
import CountdownTimer from "../CountdownTimer";
import CodeIcon from '@mui/icons-material/Code';
import ConversationCard from "./ConversationCard";
import { getBranding } from "src/utils/branding";
import CountdownTimerButton from "../CountdownTimerButton";
import { useTheme, Theme } from '@mui/material/styles';

const Message = ({ message, sendingEntity, channelId, fetchConversationsWithMessagesAction, handleMessageClick, conversationDeliveryState = '' }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showActionButtons, setShowActionButtons] = useState(true);
  const [updatedMessage, setUpdatedMessage] = useState(message.content);
  const [currentMessageId, setCurrentMessageId] = useState(null);
  const [showRawContent, setShowRawContent] = useState(false);
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.messages);
  const { enqueueSnackbar } = useSnackbar();
  const [conversation, setConversation] = useState(null);
  const [deliverNowButtonDisabled, setDeliverNowButtonDisabled] = useState(false);
  const theme = useTheme() as Theme & { palette: { accent: { main: string } } };

  useEffect(() => {
    const extractJson = (text) => {
      if (!text) return null; // Check if text is null or undefined
      const jsonPattern = /{"conversation"=>.*?}}/; // Updated regex to match the exact structure
      const match = text.match(jsonPattern);
      return match ? match[0] : null;
    };

    const jsonStr = extractJson(message.raw_content);
    if (jsonStr) {
      try {
        // Add null check before using replace
        const parsedData = JSON.parse(jsonStr?.replace(/=>/g, ':')?.replace(/nil/g, 'null') || '{}');
        setConversation(parsedData.conversation);
      } catch (error) {
        console.error('Failed to parse conversation JSON:', error);
      }
    }
  }, [message.raw_content]);

  const toggleRawContent = () => {
    setShowRawContent(!showRawContent);
  };

  const handleReload = () => {
    fetchConversationsWithMessagesAction();
    setCurrentMessageId(null);
  }

  const setSuccessMessage = (message) => {
    enqueueSnackbar(message, {
      variant: 'success',
      autoHideDuration: 3000
    });
  }

  const setErrorMessage = (message) => {
    enqueueSnackbar(message, {
      variant: 'error',
      autoHideDuration: 3000
    });
  }

  const handleMessageClicked = (message) => {
    handleMessageClick(message);
  }
    

  const editMessage = () => {
    setCurrentMessageId(message.id);
    setShowEdit(false);
    dispatch(updateMessage({ messageId: message.id, content: updatedMessage, channelId: channelId })).unwrap()
      .then((response) => {
        setSuccessMessage(response.message);
      }).catch((error) => {
        setErrorMessage(error);
      }).finally(() => {
        handleReload()
      })
  };

  const handleDelete = (message) => {
    dispatch(deleteMessage({ messageId: message.id, channelId: channelId, conversationId: message.conversation_id }));
    dispatch(decrementMessageCount({ conversationId: message.conversation_id }));
  };

  const handleSendNow = (messageId) => {
    setCurrentMessageId(message.id);
    setDeliverNowButtonDisabled(true);
    dispatch(deliverMessageNow({ messageId: messageId, channelId: channelId })).unwrap()
      .then((response) => {
        setSuccessMessage(response.message);
      }).catch((error) => {
        setErrorMessage(error);
      }).finally(() => {
        handleReload()
      })
  };

  const isDarkMode = sendingEntity && sendingEntity.role === message.sender_role;
  const isSelf = sendingEntity && sendingEntity.role === message.sender_role;

  return (
    <Box
      key={message.id}
      sx={{
        mb: "24px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        opacity: message.sender_role === "function" ? 0.7 : 1,
        position: "relative",
        '&:hover .timestamp, &:hover .action-buttons': {
          transform: 'translateY(0)',
          opacity: 1,
        },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", gap: "4px", mb: -.5, }}>
        <Typography
          variant="subtitle2"
          sx={{
            fontSize: "14px",
            textTransform: "capitalize",
            color: "rgba(0, 0, 0, 0.6)",
            mr: '4px',
            ml: '4px',
          }}
        >
          {message.sender_name}
          {message.sender_role === 'assistant' ? (
            <Box
              component="img"
              src={getBranding('oppy_expressions_no_mouth')}
              alt="Assistant Icon"
              sx={{ width: '20px', height: '20px', mt: -1, ml: 1 }}
            />
          ) : message.sender_role === 'user' ? (
            <Icon sx={{ fontSize: '16px', ml: .5 }}>person</Icon>
          ) : (
            <Icon onClick={toggleRawContent} sx={{ fontSize: '18px', ml:.5}}>
              {showRawContent ? "code" : "computer"}
            </Icon>
          )}
          {" "}
        </Typography>
      </Box>
      {(loading && currentMessageId) ? <CircularProgress /> :
        (showEdit ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-end",
              gap: "10px",
              scrollbarWidth: "none",
              maxWidth: "90%",
              minWidth: "300px",
            }}
          >
            <TextField
              value={updatedMessage}
              InputLabelProps={{ shrink: false }}
              onChange={(e) => setUpdatedMessage(e.target.value)}
              defaultValue={message.content}
              multiline
              maxRows={8}
              variant="outlined"
              sx={{
                width: '100%',
                maxWidth: '90%',
                '& .MuiInputBase-inputMultiline': {
                  scrollbarWidth: 'none',
                  'msOverflowStyle': 'none',
                  '&::-webkit-scrollbar': {
                    display: 'none'
                  },
                },
              }}
            />
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: "10px" }}>
              <Button variant="outlined" onClick={() => {
                setShowEdit(false);
                setUpdatedMessage(message.content);
              }}>
                Cancel
              </Button>
              <Button variant="contained" onClick={() => editMessage()}>
                Update
              </Button>
            </Box>
          </Box>
        ) : (
          <>
          <Box
            sx={{
              position: 'relative',
              overflow: 'visible',
            }}
          >
            <Box
              sx={{
                padding: "16px 24px",
                borderRadius: "8px",
                color: isDarkMode ? theme.palette.common.white : theme.palette.text.primary,
                backgroundColor: isDarkMode ? theme.palette.grey[800] : theme.palette.action.hover,
                wordWrap: "break-word",
                overflowWrap: "anywhere",
                transition: "300ms ease-in-out",
                '& > *, & > * > *, & > * > *': {
                  overflowWrap: 'anywhere',
                },
                '& img': {
                  maxWidth: '50%',
                  display: 'block',
                  margin: '0 auto',
                  borderRadius: "8px",
                  transition: "300ms ease-in-out",
                  mb:2,
                },
                '& img:hover': {
                  maxWidth: '90%',
                  transition: "300ms ease-in-out",
                },
                borderLeft: isSelf ? `6px solid ${theme.palette.accent.main}` : 'none',
                '@keyframes fadeInOut': {
                  '0%, 100%': { borderLeftColor: 'transparent' },
                  '50%': { borderLeftColor: theme.palette.accent.main },
                },
                animation: isSelf ? 'fadeInOut 7s infinite' : 'none',
              }}
            >
                <Typography variant="body2"
                  sx={{
                    '& a': {
                      color: "inherit",
                      textDecoration: "underline"
                    },
                    opacity: message.status === "delivered" ? 
                      1 
                      : 
                      0.7
                  }}
                >
                  {conversation ? <ConversationCard conversation={conversation} variant="small" handleConversationsListToggleClick={undefined} /> : null }
                <Markdown>{showRawContent ? message.raw_content : message.content}</Markdown>
              </Typography>
              { message.status === "pending_delivery" &&
                <>
                  <Typography variant="body2" sx={{ 
                    fontSize: "14px", 
                    opacity: 0.5,
                    mb:-1,
                    alignSelf: "flex-start",
                  }}
                  >
                    {conversationDeliveryState === "pause" ? "Sending paused" : "Send @ " + dateTimeFormatter(message.deliver_after)}
                  </Typography>
                </>
              }
            </Box>
            <Box
              className="action-buttons"
              sx={{
                position: 'absolute',
                right: 0,
                bottom: '-24px',
                padding: '2px',
                borderRadius: '4px',
                transform: 'translateY(100%)',
                opacity: 0,
                transition: 'transform 0.3s ease, opacity 0.3s ease',
                display: showActionButtons ? 'flex' : 'none',
                gap: '2px',
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                zIndex: 1,
              }}
            >
              <IconButton size="small" onClick={() => setShowEdit(true)}>
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton size="small" onClick={() => handleDelete(message)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
              {message.status !== "delivered" && (
                <IconButton size="small" onClick={() => handleSendNow(message.id)}>
                  <SendIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
          </Box>
          <Typography
            variant="body2"
            className="timestamp"
            sx={{
              fontSize: "14px",
              opacity: 0,
              transform: 'translateY(-20px)',
              transition: 'transform 300ms ease-in-out, opacity 300ms ease-in-out',
              position: "absolute",
              bottom: "-20px",
              left: 0,
              zIndex: 1,
            }}
          >
            {dateTimeFormatter(message.created_at)}
          </Typography>
          </>
        ))}

    </Box>
  );
};

export default Message;
