// import { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import ConnectCalendar from 'src/components/ConnectCalendar';
// import Layout from 'src/components/Layout';
// import { trackAnalytics } from 'src/features/analytics/analytics.slice';
// import { setGoogleCalendarToken } from 'src/features/onboarding/onboarding.slide';
// import { setUser } from 'src/features/user/user.slice';
// import useGoogleAuth from 'src/hooks/useGoogleAuth';
// import { useAppDispatch, useAppSelector } from 'src/store';

// const Calendar = () => {
//   const { isSignIn, authorizationCode, getOfflineAccess, isLoading } = useGoogleAuth();
//   const dispatch = useAppDispatch();
//   const { user } = useAppSelector((state) => state.user);
//   let navigate = useNavigate();

//   useEffect(() => {
//     if (user.google_calendar_connected) {
//       navigate('/services-list');
//     }
//   }, []);

//   useEffect(() => {
//     if (isSignIn) {
//       // is the user grant access to their google account send the refresh token
//       dispatch(
//         setGoogleCalendarToken({ authorization_code: authorizationCode })
//       )
//         .unwrap()
//         .then(() => {
//           dispatch(trackAnalytics({action_name: "google_calendar_connected", payload: { description: "user successfully connected calendar"}}))
//           navigate('/services-list');
//         })
//         .catch((error) => {
//           dispatch(trackAnalytics({action_name: "error_connecting_calendar", payload: { description: `There was error connecting calendar, error detail : ${error}`}}))
//           console.log(error)
//         });
//     }
//   }, [isSignIn]);

//   return (
//     <Layout isBoarding={true} >
//       <ConnectCalendar getOfflineAccess={getOfflineAccess} isLoading={isLoading} />
//     </Layout>
//     );
// };

// export default Calendar;


import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getUser } from 'src/features/user/user.slice'
import { setGoogleCalendarToken } from 'src/features/onboarding/onboarding.slide';
import { useAppDispatch } from 'src/store';

const Calendar = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [error, setError] = useState(null);

  useEffect(() => {
    const url = new URLSearchParams(location.search);
    const authorization_code = url.get('code');

    if (authorization_code) {
      console.log(`Authorization code: ${authorization_code}`);
      dispatch(
        setGoogleCalendarToken({ authorization_code: authorization_code, redirect_uri: `${window.location.origin}/calendar` })
      ).unwrap()
        .then(() => {
          console.log('Calendar connected');
          dispatch(getUser()) // Refresh user after calendar connection
            .unwrap()
            .then(() => console.log('User data refreshed'))
            .catch((error) => {
              console.error('Failed to refresh user data', error);
              setError(error);
            });
          window.close();
        })
        .catch((error) => {
          setError(error);
        });
    }

  }, [location]);

  return (
    <>
      {error ? <div>There was an error connecting calendar, error detail : {error}</div> :
        <div className="vw-100 vh-100 d-flex justify-content-center align-items-center position-fixed z-index-master" style={{ backgroundColor: 'var(--bs-body-bg)', zIndex: 1090, top: 0 }}>
          <CircularProgress />
        </div>
      }
    </>
  );
};

export default Calendar;
