import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { CircularProgress, IconButton, InputAdornment, Typography, TextareaAutosize, Tooltip } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { useAppSelector } from 'src/store';
import { API_ROUTES } from 'src/constants/routes';
import { httpClient } from 'src/libs';
import Edit from '@mui/icons-material/Edit';
import { enqueueSnackbar } from 'notistack';
import { updateChannel } from 'src/features/account/channels.slice';
import { useAppDispatch } from 'src/store';

const ChannelDescriptionTextInput = ({ channel }) => {
  const [showInput, setShowInput] = useState(false);
  const [error, setError] = useState(false);
  const [label, setLabel] = useState('');
  const [description, setDescription] = useState(channel.description || "");
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const handleInputChange = (event) => {
    const text = event.target.value;
    setDescription(text); // Remove trailing spaces and consecutive newlines
  };

  useEffect(() => {
    setDescription(channel.description || "");
  }, [channel]);

  const handleSubmit = () => {
    const currentDescription = channel.description || ""; // Ensure it's not null
    if (description !== currentDescription.trim()) { // Check if description has changed
      const cleaned_description = description.replace(/\s+$/, '').replace(/\n{2,}/g, '\n'); // Remove trailing spaces and consecutive newlines
      dispatch(updateChannel({ channelData: { id: channel.id, description: cleaned_description } }));
    }
  }

  return (
    <>
      <Box sx={{ maxWidth: '100%', width: '600px', wordBreak: 'break-word' }}>
        {showInput ? 
          <Box sx={{
            position: 'relative',
            width: '100%',
            '&::after': {
              content: `"${description.length}/160"`,
              position: 'absolute',
              right: '10px',
              bottom: '10px',
              fontSize: '0.75rem',
              color: description.length >= 160 ? 'error.main' : 'text.secondary'
            }
          }}>
            <TextareaAutosize
              disabled={loading}
              onChange={handleInputChange}
              onBlur={() => {
                setShowInput(false);
                setError(false);
                setLabel('');
                handleSubmit();
              }}
              aria-label="Channel description"
              minRows={3}
              placeholder="Enter channel description"
              style={{ width: '100%' }}
              value={description}
              maxLength={160}
            />
          </Box>
          :
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }} onClick={() => setShowInput(true)}>
            <Tooltip title="Click to edit">
              {description || 'No description set. Click to edit.'}
            </Tooltip>
            {/* <IconButton onClick={() => setShowInput(true)}>
              <Edit sx={{ fontSize: "16px" }} />
            </IconButton> */}
          </Box>
        }
      </Box>
    </>
  );
};

export default ChannelDescriptionTextInput;
