import { Button, Container, Row } from 'react-bootstrap';
import ErrorPopup from 'src/components/ErrorPopup';
import { trackAnalytics } from 'src/features/analytics/analytics.slice';
import useGoogleAuth from 'src/hooks/useGoogleAuth';
import { useAppDispatch, useAppSelector } from 'src/store';
import { getBranding } from 'src/utils/branding';
import useGoogleAuthV2 from 'src/hooks/useGoogleAuthV2';

const ConnectCalendar = ({getOfflineAccess, isLoading}) => {
  const { error, apiLoading } = useAppSelector((state) => state.onBoarding);
  const dispatch = useAppDispatch();
  const { isConnecting, userAuthorization } = useGoogleAuthV2();

  const onClick = (event: React.MouseEvent) => {
    dispatch(trackAnalytics({action_name: "connect_google_calendar_initiated", payload: { description: "user clicked connect google calendar"}}))
    userAuthorization(event);
  };
  return (
    <Container className="bg-light min-vh-100" fluid>
      <Container className="text-start p-4 ">
        <Row
          lg={'auto'}
          className="mt-5 p-5 d-flex flex-column justify-content-center"
        >
          <div className="d-flex justify-content-center">
            <img
              src="/images/Google_Calendar.png"
              alt=""
              height={119}
              width={119}
              className="pb-3 h-100"
            />
          </div>
          <div className="d-flex flex-column align-items-center">
            <div className="fw-medium fs-36">Connect your Google Calendar</div>
            {error && <ErrorPopup message={error} />}

            <p className="text-black-50 fs-18 text-align-center">
              Connecting your Google Calendar will allow you to enable scheduling related actions for your account.

              <br />After you connect your calendar for the first time, it's worth checking out all of the more advanced scheduling options in your account settings.
            </p>
          </div>
          <div className="d-flex justify-content-center flex-column">

              <Button
                variant="primary"
                type="button"
                onClick={onClick}
                disabled={isLoading || apiLoading || isConnecting}
              >
                {(isLoading || apiLoading || isConnecting) && (
                  <span className="spinner-border spinner-border-sm me-2"></span>
                )}
                Connect Calendar
              </Button>

            <div className="row mt-1">
              <h4>*IF YOU'VE CONNECTED YOUR CALENDAR ALREADY, CLOSE THIS WINDOW OR REFRESH YOUR BROWSER.</h4>

            </div>
          </div>
          <br />
          <p className="text-black-50 fs-18 text-align-center">
            If you are having trouble connecting your
            Google Calendar or have questions please chat us @
            <a href="https://app.oppy.pro/s/oppy?s=2&initial_message=I%27m%20having%20trouble%20connecting%20my%20Google%20calendar%20can%20you%20please%20help?" rel="noreferrer" target="_blank">here</a>
          </p>
        </Row>
      </Container>
    </Container>
  );
};

export default ConnectCalendar;
