import React, { useState } from 'react';
import { Typography, Skeleton } from '@mui/material';
import theme from 'src/themes/oppy-default-theme';
import Box from '@mui/system/Box';
import ContactCreateDialog from 'src/components/Contacts/ContactCreateDialog';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from "src/store";

const EntityCard = ({ entity, onClick = () => {}, styles }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  const handleEntityClick = () => {
    if (entity.role === 'assistant') {
      navigate(`/channels?channel_id=${entity.channel_id}`);
    } else if (entity.role === 'user') {
      const values = {
        phone: entity.tag === 'twilio' ? entity.identifier : undefined,
        email: entity.tag === 'email' ? entity.identifier : undefined,
      };
      setInitialValues(values);
      setDialogOpen(true);
    }
  };

  return (
    <>
      <Box sx={styles.wrapperBox} onClick={handleEntityClick}>
        { !entity.name ? (
          <>
            <Skeleton variant="circular" width={24} height={24} style={{ marginRight: 8 }} />
            <Skeleton variant="text" width={100} height={24} />
          </>
        ) : (
          <>
            <Typography component="span" sx={styles.name}>
              {entity.name}
            </Typography>
            {entity.identifier && entity.identifier !== entity.name && entity.role !== 'assistant' && 
              <Typography variant='caption' sx={styles.detailsRow}>
                {entity.identifier.startsWith('ui_chat:') ? 'Web Chat' : `${entity.identifier} (${entity.role})`}
              </Typography>
            }
          </>
        )}
      </Box>
      <ContactCreateDialog open={dialogOpen} initialValues={initialValues} onClose={() => setDialogOpen(false)} />
    </>
  );
};

export default EntityCard;
