import { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { trackAnalytics } from "src/features/analytics/analytics.slice";
import { setGoogleCalendarToken } from "src/features/onboarding/onboarding.slide";
import useGoogleAuth from "src/hooks/useGoogleAuth";
import { useAppDispatch, useAppSelector } from "src/store";
import ConnectCalendar from "./ConnectCalendar";
import CloseIcon from '@mui/icons-material/Close';
import { getUser } from "src/features/user/user.slice";
import { DialogTitle, IconButton } from "@mui/material";

const CalendarDialog = ({show, handleGoBack, calendarConnected, fullScreen = false}) => {
  const { isSignIn, authorizationCode, getOfflineAccess, isLoading } = useGoogleAuth();
  const [dialogOpen, setDialogOpen] = useState(show)
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user)

  useEffect(()=>{
    const interval = setInterval(() => {
      if (user.google_calendar_connected) {
        handleCalendarConnected
        return
      }
      dispatch(getUser());
    }, 5000);
    return () => clearInterval(interval);
  
  }, [isLoading, user.google_calendar_connected])

  useEffect(() => {
    console.warn('calendar dialog open', show)
    console.warn('show', show)
  }, []);

  const handleCalendarConnected = () =>{
    dispatch(trackAnalytics({ action_name: "google_calendar_connected", payload: { description: "user successfully connected calendar" } }))
    calendarConnected(true)
    console.warn('calendar connected')
    setDialogOpen(false)
  }
   useEffect(() => {
     if (isSignIn) {
       dispatch(
        setGoogleCalendarToken({ authorization_code: authorizationCode })
      )
      .unwrap()
      .then(() => {
        handleCalendarConnected
      })
      .catch((error) => {
        dispatch(trackAnalytics({action_name: "error_connecting_calendar", payload: { description: `There was an error connecting calendar, error detail : ${error}`}}))
        calendarConnected(false)
      });
     }
   }, [isSignIn]);

   return (
    <Dialog
       open={dialogOpen}
       keepMounted
       onClose={handleGoBack}
       fullScreen={fullScreen}
       maxWidth="xl"
    >
      <DialogTitle id="form-dialog-title">
        <IconButton edge="end" color="inherit" onClick={handleGoBack} aria-label="close" sx={{ position: 'absolute', right: '12px', top: '2px' }}>
          <CloseIcon color="action" />
        </IconButton>
      </DialogTitle>
       <DialogContent>
         <ConnectCalendar getOfflineAccess={getOfflineAccess} isLoading={isLoading} />
       </DialogContent>
    </Dialog>
  );
};

export default CalendarDialog;
