import { Autocomplete, LinearProgress, TextField } from '@mui/material';
import { DataGrid, GridRowsProp, GridColDef, GridCellEditStopParams, GridCellEditStopReasons, MuiEvent, GridRenderCellParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { API_ROUTES } from 'src/constants/routes';
import { httpClient } from 'src/libs';
import { useAppDispatch, useAppSelector } from 'src/store';
import 'src/styles/components/_dashboard.scss'
import Swal from 'sweetalert2';
import { useSearchParams } from "react-router-dom";
import UsersMap from './UsersMap';
import withReactContent from 'sweetalert2-react-content'
import BookingForm from './BookingForm';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { color } from '@mui/system';
import { Col, Row } from 'react-bootstrap';
import { useLoadScript } from '@react-google-maps/api';

const bookingPopup = withReactContent(Swal)


export function useSearchParamsState(
    searchParamName: string,
    defaultValue: any
): readonly [
    searchParamsState: string,
    setSearchParamsState: (newState: string) => void
] {
    const [searchParams, setSearchParams] = useSearchParams();

    const acquiredSearchParam = searchParams.get(searchParamName);
    const searchParamsState = acquiredSearchParam ?? defaultValue;

    const setSearchParamsState = (newState: string) => {
        const next = Object.assign(
            {},
            [...searchParams.entries()].reduce(
                (o, [key, value]) => ({ ...o, [key]: value }),
                {}
            ),
            { [searchParamName]: newState }
        );
        setSearchParams(next);
    };
    return [searchParamsState, setSearchParamsState];
}

const UserManager = (user) => {

  const [users, setUsers] = useState(null)
  // const [filterAccountStatus, setAccountStatus] = useState(null)
  // const [filterAccountSlugs, setServiceSlugs] = useState(null)
  const [accountStatus, setAccountStatus] = useSearchParamsState("account_status",'');
  const [serviceSlugs, setServiceSlugs] = useSearchParamsState("service_slugs", '');
  const [address, setAddress] = useSearchParamsState("address", '');
  const [fullPlace, setFullPlace] = useState(address ? { value: address, label: address } : null);
  const [availableSlugs, setSlugOptions] = useState([])
  const [loading, setLoading] = useState(true)
  const dispatch = useAppDispatch();
  const darkMode = useAppSelector(state => state.theme.darkMode )
  const rows: GridRowsProp = users || [];
  const { isLoaded } = useLoadScript({ googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY, libraries: ['places'], });

  const showBookingForm = (params) => {
    bookingPopup.fire({
      html: <BookingForm
        provider={params.row}
        filledAddress={fullPlace}
        dispatch={dispatch}
      />,
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: false,
      customClass: 'swal-wide',
    })
  }

  function renderAvatar(params: GridRenderCellParams) {
    return <img className="avatar--round" src={`${params.value}`}></img>;
  }
  function renderAddress(params: GridRenderCellParams) {
    return params.value?.formatted_address
  }
  function renderBookingForm(params: GridRenderCellParams) {
    return <a onClick={() => showBookingForm(params)} style={{ color: 'green', cursor: 'pointer' }}>Create Booking</a>
  }
  function renderBookingLink(params: GridRenderCellParams) {
    return <a href={`${params.value}`} target="_blank" rel="noreferrer">Book</a>
  }
  function renderJSON (params: GridRenderCellParams<any, any>) {
    return JSON.stringify(params.value)
  }


  function setUserData(data: any){
    setUsers(data.users)
    setSlugOptions(data.filters.slugs)
  }
  const columns: GridColDef[] = [
    { field: 'photo', headerName: '', width: 60, renderCell: renderAvatar },
    { field: 'first_name', headerName: 'First Name', width: 150 },
    { field: 'last_name', headerName: 'Last Name', width: 150, editable: true },
    { field: 'email', headerName: 'Email', width: 150 },
    { field: 'address', headerName: 'Address', width: 250, renderCell: renderAddress },
    { field: 'surcharge_travel_distance', headerName: 'Travel Distance (Miles)', width: 150 },
    { field: 'distance_in_miles', headerName: 'Distance (Mi)', width: 100 },
    { field: 'enabled_service_slugs', headerName: 'Enabled Services', width: 150 },
    { field: 'account_status', headerName: 'Account Status', width: 100, editable: true, type: "singleSelect", valueOptions: ["Default", "Active", "Rejected"] },
    { field: 'create_booking', headerName: 'Create Booking', width: 120, renderCell: renderBookingForm },
    { field: 'booking_url', headerName: 'Booking Link', width: 100, renderCell: renderBookingLink },
    { field: 'contact_phone', headerName: 'Contact Phone', width: 150 },
    { field: 'start_stop_hours', headerName: 'Start/Stop Hours', width: 150 },
    { field: 'appointment_buffer', headerName: 'Buffer Time', width: 150 },
    { field: 'created_at', headerName: 'Created At', width: 150 },
    { field: 'updated_at', headerName: 'Last Updated', width: 150 },
    { field: 'timezone', headerName: 'Timezone', width: 150 },
    { field: 'base_travel_distance', headerName: 'Base Travel Before Fee (Minutes)', width: 150 },
    { field: 'willing_to_surcharge_travel', headerName: 'Charges Travel Fees?', width: 150 },
    { field: 'travel_surcharge', headerName: 'Travel Fee (Per Minute Driving)', width: 150 },
  ]

  function fetchUsers(){
    setLoading(true)
    const url = new URL(`${process.env.REACT_APP_API_URL}/users_list`);
    if (address) {
      url.searchParams.append("address", address)
    }
    if (serviceSlugs) {
      url.searchParams.append("service_slugs", serviceSlugs)
    }

    httpClient.get(url.href, {
      headers: {
        'Content-Type': 'application/json'
      },
    }).then(({data}) => {
      setLoading(false)
      setUserData(data)
    })
    .catch(error => {
      setLoading(false)
      Swal.showValidationMessage(
        `Request failed: ${error}`
      )
    })
  }

  useEffect(() => {
    fetchUsers()
  }, [address, serviceSlugs]);

  const userUpdater = (payload) => {
    const url = `${API_ROUTES.bulk_user_update}`
    return httpClient.post(url, {ids: [payload.uuid], user: payload})
      .then(({ data }) => {
        if (!data.success) {
          throw new Error(data.message)
        }
      })
  }

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
    },
  });

  return <>
  <div className="container-fluid">
    <div className='card'>
      <div className='card-header'>
        <div className="card-title">
          <h1>Manage Users</h1>
        </div>
        <div className='card-subtitle'>
          <em><strong>Invite Team Link:</strong> https://remark.re/register?ref=admin_invite&account_invite={user.account?.owner_uuid}</em>
        </div>
        <div className='map-wrapper me-2 my-3' style={{ height: '300px' }}>
          <UsersMap
            users={users}
            isLoaded={isLoaded}
          />
        </div>
        <div className="grid-filters-bar">
          <Row>
            <Col xl={'auto'}>
              {isLoaded &&
                <GooglePlacesAutocomplete
                  autocompletionRequest={{
                    componentRestrictions: {
                      country: "USA",
                    }
                  }}
                  selectProps={{
                    value: fullPlace,
                    onChange: (value) => {
                      setFullPlace(value);
                      setAddress(value.label);
                    },
                    styles: {
                      control:(provided) => ({
                        ...provided,
                        backgroundColor: 'var(--bs-secondary-bg)',
                        color: darkMode ? 'var(--bs-light)' : 'var(--bs-dark)',
                        width: '300px',
                      }),
                      input: (provided) => ({
                        ...provided,
                        color: darkMode ? 'var(--bs-light)' : 'var(--bs-dark)',
                        padding: '10px 0px 10px 0px',
                      }),
                      menu:(provided) => ({
                        ...provided,
                        backgroundColor: 'var(--bs-secondary-bg)',
                      }),
                      option: (provided, {isFocused, isSelected}) => ({
                        ...provided,
                        color: darkMode ? 'var(--bs-light)' : 'var(--bs-dark)',
                        backgroundColor: (isFocused || isSelected) ? 'var(--bs-body-bg)' : 'var(--bs-secondary-bg)',
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: darkMode ? 'var(--bs-light)' : 'var(--bs-dark)',
                      }),
                    },
                  }}
                />
              }
            </Col>
            <Col xl={'auto'}>
              <ThemeProvider theme={theme}>
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={availableSlugs}
                  getOptionLabel={(option) => option}
                  filterSelectedOptions
                  className='services-filter-autocomplete'
                  value={serviceSlugs.split(',').filter(v => v.length)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Services"
                      placeholder="Favorites"
                    />
                  )}
                  style={{backgroundColor: "var(--bs-secondary-bg)", width: "300px"}}
                  onChange={(event, newInputValue) => {
                    setServiceSlugs(newInputValue.join(','))
                  }}
                />
              </ThemeProvider>
            </Col>
            <Col xl={'auto'}>
              <button onClick={() => window.location.replace("?")} className="btn btn-default filter-item">
                Clear Filters
              </button>
            </Col>
          </Row>
        </div>
      </div>
      <div className="card-body" style={{ height: 600, width: '100%' }}>
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              loading={loading}
              onRowClick={(params, event) => {
                event.stopPropagation();
              }}
              rows={rows}
              columns={columns}
              getRowId={(row) => row.uuid}
              
              processRowUpdate={(newRow: GridCellEditStopParams) => {
                userUpdater(newRow)
                return newRow
              }}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  : <></>
</>;
};

export default UserManager;
